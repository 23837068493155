import React from 'react'

import Error from './Error'

const defaultErrorMessages = {
  default:
    'Something went wrong while trying to display this part of the page.',
  table: 'Something went wrong while trying to display this table.',
  graph: 'Something went wrong while trying to display this graph.',
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    /*     logErrorToMyService(error, errorInfo) */
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.errorInfo) {
      if (this.props.renderError) {
        return this.props.renderError(error)
      }
      const message = this.props.errorMessage
        ? this.props.errorMessage in defaultErrorMessages
          ? defaultErrorMessages[this.props.errorMessage]
          : this.props.errorMessage
        : defaultErrorMessages.default
      return <Error>{message}</Error>
    }

    return this.props.children
  }
}

export const withErrorBoundary = (Component, options = {}) => (props) => (
  <ErrorBoundary {...options}>
    <Component {...props} />
  </ErrorBoundary>
)

export default ErrorBoundary
