import React from 'react'
import 'twin.macro'

import useRRResourceable from './useRRResourceable'

const RRTableable = ({
  children,
  label,
  filters,
  groupbyColumns,
  values,
  areCompensatingRows,
}) => {
  const { onCapture, Capturable } = useRRResourceable({
    resourceType: 'table',
  })

  const onClick = () =>
    onCapture(label, {
      filters,
      groupby_columns: groupbyColumns,
      values,
      are_compensating_rows: areCompensatingRows,
    })

  return (
    <div tw="relative">
      {children}
      <Capturable onClick={onClick} />
    </div>
  )
}

export default RRTableable
