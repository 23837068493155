import React from 'react'
import 'twin.macro'

import { H2, H3, HR, Card, Loading } from '@fxr/ui'
import Bar from '@fxr/ui/charts/Bar'
import { round } from '@fxr/common/utils'

import { useFetchCollectionSharesQuery } from './features/data/dataApiSlice'
import RRImageable from './RRImageable'

const CollectionShareDistributions = ({ org2collectionShare2freq }) => {
  const prepareData = (data) =>
    Object.keys(data).map((key) => ({
      'share (%)': parseFloat(key),
      value: parseInt(data[key]),
    }))

  return (
    <>
      {Object.keys(org2collectionShare2freq).map((org) => {
        const collectionShare2freq = org2collectionShare2freq[org]
        const id = `collection-share-${org}`

        return (
          <Card key={org}>
            <Card.Section>
              <p>
                <strong>{org}</strong>
              </p>
            </Card.Section>
            {Object.keys(collectionShare2freq).length === 0 ? (
              <Card.Section>
                <p>No data to display.</p>
              </Card.Section>
            ) : (
              <div tw="aspect-w-4 aspect-h-2">
                <RRImageable width={800} height={400} label={id}>
                  <Bar
                    id={id}
                    data={prepareData(collectionShare2freq)}
                    xlabel="share (%)"
                    background="white"
                    formatXLabel={(x) => round(x, 2)}
                  />
                </RRImageable>
              </div>
            )}
          </Card>
        )
      })}
    </>
  )
}

const CollectionShareDistributionWrapper = ({ title, children }) => (
  <>
    <H3 tw="mb-1">{title}</H3>
    <HR tw="mb-2" />
    <div tw="grid grid-cols-2 xl:grid-cols-3 gap-4">{children}</div>
  </>
)

const CollectionShareVisualization = (props) => {
  const { data, isFetching } = useFetchCollectionSharesQuery()

  const org2collectionShare2freq = data ? data.org2collection_perc2freq : null

  const estimatedOrg2collectionShare2freq = data
    ? data.estimated_org2collection_perc2freq
    : null

  return (
    <>
      <Card tw="mb-4">
        <Card.Header tw="flex justify-between items-center">
          <H2>Collection shares</H2>
        </Card.Header>
      </Card>

      {isFetching ? (
        <Loading.Card />
      ) : (
        <>
          {org2collectionShare2freq && (
            <CollectionShareDistributionWrapper title="given or computed">
              <CollectionShareDistributions
                org2collectionShare2freq={org2collectionShare2freq}
              />
            </CollectionShareDistributionWrapper>
          )}
          {estimatedOrg2collectionShare2freq && (
            <CollectionShareDistributionWrapper title="estimated">
              <CollectionShareDistributions
                org2collectionShare2freq={estimatedOrg2collectionShare2freq}
              />
            </CollectionShareDistributionWrapper>
          )}
        </>
      )}
    </>
  )
}

export default CollectionShareVisualization
