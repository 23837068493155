import React, { useState, useMemo } from 'react'
import {
  useTable,
  useSortBy,
  usePagination,
  useBlockLayout,
  useFilters,
} from 'react-table'
import { useLocalstorageState } from 'rooks'
import { theme } from 'twin.macro'
import { useSelector, useDispatch } from 'react-redux'

import { round, objectFilter } from '@fxr/common/utils'
import { tableFilterTypes } from '@fxr/ui/rtable/Filters'
import RTable from '@fxr/ui/rtable/Table'
import { VR, HR, MultiSwitch, Card, H3, H2, Switch } from '@fxr/ui'

import RRTableable from './RRTableable'
import columnsConfig, { columnGroups } from './columns'
import { useFetchTabularQuery } from './features/data/dataApiSlice'
import { filtersUpdated } from './features/filters/filtersSlice'
import SelectColumns from './SelectColumns'
import usePrepareFilters from './usePrepareFilters'
import { VALUES } from './constants'

import { cmoName, pubName } from './constants'

const getTableLabel = (groupbyColumnsAndFilters, isCompensators) =>
  `tabular${
    isCompensators ? '-compensators' : ''
  }-${groupbyColumnsAndFilters.join('-')}`

const Table = ({ data, columns, isLoading, onRowClick }) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: (false && theme('spacing.tableColumnMinWidth')) || 10,
      width: (false && theme('spacing.tableColumnWidth')) || 250,
      maxWidth: (false && theme('spacing.tableColumnMaxWidth')) || 550,
    }),
    []
  )

  const memoizedColumns = useMemo(() => columns, [columns])

  const filterTypes = useMemo(
    () => ({
      textOrUnknown: tableFilterTypes.textOrUnknown,
      text: tableFilterTypes.text,
      greaterThan: tableFilterTypes.greaterThan,
      lessThan: tableFilterTypes.lessThan,
      between: tableFilterTypes.between,
      betweenAbs: tableFilterTypes.betweenAbs,
    }),
    []
  )

  const initialState = {
    pageSize: 20,
    pageIndex: 0,
    sortBy: [{ id: 'estimated_mpc_client_amount_due_min', desc: true }],
  }

  const tableInstance = useTable(
    {
      columns: memoizedColumns,
      data,
      defaultColumn,
      filterTypes,
      pageSizes: [20, 50, 100, 150, 300],
      initialState,
    },
    useFilters,
    useSortBy,
    usePagination,
    useBlockLayout
  )

  return (
    <RTable.NavbarOffset>
      <RTable
        tableInstance={tableInstance}
        isLoading={isLoading}
        onRowClick={onRowClick}
        sortable={true}
        filterable={true}
        paginated={true}
        renderTFoot={true}
        renderFooter={true}
      />
    </RTable.NavbarOffset>
  )
}

const deficientOrgtypes = [cmoName, pubName, 'both']

const filterOrgtype = (rows, orgtype) => {
  if (orgtype === 'both') {
    return rows
  }

  const filter =
    orgtype === 'cmo'
      ? (row) => row.mpc_total_comp_amount_difference < 0
      : (row) => row.mpc_total_comp_amount_difference > 0

  return rows.filter(filter)
}

const TabularDisplay = () => {
  const [deficientOrgtype, setDeficientOrgtype] = useState('both')
  const [showTCA, setShowTCA] = useLocalstorageState(
    'fxr:tabular:show-tca',
    true
  )
  const [showEstimations, setShowEstimations] = useLocalstorageState(
    'fxr:tabular:show-estimations',
    true
  )

  const { data, isFetching } = useFetchTabularQuery()
  const groupbyColumns = useSelector(
    (state) => state.temporal.present.groupby.columns
  )

  const filters = useSelector((state) => state.temporal.present.filters)
  const activeFilters = objectFilter(filters, (col) => filters[col].length > 0)

  const groupbyColumnsAndFilters = groupbyColumns.concat(
    Object.keys(activeFilters).filter((col) => !groupbyColumns.includes(col))
  )

  const prepareFilters = usePrepareFilters()
  const dispatch = useDispatch()

  if (data === undefined && !isFetching) {
    return <p>something went wrong.</p>
  }

  let rows = filterOrgtype(
    data?.rows || [],
    { both: 'both', [cmoName]: 'cmo', [pubName]: 'publisher' }[deficientOrgtype]
  )
  const compensatingRows = filterOrgtype(
    data?.compensating_rows || [],
    { both: 'both', [cmoName]: 'publisher', [pubName]: 'cmo' }[deficientOrgtype]
  )

  let columns = groupbyColumns.map((col) => columnsConfig[col])

  if (showEstimations) {
    columns = columns.concat([
      columnGroups.estimated_mpc_client_amount_due_range,
    ])
  }

  if (showTCA) {
    columns = columns.concat([columnGroups.mpc_total_comp_amounts])
  }

  columns = columns.concat([columnGroups.mpc_collection_amounts])

  if (showEstimations) {
    columns = columns.concat([
      columnsConfig.deficient_org,
      columnGroups.estimated_mpc_collection_amount_range,
      columnGroups.estimated_mpc_collection_amount_due_range,
    ])
  }

  if (!showEstimations && !showTCA) {
    columns = columns.concat([
      columnsConfig.mpc_collection_amount_difference_pct,
      columnsConfig.collection_deficient_org,
    ])
  }

  if (showTCA) {
    columns = columns.concat([
      columnGroups.spc_total_comp_amounts,
      columnGroups.mpc_total_comp_amounts_binned,
    ])
  }

  if (!showTCA) {
    columns = columns.concat([columnGroups.spc_collection_amounts])
  }

  const compensatingColumns = groupbyColumnsAndFilters
    .map((col) => columnsConfig[col])
    .concat(
      showTCA
        ? [
            columnGroups.mpc_total_comp_amounts,
            columnGroups.spc_total_comp_amounts,
            columnGroups.mpc_total_comp_amounts_binned,
          ]
        : [
            columnGroups.mpc_collection_amounts,
            columnGroups.spc_collection_amounts,
          ]
    )

  const setFiltersFromRow = (event, row) => {
    dispatch(filtersUpdated(prepareFilters(row.values)))
  }

  return (
    <>
      <Card tw="mb-4">
        <Card.Header>
          <H3>tabular data</H3>
        </Card.Header>
        <Card.Section>
          <div tw="flex items-stretch">
            <div tw="flex-1">
              <SelectColumns />
            </div>

            <VR />

            <div tw="flex-1 flex items-center pl-4">
              <p>display contexts with deficient </p>

              <div tw="mx-2">
                <MultiSwitch
                  options={deficientOrgtypes}
                  value={deficientOrgtype}
                  onChange={setDeficientOrgtype}
                />
              </div>
            </div>

            <VR />

            <div>
              <div tw="flex-initial flex items-center pl-4 py-1">
                <p>show TCA</p>

                <div tw="mx-2">
                  <Switch
                    name="show-tca"
                    value={showTCA}
                    onChange={setShowTCA}
                  />
                </div>
              </div>
              <HR />
              <div tw="flex-initial flex items-center pl-4 py-1">
                <p>show estimations</p>

                <div tw="mx-2">
                  <Switch
                    name="show-estimations"
                    value={showEstimations}
                    onChange={setShowEstimations}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card.Section>
        {data && 'compensation_score' in data && deficientOrgtype === 'both' && (
          <Card.Section>
            <div tw="flex items-center">
              <H2>{round(data.compensation_score, 4)}</H2>
              <p tw="ml-3 text-xs text-gray-500 italic">compensation score</p>
            </div>
          </Card.Section>
        )}
        <Card.Body>
          <RRTableable
            label={getTableLabel(groupbyColumnsAndFilters, false)}
            groupbyColumns={groupbyColumns}
            filters={activeFilters}
            areCompensatingRows={false}
            values={VALUES}
          >
            <Table
              isLoading={isFetching}
              data={rows}
              columns={columns}
              onRowClick={groupbyColumns.length > 0 ? setFiltersFromRow : null}
            />
          </RRTableable>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <H3>possible compensators</H3>
        </Card.Header>

        <Card.Body>
          <RRTableable
            label={getTableLabel(groupbyColumnsAndFilters, true)}
            groupbyColumns={groupbyColumns}
            filters={activeFilters}
            areCompensatingRows={true}
            values={VALUES}
          >
            <Table
              isLoading={isFetching}
              data={compensatingRows}
              columns={compensatingColumns}
              onRowClick={
                groupbyColumns.length + Object.keys(activeFilters).length > 0
                  ? setFiltersFromRow
                  : null
              }
            />
          </RRTableable>
        </Card.Body>
      </Card>
    </>
  )
}

export default TabularDisplay
