import React from 'react'
import tw, { styled } from 'twin.macro'
import { Box } from 'rebass/styled-components'
import { HiOutlineChevronRight } from 'react-icons/hi'
import { Link, useLocation } from 'react-router-dom'

export const SidebarLayout = ({ children }) => (
  <Box tw="relative w-full pl-60">{children}</Box>
)

const SidebarLinkP = tw.p`
  pl-4 py-2 flex-grow block
  transition-transform group-hover:(transform translate-x-1)
`
export const SidebarLink = React.memo(({ to, children }) => {
  let { pathname } = useLocation()

  const isActive = pathname === to

  return (
    <Box tw="mb-3 pr-3 w-full">
      <Link to={to}>
        <Box
          className={isActive ? '' : 'group'}
          tw="flex items-center
          rounded-r-full bg-white text-black transition border border-l-0"
          css={
            isActive ? tw`ring-1 ring-brand border-brand` : tw`border-gray-300`
          }
        >
          <SidebarLinkP>{children}</SidebarLinkP>
          <Box
            tw="rounded-full flex items-center justify-center w-10
            transition-colors group-hover:(bg-gray-100) h-10"
          >
            <HiOutlineChevronRight tw="w-4 h-4" />
          </Box>
        </Box>
      </Link>
    </Box>
  )
})

const Sidebar = tw.div`flex flex-col
  fixed top[60px] bottom-0 left-0 w-60
  bg-white dark:bg-gray-700 border-r border-gray-300 dark:border-gray-900 py-6`

Sidebar.Layout = SidebarLayout
Sidebar.Link = SidebarLink

export default Sidebar
