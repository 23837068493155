import React from 'react'

import { Badge } from '@fxr/ui'

import CaseReviewsSummary from './CaseReviewsSummary'

const CaseBadges = ({ ccase }) => {
  return (
    <>
      {ccase.status && <Badge.Neutral>{ccase.status}</Badge.Neutral>}
      {ccase.finder && <Badge.Neutral>{ccase.finder}</Badge.Neutral>}
      {ccase.events &&
        ccase.events.filter((ev) => ev.type === 'REVIEW_ADDED').length > 0 && (
          <Badge.Neutral>
            <CaseReviewsSummary events={ccase.events} />
          </Badge.Neutral>
        )}
    </>
  )
}

export default CaseBadges
