import React from 'react'
import tw, { styled } from 'twin.macro'
import { Box } from 'rebass/styled-components'
import ReactTooltip from 'react-tooltip'

import { generateUUID } from '@fxr/common/utils'

const Div = styled.div({
  ...tw`w-full`,
  '.__react_component_tooltip': tw`rounded py-1 px-2`,
})

const WithTooltip = ({ children, renderTooltip, ...props }) => {
  const id = `tooltip-${generateUUID()}`
  return (
    <Div>
      <Box data-tip data-for={id} tw="w-full">
        {children}
      </Box>
      <ReactTooltip id={id} type="dark" effect="solid" {...props}>
        {renderTooltip()}
      </ReactTooltip>
    </Div>
  )
}

export default WithTooltip
