import { createSlice } from '@reduxjs/toolkit'

import { loadState } from '../../app/actions'

const initialState = {
  columns: [],
}

const groupbySlice = createSlice({
  name: 'groupby',
  initialState,
  reducers: {
    groupbyColumnsUpdated: (state, action) => {
      state.columns = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadState, (state, action) => {
      // completely refresh state
      return action.payload.groupby
    })
  },
})

export const { groupbyColumnsUpdated } = groupbySlice.actions

export default groupbySlice.reducer
