import React, { useState, useEffect } from 'react'
import 'twin.macro'
import { useLocalstorageState } from 'rooks'

import { Button, Input, IconButtons, Card, Loading } from '@fxr/ui'

import { useUpdateCaseMutation } from './features/cases/caseApiSlice'

const CaseReviewer = ({ ccase, onDecisionSubmitted = null }) => {
  const [comment, setComment] = useState('')
  const [reviewer, setReviewer] = useLocalstorageState('fxr:reviewer', '')
  const [hasSetReviewer, setHasSetReviewer] = useState(reviewer !== '')

  const [updateCase, { isLoading: isUpdating }] = useUpdateCaseMutation()

  // if ccase changes, empty the comment field
  useEffect(() => {
    setComment('')
  }, [ccase])

  const submitDecision = (decision) => () => {
    const reviewEvent = {
      type: 'REVIEW_ADDED',
      data: {
        author: reviewer,
        decision,
        comment,
      },
    }

    updateCase({
      id: ccase.id,
      ccase: {
        events: [...(ccase.events || []), reviewEvent],
      },
    })
    onDecisionSubmitted && onDecisionSubmitted()
  }

  if (!hasSetReviewer)
    return (
      <>
        <Input
          tw="flex-1"
          value={reviewer || ''}
          placeholder="your name?"
          onChange={(e) => setReviewer(e.target.value)}
        />

        <Button.Primary onClick={() => setHasSetReviewer(true)}>
          done
        </Button.Primary>
      </>
    )

  if (isUpdating)
    return (
      <Card.Info>
        <Loading text="adding review..." />
      </Card.Info>
    )

  return (
    <>
      <Input
        tw="flex-1"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="your comment"
      />

      <IconButtons.Check onClick={submitDecision('accepted')} />
      <IconButtons.Close onClick={submitDecision('rejected')} />
    </>
  )
}

export default CaseReviewer
