import React from 'react'
import 'twin.macro'

import { VR } from '@fxr/ui'

const CaseReviewsSummary = ({ events = [] }) => {
  const reviews = events.filter((ev) => ev.type === 'REVIEW_ADDED')
  const nofPosReviews = reviews.filter((r) => r.data.decision === 'accepted')
    .length
  const nofNegReviews = reviews.filter((r) => r.data.decision === 'rejected')
    .length

  return (
    <div tw="flex">
      <span tw="text-green-600 font-bold">{nofPosReviews}</span>
      <VR tw="mx-2 dark:(bg-white! bg-opacity-50!)" />
      <span tw="text-red-600 font-bold">{nofNegReviews}</span>
    </div>
  )
}

export default CaseReviewsSummary
