import { useDispatch, useSelector } from 'react-redux'

import { Capturable } from '@fxr/ui'

import { resourceAdded } from './features/researchRequest/generatorSlice'

const useRRResourceable = ({ resourceType }) => {
  const dispatch = useDispatch()
  const onCapture = (label, data) =>
    dispatch(resourceAdded({ resource_type: resourceType, label, data }))
  /* const isCapturing = useSelector(
   *   (state) => state.researchRequestGenerator.isCapturing
   * ) */
  const isCapturing = useSelector(
    (state) => state.researchRequestGenerator.display
  )

  return {
    onCapture,
    isCapturing,
    Capturable: (props) => <Capturable active={isCapturing} {...props} />,
  }
}

export default useRRResourceable
