import React from 'react'
import Chart from 'react-google-charts'
import tw from 'twin.macro'

import Loading from '../Loading'

const Div = tw.div`bg-white rounded p-4`

const Sankey = ({ data }) => (
  <Div>
    <Chart
      width="100%"
      height="700px"
      chartType="Sankey"
      loader={<Loading />}
      data={data}
    />
  </Div>
)

export default Sankey
