import React, { useState, useEffect } from 'react'

import Loading from './Loading'

const getEnsureRender = (Component) => ({ children }) => {
  const [hasRendered, setHasRendered] = useState(false)

  useEffect(() => {
    setHasRendered(true)
  }, [])

  if (hasRendered) return children

  return <Component />
}

export default getEnsureRender
