import React from 'react'
import FileSaver from 'file-saver'
import tw from 'twin.macro'

import Button from '../Button'

const Div = tw.div`px-6 py-4 space-y-2 text-center`

export const DownloadButton = ({ content, fname }) => (
  <Button.Primary onClick={() => FileSaver.saveAs(content, fname)}>
    download file
  </Button.Primary>
)

const DownloadButtonViewer = ({ content, fname }) => {
  return (
    <Div>
      <p>no preview possible.</p>
      <DownloadButton content={content} fname={fname} />
    </Div>
  )
}

export default DownloadButtonViewer
