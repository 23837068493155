import React, { useState } from 'react'
import tw from 'twin.macro'

import { Card, H2, H3, HR, Loading, Error, ButtonNeutral } from '@fxr/ui'
import { objectFilter, round } from '@fxr/common/utils'

import { useFetchConsistentContextsQuery } from './features/data/dataApiSlice'
import { CONTEXT_COLS } from './constants'
import ContextBadges from './ContextBadges'
import useExploreContext from './useExploreContext'
import RRResourceable from './RRResourceable'

const getConsistentContextLabel = (consistentContext) =>
  `consistent-context-varying-${consistentContext.column_changed}`

const ConsistentContext = ({ consistentContext, context, index = null }) => {
  console.log({ consistentContext, context })

  const values = objectFilter(
    consistentContext,
    (key) => !CONTEXT_COLS.includes(key)
  )

  const exploreContext = useExploreContext()

  const columnChanged = consistentContext.column_changed

  return (
    <Card tw="relative">
      {index !== null && <Card.Dot>{index + 1}</Card.Dot>}
      <Card.Section>
        <ContextBadges
          context={context}
          getBadgeCss={(col) =>
            col === columnChanged ? tw`border-2 border-brand` : ``
          }
        />

        <ButtonNeutral tw="mt-2" onClick={() => exploreContext(context)}>
          explore this context
        </ButtonNeutral>
      </Card.Section>

      <Card.Section>
        <H3>MPC</H3>
        <p>
          <strong>cmo:</strong> {round(values.cmo_mpc_total_comp_amount, 2)}
        </p>
        <p>
          <strong>pub:</strong> {round(values.pub_mpc_total_comp_amount, 2)}
        </p>
        <HR />
        <p>{round(values.mpc_total_comp_amount_difference, 2)}</p>
      </Card.Section>

      <Card.Section>
        <H3>SPC</H3>
        <p>
          <strong>cmo:</strong> {round(values.cmo_spc_total_comp_amount, 2)}
        </p>
        <p>
          <strong>pub:</strong> {round(values.pub_spc_total_comp_amount, 2)}
        </p>
      </Card.Section>
    </Card>
  )
}

const ConsistentContexts = (props) => {
  const [loadNContexts, setLoadNContexts] = useState(20)

  const { data, isFetching } = useFetchConsistentContextsQuery({
    loadNContexts,
  })

  if (isFetching) return <Loading.Card />
  if (!data) return <Error />

  const consistentContexts = data.consistent_contexts
  const hasConsistentContexts =
    consistentContexts && consistentContexts.length > 0

  const increaseLoadNContexts = () => setLoadNContexts(loadNContexts + 10)

  return (
    <>
      <Card tw="mb-4">
        <Card.Header>
          <H2>consistent contexts</H2>
        </Card.Header>
      </Card>
      {hasConsistentContexts && (
        <>
          {consistentContexts.map((consistentContext, i) => {
            const context = objectFilter(consistentContext, (key) =>
              CONTEXT_COLS.includes(key)
            )
            return (
              <div tw="mb-4 relative" key={i}>
                <RRResourceable
                  resourceType="consistent_context"
                  label={getConsistentContextLabel(consistentContext)}
                  data={{ consistent_context: consistentContext, context }}
                >
                  <ConsistentContext
                    context={context}
                    index={i}
                    consistentContext={consistentContext}
                  />
                </RRResourceable>
              </div>
            )
          })}

          {isFetching ? (
            <Loading.Card />
          ) : (
            <ButtonNeutral onClick={increaseLoadNContexts} tw="w-full">
              load 10 more contexts
            </ButtonNeutral>
          )}
        </>
      )}
      {!hasConsistentContexts && !isFetching && (
        <Card>
          <Card.Body>
            <p>No instances were found. Are your filters perhaps empty?</p>
          </Card.Body>
        </Card>
      )}
    </>
  )
}

export default ConsistentContexts
