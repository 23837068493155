import React from 'react'
import 'twin.macro'

const Value = ({ val }) => {
  if (!Array.isArray(val)) return val

  if (val.length > 3)
    return (
      <span tw="italic cursor-help" title={val.join(', ')}>
        {val.length} values
      </span>
    )
  return val.join(', ')
}

const ContextBadges = ({ context, getBadgeCss = null }) => (
  <div tw="space-x-1 space-y-1">
    {Object.keys(context).map(
      (col) =>
        (!Array.isArray(context[col]) || context[col].length > 0) && (
          <span
            key={col}
            tw="p-1 rounded-sm bg-gray-200 dark:bg-gray-800 dark:text-white inline-block"
            css={getBadgeCss ? getBadgeCss(col, context[col]) : ``}
          >
            <span tw="font-bold">{col}:</span> <Value val={context[col]} />
          </span>
        )
    )}
  </div>
)

export default ContextBadges
