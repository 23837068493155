import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { loadState } from './app/actions'
import usePrepareFilters from './usePrepareFilters'

const useExploreContext = ({ caseId = null, pushToHistory = true } = {}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const prepareFilters = usePrepareFilters()

  return (context) => {
    if (pushToHistory) history.push({ pathname: '/', state: { caseId } })
    dispatch(loadState({ filters: prepareFilters(context) }))
  }
}

export default useExploreContext
