import React from 'react'
import 'twin.macro'
import { Flex, Box, Text } from 'rebass/styled-components'
import { GrFormClose } from 'react-icons/gr'

import { IconButtonConcealed } from '../Button'
import Input from '../forms/Input'
import Slider from '../forms/Slider'
import Select from '../forms/Select'

// Define a text UI for filtering
export function TextColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <Input
      type="text"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`search ${count} rows...`}
    />
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export const SliderColumnFilter = ({ range = undefined }) => ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [min, max] =
    range ||
    React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach((row) => {
        const value = row.values[id]
        if (!isNaN(value)) {
          min = Math.min(row.values[id], min)
          max = Math.max(row.values[id], max)
        }
      })
      return [min, max]
    }, [id, preFilteredRows])

  return (
    <Box tw="flex items-center">
      <Box tw="flex-1 flex items-center mr-1">
        <Slider
          min={min}
          max={max}
          steps={5}
          value={filterValue || min}
          onChange={setFilter}
        />
      </Box>
      <IconButtonConcealed onClick={() => setFilter(undefined)}>
        <GrFormClose />
      </IconButtonConcealed>
    </Box>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
  displayMinMax = true,
}) {
  const [min, max] = React.useMemo(() => {
    if (!displayMinMax) return [null, null]

    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <Flex tw="items-center">
      <Input
        value={filterValue[0] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={['Min', displayMinMax ? `(${min})` : ''].join(' ')}
        style={{ flex: '1 0 0' }}
      />
      <Text tw="mx-1">to</Text>
      <Input
        value={filterValue[1] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={['Max', displayMinMax ? `(${max})` : ''].join(' ')}
        style={{ flex: '1 0 0' }}
      />
    </Flex>
  )
}

export function NumberLowerBoundFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const min = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min)
    })
    return min
  }, [id, preFilteredRows])

  return (
    <Flex tw="items-center">
      {'>'}
      <Input
        value={filterValue || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value
          setFilter(val ? parseInt(val, 10) : undefined)
        }}
        placeholder={`Min (${min})`}
        style={{ flex: '1 0 0' }}
      />
    </Flex>
  )
}

export function NumberUpperBoundFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const max = React.useMemo(() => {
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row) => {
      max = Math.max(row.values[id], max)
    })
    return max
  }, [id, preFilteredRows])

  return (
    <Flex tw="items-center">
      {'<'}
      <Input
        value={filterValue || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value
          setFilter(val ? parseInt(val, 10) : undefined)
        }}
        placeholder={`Max (${max})`}
        style={{ flex: '1 0 0' }}
      />
    </Flex>
  )
}

/* function fuzzyText(rows, id, filterValue) {
 *   return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
 * }
 *
 * // Let the table remove the filter if the string is empty
 * fuzzyText.autoRemove = (val) => !val */

const textWithWhitelist = (whitelist) => (rows, id, filterValue) => {
  const query = String(filterValue).toLowerCase()
  return rows.filter((row) => {
    const rowValue = row.values[id]

    return rowValue !== undefined
      ? String(rowValue).toLowerCase().startsWith(query) ||
          whitelist.includes(rowValue)
      : true
  })
}

const text = (rows, id, filterValue) => {
  const query = String(filterValue).toLowerCase()
  return rows.filter((row) => {
    const rowValue = row.values[id]
    return rowValue !== undefined
      ? String(rowValue).toLowerCase().startsWith(query)
      : true
  })
}

function greaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

greaterThan.autoRemove = (val) => typeof val !== 'number'

function lessThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id]
    return rowValue <= filterValue
  })
}

lessThan.autoRemove = (val) => typeof val !== 'number'

export const between = (rows, ids, filterValue, formatter = null) => {
  let [min, max] = filterValue || []

  min = typeof min === 'number' ? min : -Infinity
  max = typeof max === 'number' ? max : Infinity

  if (min > max) {
    const temp = min
    min = max
    max = temp
  }

  return rows.filter((row) => {
    return ids.some((id) => {
      let rowValue = row.values[id]

      if (formatter) rowValue = formatter(rowValue)

      // patch isNaN
      return isNaN(rowValue) || (rowValue >= min && rowValue <= max)
    })
  })
}

between.autoRemove = (val) =>
  !val || (typeof val[0] !== 'number' && typeof val[1] !== 'number')

export const tableFilterTypes = {
  /*   fuzzyText: fuzzyText, */
  text,
  textWithWhitelist,
  textOrUnknown: textWithWhitelist(['unknown']),
  greaterThan,
  lessThan,
  between,
  betweenAbs: (rows, ids, filterValue) =>
    between(rows, ids, filterValue, Math.abs),
}
