import React from 'react'
import tw from 'twin.macro'

import Panel from './Panel'
import useChevronDropdown from './useChevronDropdown'

const InnerHeader = tw.div`flex items-center space-x-2 cursor-pointer`

const ExpandablePanel = ({ header, children }) => {
  const { isOpen, setIsOpen, Chevron } = useChevronDropdown()

  const onClickHeader = (e) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  return (
    <Panel>
      <Panel.Header>
        <InnerHeader onClick={onClickHeader}>
          <Chevron />
          {header}
        </InnerHeader>
      </Panel.Header>

      {isOpen && <Panel.Body>{children}</Panel.Body>}
    </Panel>
  )
}

export default ExpandablePanel
