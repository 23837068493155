import React from 'react'
import { Box } from 'rebass/styled-components'
import tw, { styled } from 'twin.macro'

export const Tab = styled.div(({ active, small }) => [
  tw`inline-block px-3 py-2
     rounded border cursor-pointer
     border-gray-300 bg-gray-100 dark:(border-gray-800 bg-gray-800 text-white)
  `,
  active &&
    tw`
   border-brand-darker bg-brand-dark text-white
   dark:(border-brand-darker bg-brand-dark text-white)
  `,
  small && tw`px-2 py-1`,
])

const Tabs = ({ tabs, activeTab, onSetActiveTab }) => (
  <Box tw="space-x-2">
    {tabs.map((tab, i) => (
      <Tab
        key={i}
        onClick={() => onSetActiveTab(tab)}
        active={activeTab === tab}
      >
        {tab}
      </Tab>
    ))}
  </Box>
)

export default Tabs
