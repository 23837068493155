import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { usePassFromState, dynamicBaseQuery } from '../data/dataApiSlice'

import { API_BASE_URL, API_VERSION } from '../../constants'

export const defaultApiSlice = createApi({
  reducerPath: 'defaultApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/${API_VERSION}`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState()

      const token = state.auth0.token

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchAvailableComposers: builder.query({
      query: () => ({
        url: '/available_composers',
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useFetchAvailableComposersQuery } = defaultApiSlice
