import React from 'react'
import { theme } from 'twin.macro'
import 'react-step-progress-bar/styles.css'
import { Box } from 'rebass/styled-components'
import { ProgressBar as RProgressBar } from 'react-step-progress-bar'

const filledBackground = `linear-gradient(to right, ${theme(
  'colors.brand.darker'
)}, ${theme('colors.brand.DEFAULT')})`

const ProgressBar = ({ percent }) => (
  <Box tw="rounded-full bg-gray-200 p-0.5">
    <RProgressBar percent={percent} filledBackground={filledBackground} />
  </Box>
)

export default ProgressBar
