import React from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'

import Navbar from './Navbar'
import Home from './Home'
import RankedContexts from './RankedContexts'
import InterestingCases from './InterestingCases'
import InterestingCaseView from './InterestingCaseView'
import CollectionDiscrepancies from './CollectionDiscrepancies'
import EnsureLoadStateFromURL from './EnsureLoadStateFromURL'
import RRGenerator from './RRGenerator'
import SULCOverviews from './SULCOverviews'

const Router = () => {
  return (
    <HashRouter>
      <Navbar />

      <EnsureLoadStateFromURL>
        <RRGenerator />
        <Switch>
          <Route path="/ranked-contexts">
            <RankedContexts />
          </Route>
          <Route path="/interesting-cases/:id">
            <InterestingCaseView />
          </Route>
          <Route path="/interesting-cases">
            <InterestingCases />
          </Route>
          <Route path="/big-fish">
            <CollectionDiscrepancies />
          </Route>
          <Route path="/sulc-overviews">
            <SULCOverviews />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </EnsureLoadStateFromURL>
    </HashRouter>
  )
}

export default Router
