import React from 'react'
import tw from 'twin.macro'
import { Text } from 'rebass/styled-components'

import Label from './Label'
import Error from '../Error'

const Span = tw(Text)``

const FormItem = ({ label, name, error, children }) => {
  const hasError = error && error.message
  return (
    <div>
      {label && (
        <Label for={name}>
          <Span as="span" css={hasError ? tw`text-red-700` : ''}>
            {label}
          </Span>
        </Label>
      )}
      <div>{children}</div>
      {hasError && <Error>{error.message}</Error>}
    </div>
  )
}

export default FormItem
