import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import tw from 'twin.macro'

import useCollectionDiscrepancies from '@fxr/ui/organisms/useCollectionDiscrepancies'
import { ButtonNeutral, H2, Card, Loading } from '@fxr/ui'
import { objectMap } from '@fxr/common/utils'
import { NonTablePaginator } from '@fxr/ui/rtable/Paginator'

import { loadState } from './app/actions'
import { useFetchBigFishQuery } from './features/data/dataApiSlice'
import usePrepareFilters from './usePrepareFilters'

const Discrepancy = ({ discrepancy, index = null }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const prepareFilters = usePrepareFilters()

  const onViewContext = () => {
    history.push('/tabular')
    dispatch(
      loadState({
        filters: prepareFilters(objectMap(discrepancy.scope, (val) => [val])),
      })
    )
  }

  return (
    <Card tw="relative">
      {index !== null && <Card.Dot>{index + 1}</Card.Dot>}
      <Card.Section css={tw`pr-1`}>
        <div tw="flex justify-between items-center">
          <p>Whenever this was the case:</p>

          <ButtonNeutral onClick={onViewContext}>view context</ButtonNeutral>
        </div>
      </Card.Section>
      <Card.Dictionary dictionary={discrepancy.scope} />
      <Card.Body>
        <p>{discrepancy.renderText()}</p>
      </Card.Body>
    </Card>
  )
}

const CollectionDiscrepancies = (props) => {
  const { data, isFetching } = useFetchBigFishQuery()
  const [page, setPage] = React.useState(0)
  const pageSize = 30

  const { discrepancies } = React.useMemo(
    () =>
      useCollectionDiscrepancies({
        unparsedDiscrepancies: data || [],
      }),
    [data]
  )

  if (isFetching) return <Loading.Card />
  if (data === null || discrepancies === null)
    return <p>something went wrong.</p>

  const pageCount = parseInt((discrepancies?.length || 0) / pageSize) + 1

  const paginatedDiscrepancies = discrepancies
    ? discrepancies.slice(page * pageSize, (page + 1) * pageSize)
    : []

  return (
    <div tw="max-w-4xl mx-auto p-4">
      <Card tw="mb-4 sticky">
        <Card.Header>
          <div tw="flex items-center justify-between">
            <H2>Big Fish</H2>

            <NonTablePaginator
              index={page}
              count={pageCount}
              setIndex={setPage}
            />
          </div>
        </Card.Header>
      </Card>

      <>
        {paginatedDiscrepancies.map((discrepancy, i) => (
          <div key={page * pageSize + i} tw="mb-4">
            <Discrepancy
              discrepancy={discrepancy}
              index={page * pageSize + i}
            />
          </div>
        ))}
      </>
    </div>
  )
}

export default CollectionDiscrepancies
