import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'

import Base from './Base'

const selectOnFocus = (event) => event.target.select()

const Input = React.forwardRef((props, ref) => {
  const onFocus = 'onFocus' in props ? props.onFocus : selectOnFocus

  return <Base as="input" {...props} onFocus={onFocus} ref={ref} />
})

export const InputWithOwnState = ({ onChange, onBlur, value, ...props }) => {
  const [ownValue, setOwnValue] = useState(value)

  useEffect(() => {
    setOwnValue(value)
  }, [value])

  const onInnerChange = (e) => {
    setOwnValue(e.target.value)
  }

  const onInnerBlur = (e) => {
    onChange(ownValue)
    onBlur && onBlur(e)
  }

  return (
    <Input
      onBlur={onInnerBlur}
      onChange={onInnerChange}
      value={ownValue}
      {...props}
    />
  )
}

export const InputConcealed = tw(Input)`
  border-0

  focus:(outline[none] border-b-2)
`

Input.WithOwnState = InputWithOwnState

export default Input
