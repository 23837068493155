import React, { forwardRef } from 'react'

const attrs = (Component, attrsResolver) =>
  forwardRef((props, ref) => <Component {...attrsResolver(props)} ref={ref} />)

attrs.className = (Component, className, attrsResolver = null) =>
  attrs(Component, (props) => ({
    ...props,
    className: `${className || ''} ${props.className || ''}`,
    ...(attrsResolver ? attrsResolver(props) : {}),
  }))

export default attrs
