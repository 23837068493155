import React from 'react'
import tw from 'twin.macro'
import { useSelector, useDispatch } from 'react-redux'

import { MultiSelect, Select, VR } from '@fxr/ui'

import { filterUpdated } from './features/cases/filtersSlice'

const FilterItem = tw.div`flex[1 0 0px]`
const FilterSpacer = tw(VR)`mx-2`

const finders = ['cliquer', 'treefinder', 'user']
const statuses = ['new', 'accepted', 'rejected', 'rr_generated', 'sent_out']
const nofReviews = [0, 1, 2, 3, 4, 5, 6, 7, 8]
const orgtypes = ['cmo', 'publisher']

const InterestingCaseFilters = (props) => {
  const filters = useSelector((state) => state.caseFilters)

  const dispatch = useDispatch()

  const getSetFilter = (name) => (value) =>
    dispatch(filterUpdated({ name, value }))

  return (
    <div tw="flex">
      <FilterItem>
        <Select
          value={filters.finder}
          options={finders}
          onChange={getSetFilter('finder')}
          isMulti
          label="finder"
        />
      </FilterItem>

      <FilterSpacer />

      <FilterItem>
        <Select
          value={filters.status}
          options={statuses}
          onChange={getSetFilter('status')}
          isMulti
          label="status"
        />
      </FilterItem>

      <FilterSpacer />

      <FilterItem>
        <Select
          value={filters.nof_negative_reviews}
          options={nofReviews}
          onChange={getSetFilter('nof_negative_reviews')}
          isMulti
          label="# negative reviews"
        />
      </FilterItem>

      <FilterSpacer />

      <FilterItem>
        <Select
          value={filters.nof_positive_reviews}
          options={nofReviews}
          onChange={getSetFilter('nof_positive_reviews')}
          isMulti
          label="# positive reviews"
        />
      </FilterItem>

      <FilterSpacer />

      <FilterItem>
        <Select
          value={filters.deficient_orgtype}
          options={orgtypes}
          onChange={getSetFilter('deficient_orgtype')}
          isMulti
          label="deficient orgtype"
        />
      </FilterItem>
    </div>
  )
}

export default InterestingCaseFilters
