import React from 'react'
import 'twin.macro'
import { useSelector, useDispatch } from 'react-redux'
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useLocation,
  Redirect,
} from 'react-router-dom'

import { VR, Tab, Sidebar, H2, H4, HR } from '@fxr/ui'

import Filters from './Filters'
import ContextBadges from './ContextBadges'
import TabularDisplay from './TabularDisplay'
/* import Spreadsheet from './Spreadsheet' */
import OriginalRowsDisplay from './OriginalRowsDisplay'
import BarPlotDisplay from './BarPlotDisplay'
import SankeyDisplay from './SankeyDisplay'
import CollectionShareVisualization from './CollectionShareVisualization'
import ConfidenceDistributions from './ConfidenceDistributions'
import ConsistentContexts from './ConsistentContexts'
import CompositionMetadata from './CompositionMetadata'
import { useInterestingCaseBarToggle } from './InterestingCaseBar'
import { filtersSet } from './features/filters/filtersSlice'

const CurrentContextBar = () => {
  const filters = useSelector((state) => state.temporal.present.filters)

  return (
    <div tw="border-b border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-700 py-2 px-3">
      <H4 tw="inline-block mr-2">current context</H4>
      {Object.keys(filters).length === 0 && (
        <span tw="italic dark:text-white">&nbsp;none</span>
      )}

      {Object.keys(filters).length > 0 && <ContextBadges context={filters} />}
    </div>
  )
}

const Home = (props) => {
  let { path, url } = useRouteMatch()

  if (url[url.length - 1] === '/') url = url.slice(0, url.length - 2)
  if (path[path.length - 1] === '/') path = path.slice(0, path.length - 2)

  let { pathname } = useLocation()
  let location = useLocation()

  const dispatch = useDispatch()

  const onClearAllFilters = () => dispatch(filtersSet({}))

  const { showICB, renderedICBComponent } = useInterestingCaseBarToggle()

  return (
    <Sidebar.Layout>
      <Sidebar
        tw="pt-4 px-2 overflow-y-scroll"
        style={{ marginBottom: showICB ? 60 : 0 }}
      >
        <div tw="flex justify-between items-center">
          <H2>filters</H2>
          <p
            tw="text-xs text-gray-500 cursor-pointer underline italic"
            onClick={onClearAllFilters}
          >
            clear all
          </p>
        </div>
        <HR />
        <div tw="mb-3" />

        <Filters />
      </Sidebar>

      <div tw="min-height[calc(100vh - 60px)] dark:bg-gray-800">
        <CurrentContextBar />

        <div tw="border-b border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-700 py-1 flex">
          {[
            [
              'explore',
              [
                ['tabular display', 'tabular'],
                ['bar plot', 'barplot'],
                ['sankey', 'sankey'],
                ['original rows', 'original-rows'],
              ],
            ],
            [
              'validate',
              [
                ['collection shares', 'collection-shares'],
                ['confidence distributions', 'confidence-distributions'],
                ['consistent contexts', 'consistent-contexts'],
                ['composition metadata', 'composition-metadata'],
              ],
            ],
          ].map((section, i) => {
            const [title, items] = section

            return (
              <React.Fragment key={section}>
                <div tw="flex-1 px-3">
                  <H4>{title}</H4>
                  <div tw="space-x-2">
                    {items.map((item) => {
                      const [name, href] = item
                      const to = `${url}/${href}`

                      return (
                        <Link to={to} key={href}>
                          <Tab small active={pathname === to}>
                            {name}
                          </Tab>
                        </Link>
                      )
                    })}
                  </div>
                </div>
                {i === 0 && <VR />}
              </React.Fragment>
            )
          })}
        </div>

        <div tw="m-4 h-full">
          <Switch>
            <Route path={`${url}/tabular`}>
              <TabularDisplay />
            </Route>

            <Route path={`${url}/barplot`}>
              <BarPlotDisplay />
            </Route>

            <Route path={`${url}/sankey`}>
              <SankeyDisplay />
            </Route>

            <Route path={`${url}/original-rows`}>
              <OriginalRowsDisplay />
            </Route>

            <Route path={`${url}/collection-shares`}>
              <CollectionShareVisualization />
            </Route>

            <Route path={`${url}/confidence-distributions`}>
              <ConfidenceDistributions />
            </Route>

            <Route path={`${url}/consistent-contexts`}>
              <ConsistentContexts />
            </Route>

            <Route path={`${url}/composition-metadata`}>
              <CompositionMetadata />
            </Route>

            <Route path={`${url}/`}>
              <Redirect to={{ ...location, pathname: '/tabular' }} />
            </Route>
          </Switch>
        </div>
      </div>

      {showICB && <div tw="height[60px]" />}

      <div tw="fixed bottom-0 left-0 right-0 zIndex[100000]">
        {renderedICBComponent}
      </div>
    </Sidebar.Layout>
  )
}

export default Home
