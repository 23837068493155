import 'twin.macro'
import { Auth0Provider } from '@auth0/auth0-react'

import { Toaster } from '@fxr/ui'

import Router from './Router'
import AuthenticatedWithAuth0 from './AuthenticatedWithAuth0'
import EnsureComposerIsSet from './EnsureComposerIsSet'

function App() {
  return (
    <Auth0Provider
      domain="dev-rojrq7r0.eu.auth0.com"
      clientId="EMLIJrdFTPBaQI5QGM7mwhEc0VyKM2hl"
      redirectUri={window.location.origin}
      audience="https://combe.fxrinsights.com/api"
      scope=""
    >
      <Toaster />
      <div tw="dark:bg-gray-800">
        <AuthenticatedWithAuth0>
          <EnsureComposerIsSet>
            <Router />
          </EnsureComposerIsSet>
        </AuthenticatedWithAuth0>
      </div>
    </Auth0Provider>
  )
}

export default App
