import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  context: null,
  caseId: null,
  recipient: '',
  resources: {},
  isCapturing: false,
  display: false,
}

const resolveLabel = (labels, newLabel) => {
  // make newLabel unique w.r.t. labels
  const count = labels.filter((label) => label === newLabel).length
  return count === 0 ? newLabel : `${newLabel}-${count}`
}

const generatorSlice = createSlice({
  name: 'researchRequestGenerator',
  initialState,
  reducers: {
    contextSet: (state, action) => {
      state.context = action.payload
    },
    caseIdSet: (state, action) => {
      state.caseId = action.payload
    },
    researchRequestCleared: (state, action) => {
      return initialState
    },
    recipientSet: (state, action) => {
      state.recipient = action.payload
    },
    isCapturingSet: (state, action) => {
      state.isCapturing = action.payload
    },
    displaySet: (state, action) => {
      state.display = action.payload
    },
    resourceAdded: (state, action) => {
      state.resources[
        resolveLabel(Object.keys(state.resources), action.payload.label)
      ] = action.payload
    },
    resourceRemoved: (state, action) => {
      delete state.resources[action.payload]
    },
  },
})

export const {
  contextSet,
  caseIdSet,
  researchRequestCleared,
  recipientSet,
  isCapturingSet,
  displaySet,
  resourceAdded,
  resourceRemoved,
} = generatorSlice.actions

export default generatorSlice.reducer
