import React, { useState, useMemo } from 'react'
import 'twin.macro'
import { useTable, useBlockLayout } from 'react-table'

import RTable from '@fxr/ui/rtable/Table'
import useChevronDropdown from '@fxr/ui/useChevronDropdown'
import {
  Loading,
  Error,
  Card,
  H3,
  ButtonPrimary,
  MultiSwitch,
  VR,
} from '@fxr/ui'
import { objectMap } from '@fxr/common/utils'

import { useFetchOriginalRowsJsonQuery } from './features/data/dataApiSlice'

const headersToCols = (headers, prefix = '') =>
  [...new Set(headers)].map((header) => ({
    id: `${prefix}${header}`,
    Header: header,
    accessor: header,
  }))

const OriginalRowsDocumentTable = ({ rows, columns }) => {
  const tableInstance = useTable(
    {
      columns,
      data: rows,
    },
    useBlockLayout
  )

  return (
    <RTable.NavbarOffset>
      <RTable
        tableInstance={tableInstance}
        sortable={false}
        filterable={false}
        paginated={false}
        renderTFoot={false}
        renderFooter={false}
      />
    </RTable.NavbarOffset>
  )
}

const OriginalRowsDocument = ({ fname, rows, columns }) => {
  const { isOpen, Chevron } = useChevronDropdown()

  const nRows = rows.length

  return (
    <>
      <div tw="border bg-gray-100 dark:bg-gray-800 dark:border-gray-900 px-2 py-1 flex items-center">
        {nRows > 0 && <Chevron />}
        <p tw="ml-2">
          {fname} <span tw="italic">({nRows} rows)</span>
        </p>
      </div>
      {isOpen && nRows > 0 && (
        <Loading.Ensure.Padded>
          <OriginalRowsDocumentTable rows={rows} columns={columns} />
        </Loading.Ensure.Padded>
      )}
    </>
  )
}

const OriginalRowsTabbedGrid = ({ org2fname2data }) => {
  const [activeOrg, setActiveOrg] = useState(Object.keys(org2fname2data)[0])
  const [activeColType, setActiveColType] = useState('both')

  let fname2data = org2fname2data[activeOrg]

  return (
    <>
      <Card.Section tw="flex items-stretch">
        <div>
          <p tw="font-bold text-xs">org</p>
          <MultiSwitch
            options={Object.keys(org2fname2data)}
            value={activeOrg}
            onChange={setActiveOrg}
          />
        </div>
        <VR tw="mx-5" />
        <div>
          <p tw="font-bold text-xs">columns</p>
          <MultiSwitch
            options={['both', 'fxr', 'raw']}
            value={activeColType}
            onChange={setActiveColType}
          />
        </div>
        {/* <VR tw="mx-5" />
            <div>
            <p tw="font-bold text-xs">collection type</p>
            <MultiSwitch
            options={['both', 'spc', 'mpc']}
            value={activeCollectionType}
            onChange={setActiveCollectionType}
            />
            </div> */}
      </Card.Section>
      <Card.Section>
        {Object.keys(fname2data).map((fname) => {
          const data = fname2data[fname]

          const { rows, raw_cols: rawCols, fxr_cols: fxrCols } = data

          const commonColumnNames = ['doctype', 'document_id', 'original_index']
          const columns = headersToCols(commonColumnNames, '')

          if (activeColType === 'both' || activeColType === 'raw') {
            columns.push({
              Header: 'raw',
              columns: headersToCols(
                rawCols.filter((col) => !commonColumnNames.includes(col)),
                'raw_'
              ),
            })
          }

          if (activeColType === 'both' || activeColType === 'fxr') {
            columns.push({
              Header: 'fxr',
              columns: headersToCols(
                fxrCols.filter((col) => !commonColumnNames.includes(col)),
                'fxr_'
              ),
            })
          }

          return (
            <div key={fname} tw="mb-2">
              <OriginalRowsDocument
                fname={fname}
                rows={rows}
                columns={columns}
              />
            </div>
          )
        })}
      </Card.Section>
    </>
  )
}

const OriginalRowsDisplay = (props) => {
  const [ignoreUpperLimit, setIgnoreUpperLimit] = useState(false)
  const xspr = useState(null)[0]
  /*   const filters = useSelector((state) => state.temporal.present.filters) */

  let { data, isFetching } = useFetchOriginalRowsJsonQuery({
    params: { ignoreUpperLimit },
  })

  const org2fname2data = data?.data || null
  console.log({ org2fname2data, data })

  const hasUserError = Boolean(data) && 'type' in data && 'message' in data
  const userError = data
  /*   const userError = data?.error */
  /*   const hasUserError = Boolean(userError) */
  const upperLimitExceeded = userError?.type === 'ROWS_UPPER_LIMIT_EXCEEDED'

  let userErrorMessage = 'something went wrong.'
  if (userError?.message)
    userErrorMessage = `something went wrong: ${userError.message}`
  if (upperLimitExceeded) userErrorMessage = userError.message

  return (
    <Card>
      <Card.Header tw="flex items-center">
        <H3>original rows</H3>
        <div tw="flex-1" />
        <ButtonPrimary
          /*           onClick={() => downloadXSpreadsheet(xspr, filters)} */
          disabled={xspr === null}
        >
          download as .xlsx
        </ButtonPrimary>
      </Card.Header>

      {isFetching ? (
        <Card.Body>
          <Loading />
        </Card.Body>
      ) : hasUserError ? (
        <div>
          <Error>{userErrorMessage}</Error>

          {upperLimitExceeded && (
            <Card.Body>
              <p>
                you can load the data anyway, but it might slow down your
                computer:
              </p>
              <ButtonPrimary onClick={() => setIgnoreUpperLimit(true)}>
                ignore upper limit and load anyway
              </ButtonPrimary>
            </Card.Body>
          )}
        </div>
      ) : (
        org2fname2data && (
          <OriginalRowsTabbedGrid org2fname2data={org2fname2data} />
        )
      )}
    </Card>
  )
}

export default OriginalRowsDisplay
