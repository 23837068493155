import { useState } from 'react'
import { mergeNested } from './utils'

const useObjectState = () => {
  const [state, setState] = useState({})

  const updateState = (newPartialState) => {
    setState(mergeNested(state, newPartialState))
  }

  return {
    state,
    setState,
    updateState,
  }
}

export default useObjectState
