import { isObject, isString } from '@fxr/common/utils'
import TreeModel from 'tree-model'

const toIdFormat = (nodes, path = []) => {
  const joinedPath = path.join('.')
  const getId = (key) => (path.length > 0 ? `${joinedPath}.${key}` : key)

  if (isString(nodes)) {
    return [
      {
        id: getId(nodes),
        label: nodes,
      },
    ]
  }

  if (Array.isArray(nodes)) {
    return nodes.map((obj) => toIdFormat(obj, path)[0])
  }

  if (isObject(nodes)) {
    const mappedNodes = Object.keys(nodes).map((key) => ({
      id: getId(key),
      label: key,
      children: toIdFormat(nodes[key].children, [...path, key]),
    }))

    return mappedNodes
  }

  return {}
}

const _toLabelValueFormat = (data) => {
  if (Array.isArray(data)) return data.map(_toLabelValueFormat)

  const splitId = data.id.split('.')
  return {
    value: data.id,
    label: splitId[splitId.length - 1],
    children: data.children ? data.children.map(_toLabelValueFormat) : null,
  }
}

class Tree {
  constructor(data) {
    this.data = toIdFormat(data)

    this._originalData = data
  }

  toLabelValueFormat = () => {
    if (!this.lvData) this.lvData = _toLabelValueFormat(this.data)

    return this.lvData
  }

  setTreeModel = () => {
    if (!this.treeModel) {
      if (this.data.length > 0)
        this.treeModel = new TreeModel().parse(this.data[0])
    }

    return this.treeModel
  }

  resolveLabel = (label) => {
    const labels = label.split('|')

    const ids = []

    this.setTreeModel()

    if (!this.treeModel) return []

    this.treeModel.walk((node) => {
      const id = node.model.id

      if (!id) return true

      const splitId = id.split('.')

      labels.forEach((label) => {
        // if it's the full id
        if (label === id) {
          ids.push(id)
        }
        // or a partial id
        if (splitId.includes(label)) {
          ids.push(id)
        }
        // TODO: resolve 'b.c' to 'a.b.c'?
      })
    })

    return ids
  }

  nodes = () => {
    this.setTreeModel()

    const ids = []

    if (!this.treeModel) return []

    this.treeModel.walk((node) => {
      const id = node.model.id

      ids.push(id)
    })

    return ids
  }
}

export default Tree
