import React from 'react'
import 'twin.macro'

import CaseEvent from './CaseEvent'

const CaseEvents = ({ events }) => {
  if (events.length === 0) {
    return <p>no events yet.</p>
  }

  return (
    <>
      {events.map((event, i) => (
        <div tw="mb-2" key={i}>
          <CaseEvent event={event} />
        </div>
      ))}
    </>
  )
}

export default CaseEvents
