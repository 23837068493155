import tw from 'twin.macro'
import React, { useState, useEffect, useRef } from 'react'

import { component2canvas, fileType } from '@fxr/common/component2image'

import withAutoSize from './charts/withAutoSize'
import Capturable from './Capturable'

const RendererComponent = tw.div`fixed top-0 left-0 z-index[-100]`

const Renderer = ({ children, width, height, onSetRef }) => {
  const ref = useRef()

  useEffect(() => {
    ref.current && setTimeout(() => onSetRef(ref), 100)
  }, [ref.current])

  return (
    <RendererComponent ref={ref} style={{ width, height }}>
      {children}
    </RendererComponent>
  )
}

const Child2canvas = ({
  children,
  width,
  height,
  onCanvas,
  imageType = fileType.PNG,
  getSetShouldRender = null,
}) => {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    getSetShouldRender && getSetShouldRender(setShouldRender)
  }, [])

  const onSetRef = (ref) => {
    component2canvas(ref, {
      html2CanvasOptions: {
        /* width,
         * height,
         * windowWidth: width,
         * windowHeight: height, */
        /*         foreignObjectRendering: true, */
      },
    }).then((canvas) => {
      onCanvas({ canvas, dataURL: canvas.toDataURL(imageType, 1.0) })
      setShouldRender(false)
    })
  }

  const onClick = () => setShouldRender(true)

  return (
    <>
      {shouldRender && (
        <Renderer width={width} height={height} onSetRef={onSetRef}>
          {children}
        </Renderer>
      )}
      <Capturable active={true} onClick={onClick} />
    </>
  )
}

export default Child2canvas
