import React from 'react'
import 'twin.macro'
import { useSelector, useDispatch } from 'react-redux'

import { Select, HR, TreeSelect } from '@fxr/ui'

import { filterUpdated } from './features/filters/filtersSlice'
import { useFetchTabularQuery } from './features/data/dataApiSlice'
import { FILTERABLE_CONTEXT_COLS } from './constants'
import InterestingCaseCreator from './InterestingCaseCreator'

const contextCol2component = {
  /*   usage_type: TreeSelect, */
  territory: TreeSelect,
}
// set defaults
FILTERABLE_CONTEXT_COLS.forEach((col) => {
  if (!(col in contextCol2component)) contextCol2component[col] = Select
})

export const FilterInputs = ({
  filters,
  getOnChange,
  concol2availValues = null,
  isLoading = false,
}) => {
  if (!filters) return null

  const renderLabel = (concol) => (
    <p tw="flex items-center justify-between">
      {concol}{' '}
      {(filters[concol] || []).length > 0 && (
        <span
          tw="text-xs text-gray-400 cursor-pointer underline italic ml-2 inline-block"
          onClick={() => getOnChange(concol)([])}
        >
          clear
        </span>
      )}
    </p>
  )

  const renderSelect = ({ concol, onChange }) => {
    const Component = contextCol2component[concol]
    return (
      <div key={concol}>
        <Component
          name={`filter-${concol}`}
          label={renderLabel(concol)}
          isLoading={isLoading}
          isSearchable
          isMulti
          creatable={true}
          closeMenuOnSelect={false}
          value={filters[concol] || null}
          options={concol2availValues ? concol2availValues[concol] || [] : []}
          onChange={onChange}
        />
      </div>
    )
  }

  return (
    <>
      {FILTERABLE_CONTEXT_COLS.map((concol) =>
        renderSelect({ concol, onChange: getOnChange(concol) })
      )}
    </>
  )
}

const Filters = (props) => {
  const filters = useSelector((state) => state.temporal.present.filters)
  const dispatch = useDispatch()

  const { data, isFetching } = useFetchTabularQuery()

  const getOnChange = (concol) => (value) => {
    dispatch(filterUpdated({ name: concol, value }))
  }

  return (
    <div tw="space-y-3 mb-8">
      <FilterInputs
        filters={filters}
        getOnChange={getOnChange}
        concol2availValues={data?.concol2avail_values}
        isLoading={isFetching}
      />

      <HR />

      <InterestingCaseCreator />
    </div>
  )
}

export default Filters
