import tw from 'twin.macro'

export const H1 = tw.h1`font-bold text-xl dark:text-white`

export const H2 = tw.h2`font-bold text-lg dark:text-white`

export const H3 = tw.h3`font-bold dark:text-white`

export const H4 = tw.h4`font-black text-xs text-gray-600 dark:text-gray-300 uppercase`

export const P = tw.p`font-black dark:text-white`

export const PSmall = tw.p`font-black text-xs dark:text-white`
