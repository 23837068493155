import React from 'react'
import tw from 'twin.macro'

const DictContainer = tw.div`table flex items-stretch`
const DictItems = tw.div`table-row-group`
const DictItem = tw.div`table-row`
const DictKey = tw.div`table-cell text-right font-bold`
const DictValue = tw.div`table-cell`

const Dictionary = ({ object }) => (
  <DictContainer>
    <DictItems>
      {Object.keys(object).map((key) => (
        <DictItem key={key}>
          <DictKey>
            <p>{key}:&nbsp;</p>
          </DictKey>
          <DictValue>
            <p>{object[key]}&nbsp;</p>
          </DictValue>
        </DictItem>
      ))}
    </DictItems>
  </DictContainer>
)

export default Dictionary
