import React, { useState, useEffect } from 'react'
import 'twin.macro'
import { useLocalstorageState } from 'rooks'
import { useDispatch, useSelector } from 'react-redux'

import {
  Switch,
  H2,
  H3,
  H4,
  HR,
  Card,
  Panel,
  Loading,
  IconButton,
  Button,
  Dictionary,
  Select,
} from '@fxr/ui'

import { useGetCasesQuery } from './features/cases/caseApiSlice'
import {
  contextSet,
  researchRequestCleared,
  caseIdSet,
  recipientSet,
  resourceRemoved,
  displaySet,
  isCapturingSet,
} from './features/researchRequest/generatorSlice'
import { useCreateResearchRequestMutation } from './features/data/dataApiSlice'
import useConfig from './useConfig'
import ContextBadges from './ContextBadges'

const ImageRenderer = ({ resource }) => (
  <div tw="shadow-lg">
    <img src={resource.data.data_url} alt="research request" />
  </div>
)

const TableRenderer = ({ resource }) => (
  <>
    <p>filters: {Object.keys(resource.data.filters).join(', ') || 'none'}</p>
    <p>groupby: {resource.data.groupby_columns.join(', ') || 'none'}</p>
    <p>
      compensating rows:{' '}
      {resource.data.are_compensating_rows ? 'true' : 'false'}
    </p>
  </>
)

const ConsistentContextRenderer = ({ resource }) => (
  <>
    <p>column changed: {resource.data.consistent_context.column_changed}</p>
    <p>context: </p>
    <ContextBadges context={resource.data.context} />
  </>
)

const resourceType2renderer = {
  image: ImageRenderer,
  table: TableRenderer,
  consistent_context: ConsistentContextRenderer,
}

const RRSetter = () => {
  const [caseId, setCaseId] = useState(null)

  const { data: cases, isFetching } = useGetCasesQuery()

  const filters = useSelector((state) => state.temporal.present.filters)
  const dispatch = useDispatch()

  const setResearchRequest = () => {
    if (caseId === null) {
      dispatch(contextSet(filters))
    } else {
      const ccase = cases.find((c) => c.id === caseId)
      const context =
        'context_simplified' in ccase
          ? ccase.context_simplified
          : ccase.context_full
      dispatch(caseIdSet(caseId))
      dispatch(contextSet(context))
    }
  }

  return (
    <>
      <Select
        tw="mb-2"
        placeholder="select case..."
        value={caseId}
        options={cases ? ['None', ...cases.map((c) => c.id)] : []}
        isLoading={isFetching}
        onChange={(caseId) => setCaseId(caseId === 'None' ? null : caseId)}
      />

      <Button.Primary tw="w-full" onClick={setResearchRequest}>
        start RR{' '}
        {caseId === null ? 'from current filters (no case)' : 'from case'}
      </Button.Primary>
      <p tw="text-gray-500 italic">
        {caseId === null
          ? 'this will set the context of the RR equal to the current filters.'
          : 'this will set the context of the RR equal to the context of the selected case.'}
      </p>
    </>
  )
}

const RRGenerator = () => {
  const state = useSelector((state) => state.researchRequestGenerator)
  const [useEstimations, setUseEstimations] = useLocalstorageState(
    'fxr:rr-generator:use-estimations',
    true
  )
  const dispatch = useDispatch()
  const config = useConfig()

  const [
    createResearchRequest,
    { data, isLoading },
  ] = useCreateResearchRequestMutation()

  const resourceType2count = {}
  Object.values(state.resources).forEach((resource) => {
    if (!(resource.resource_type in resourceType2count))
      resourceType2count[resource.resource_type] = 0
    resourceType2count[resource.resource_type] += 1
  })

  const hasCreatedRR = Boolean(data)
  const isContextSet = Boolean(state.context)

  const clearResearchRequest = () => dispatch(researchRequestCleared())

  const filters = useSelector((state) => state.temporal.present.filters)

  return (
    <div tw="fixed height[calc(100vh - 62px)] overflow-y-scroll right-0 bottom-0 shadow-xl w-72 bg-white dark:bg-gray-700 py-4 px-4 border-l dark:border-gray-900 zIndex[10000]">
      <H2>research request generator</H2>

      {!isContextSet ? (
        <RRSetter />
      ) : (
        <>
          {!isLoading && (
            <div tw="flex flex-col items-stretch mt-2">
              <Select
                placeholder="recipient"
                options={config.orgs || []}
                value={state.recipient}
                onChange={(value) => dispatch(recipientSet(value))}
              />

              <div tw="flex items-center space-x-2 mt-2">
                <Switch
                  name="use-estimations"
                  value={useEstimations}
                  onChange={setUseEstimations}
                />

                <p tw="dark:text-white">use estimated amounts</p>
              </div>
            </div>
          )}

          <HR tw="my-2" />

          {!hasCreatedRR && (
            <Button.Primary
              tw="w-full"
              disabled={isLoading}
              onClick={() => createResearchRequest({ state, useEstimations })}
            >
              generate
            </Button.Primary>
          )}

          <div tw="mb-4" />

          {hasCreatedRR ? (
            <>
              <Card>
                <Card.Body>
                  <p>the research request has been generated!</p>

                  <a
                    href={`https://www.dropbox.com/home${data.dropbox_fpath}`}
                    target="_blank"
                  >
                    <Button.Primary>view on dropbox</Button.Primary>
                  </a>

                  <p>
                    the <code>.tex</code> file is located at:
                  </p>
                  <Panel>
                    <Panel.Section>
                      <code tw="text-xs">{data.tex_fpath}</code>
                    </Panel.Section>
                  </Panel>

                  <div tw="mb-2" />

                  <p>
                    the <code>.pdf</code> file is located at:
                  </p>
                  <Panel>
                    <Panel.Section>
                      <code tw="text-xs">{data.pdf_fpath}</code>
                    </Panel.Section>
                  </Panel>
                </Card.Body>
              </Card>
              <Button.Neutral tw="mt-4" onClick={clearResearchRequest}>
                reset RR generator
              </Button.Neutral>
            </>
          ) : isLoading ? (
            <Loading.Card />
          ) : (
            <>
              <Card tw="mt-4">
                <Card.Section>
                  <H4>RR context</H4>
                  <ContextBadges context={state.context} />

                  {state.caseId && <H4>RR case: {state.caseId}</H4>}

                  {Object.keys(state.context).length === 0 && <em>none</em>}
                  <Button.Neutral
                    tw="mt-2 w-full"
                    onClick={clearResearchRequest}
                  >
                    clear
                  </Button.Neutral>
                </Card.Section>

                <Card.Section>
                  <H4>selected resources</H4>
                  <Dictionary object={resourceType2count} />
                  {Object.keys(resourceType2count).length === 0 && (
                    <em>none</em>
                  )}
                </Card.Section>
              </Card>

              <H3 tw="mt-2 mb-4">resources</H3>
              {Object.keys(state.resources).map((label) => {
                const resource = state.resources[label]
                const Renderer = resourceType2renderer[resource.resource_type]

                return (
                  <div key={label} tw="mb-3">
                    <Card tw="relative">
                      <IconButton
                        tw="absolute -right-2 -top-2"
                        onClick={() => dispatch(resourceRemoved(label))}
                      >
                        &times;
                      </IconButton>

                      <Card.Header>
                        <p>
                          <strong>{label}</strong>
                        </p>
                      </Card.Header>

                      <Card.Section>
                        <Renderer resource={resource} />
                      </Card.Section>
                    </Card>
                  </div>
                )
              })}
            </>
          )}
        </>
      )}
    </div>
  )
}

const RRGeneratorWrapper = () => {
  const state = useSelector((state) => state.researchRequestGenerator)

  return state.display ? <RRGenerator /> : null
}

export const ToggleRRGenerator = () => {
  const state = useSelector((state) => state.researchRequestGenerator)

  const dispatch = useDispatch()

  return (
    <Button.Dark onClick={() => dispatch(displaySet(!state.display))}>
      research request
    </Button.Dark>
  )
}

export default RRGeneratorWrapper
