import React from 'react'
import tw, { styled } from 'twin.macro'
import { Box } from 'rebass/styled-components'

import { sumArray } from '@fxr/common/utils'

const Container = styled(Box)(() => ({
  ...tw`rounded-full overflow-hidden border-2 border-gray-200 dark:border-gray-800 flex`,
  '> div:nth-of-type(1)': tw`rounded-l-full`,
  '> div:last-of-type': tw`rounded-r-full`,
}))

const DistributionBar = ({ widths, colors, onClick = null }) => {
  const total = sumArray(widths)
  return (
    <Container>
      {widths.map((width, i) => (
        <Box
          tw="height[0.65rem] inline-block"
          css={{ flex: `0 0 ${(width / total) * 100}%`, background: colors[i] }}
          onClick={onClick ? (e) => onClick(key, e) : null}
          key={i}
        />
      ))}
    </Container>
  )
}

export default DistributionBar
