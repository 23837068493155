import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { usePassFromState, dynamicBaseQuery } from '../data/dataApiSlice'

import { API_BASE_URL } from '../../constants'

export const caseApiSlice = createApi({
  reducerPath: 'casesApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: ['Cases'],
  endpoints: (builder) => ({
    getCases: builder.query({
      query: ({ caseFilters }) => ({
        url: '/interesting_cases',
        method: 'GET',
        params: {
          filters: JSON.stringify(caseFilters),
        },
      }),
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'Cases', id })),
              { type: 'Cases', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Cases', id: 'LIST' }` is invalidated
            [{ type: 'Cases', id: 'LIST' }],
    }),
    createCase: builder.mutation({
      query: ({ ccase }) => {
        return {
          url: '/interesting_cases',
          method: 'POST',
          body: {
            case: ccase,
          },
        }
      },
      transformResponse: (response, meta) => {
        return response.data
      },
      invalidatesTags: (result, error, { id }) => {
        return [{ type: 'Cases', id: 'LIST' }]
      },
    }),
    updateCase: builder.mutation({
      query: ({ id, ccase }) => {
        return {
          url: '/interesting_cases',
          method: 'PUT',
          body: {
            id,
            case: ccase,
          },
        }
      },
      invalidatesTags: (result, error, { id }) => {
        return [{ type: 'Cases', id }]
      },
    }),
  }),
})

export const {
  useGetCasesQuery: _useGetCasesQuery,
  useCreateCaseMutation,
  useUpdateCaseMutation,
} = caseApiSlice

export const useGetCasesQuery = () =>
  usePassFromState(['caseFilters'], _useGetCasesQuery)
