import ParentSize from '@visx/responsive/lib/components/ParentSize'

const withAutoSize = (Component) => (props) => (
  <ParentSize>
    {({ width, height }) => {
      return <Component width={width} height={height} {...props} />
    }}
  </ParentSize>
)

export default withAutoSize
