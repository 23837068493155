import React from 'react'
import tw, { styled } from 'twin.macro'
import RSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { isObject } from '@fxr/common/utils'

import Label from './Label'

/* import { getBase } from './Base'
 *
 * const BaseSelect = tw(getBase(RSelect))`pr-5 cursor-pointer` */

const styleFn = ({ theme }) => ({
  '.react-select__control': tw`dark:bg-gray-700 dark:border-gray-500`,
  '.react-select__menu': tw`dark:bg-gray-700`,
  '.react-select__option': tw`dark:text-gray-100! dark:bg-gray-700 dark:hocus:bg-gray-800! dark:active:bg-gray-800!`,
  '.react-select__single-value': tw`dark:text-white`,
  '.react-select__multi-value': tw`dark:bg-gray-800 dark:text-white`,
  '.react-select__multi-value__label': tw`dark:text-white`,
})
export const StyledSelectComponent = styled(RSelect)(styleFn)
export const StyledCreatableSelectComponent = styled(CreatableSelect)(styleFn)

const Select = ({
  options: _options,
  value: _value,
  onChange: _onChange,
  label,
  creatable = false,
  Component = null,
  deselect = null,
  ...props
}) => {
  const arrayMode = Array.isArray(_options) && !isObject(_options[0])

  if (deselect && arrayMode) {
    _options = [..._options, deselect]
    let __onChange = _onChange

    _onChange = (value) => {
      __onChange(value === deselect ? null : value)
    }
  }

  let options = _options
  let value = _value
  let onChange = _onChange

  if (arrayMode) {
    options = _options.map((val) => ({ value: val, label: val }))

    // be consistent with the API, so wrap value & onChange
    const formatOption = (option) => ({ value: option, label: option })
    const parseOption = ({ value }) => value

    // need to account for fact that it could either be array or just single
    // option
    if (value) {
      value = Array.isArray(value)
        ? value.map(formatOption)
        : formatOption(value)
    }

    onChange = (option, event) =>
      _onChange(
        Array.isArray(option) ? option.map(parseOption) : parseOption(option),
        event
      )
  }

  const SelectComponent =
    Component ||
    (creatable ? StyledCreatableSelectComponent : StyledSelectComponent)

  return (
    <>
      {label && <Label htmlFor={props.name}>{label}</Label>}
      <SelectComponent
        classNamePrefix="react-select"
        options={options}
        value={value}
        onChange={onChange}
        {...props}
      />
    </>
  )
}

export default Select
