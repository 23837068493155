import React from 'react'
import tw from 'twin.macro'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Box, Text } from 'rebass/styled-components'

const P = tw.p`dark:text-red-800`

const Error = ({ children = 'something went wrong.' }) => (
  <Box tw="p-2 text-red-800 bg-red-100 flex items-center">
    <Box tw="mr-2">
      <AiOutlineExclamationCircle width="24px" height="24px" />
    </Box>
    <P>{children}</P>
  </Box>
)

export default Error
