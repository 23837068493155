import qs from 'qs'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'

import { objectFilterByKeys } from '@fxr/common/utils'

const temporalStateKeys = ['filters', 'groupby']
const stateKeys = ['composers']

export const useSharableURL = () => {
  const state = useSelector((state) => state)
  const path = window.location.href.split('?')[0]

  const extractedState = {
    ...objectFilterByKeys(state.temporal.present, temporalStateKeys),
    ...objectFilterByKeys(state, stateKeys),
  }

  const url = `${path}?${qs.stringify({
    state: extractedState,
  })}`
  return url
}

const useShareToURL = () => {
  const url = useSharableURL()

  return () => {
    navigator.clipboard.writeText(url).then(
      function () {
        toast.success('url for current view copied to clipboard!')
      },
      function (err) {
        console.error('Async: Could not copy text: ', err)
      }
    )
  }
}

export default useShareToURL
