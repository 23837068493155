import React, { useState } from 'react'
import 'twin.macro'

import { H3, Panel, Loading, Card, H2, ButtonNeutral } from '@fxr/ui'
import { round } from '@fxr/common/utils'

import { useFetchRankedContextsQuery } from './features/data/dataApiSlice'
import ContextBadges from './ContextBadges'
import useExploreContext from './useExploreContext'

const RankedContext = ({ displayDetails, rankedContext, index = null }) => {
  const { text, context, value, amount } = rankedContext

  const exploreContext = useExploreContext()

  return (
    <Card>
      <Card.Section tw="relative">
        {index !== null && <Card.Dot>{index + 1}</Card.Dot>}

        <ContextBadges context={context} />

        <ButtonNeutral tw="mt-2" onClick={() => exploreContext(context)}>
          explore this context
        </ButtonNeutral>
      </Card.Section>
      <Card.Section>
        <p>
          <strong>cmo:</strong> {round(value[0], 2)}
        </p>
        <p>
          <strong>publisher:</strong> {round(value[1], 2)}
        </p>
      </Card.Section>
      <Card.Section>
        <p>
          <strong>amount:</strong> {round(amount, 2)}
        </p>
        <p tw="text-xs text-gray-500 my-2">{text}</p>
      </Card.Section>
      {displayDetails && (
        <Card.Section>
          <Panel>
            <Panel.Header>
              <H3>ranked context object</H3>
            </Panel.Header>
            <Panel.Body>
              <code>
                {'{'}
                <br />
                {Object.keys(rankedContext).map((key) => (
                  <span key={key}>
                    &nbsp;&nbsp;
                    {key}: {rankedContext[key].toString()},<br />
                  </span>
                ))}
                {'}'}
              </code>
            </Panel.Body>
          </Panel>
        </Card.Section>
      )}
    </Card>
  )
}

const RankedContexts = (props) => {
  const { data, isFetching } = useFetchRankedContextsQuery()

  const rankedContexts = data || []
  const [displayDetails, setDisplayDetails] = useState(false)

  return (
    <div tw="max-w-4xl mx-auto p-4">
      <Card tw="mb-6">
        <Card.Header>
          <H2>ranked contexts</H2>

          <ButtonNeutral
            tw="mt-2"
            onClick={() => setDisplayDetails(!displayDetails)}
          >
            {displayDetails ? 'hide' : 'show'} details
          </ButtonNeutral>
        </Card.Header>
      </Card>

      <div tw="space-y-4">
        {isFetching ? (
          <Loading.Card />
        ) : (
          <>
            {rankedContexts.map((rankedContext, i) => (
              <RankedContext
                rankedContext={rankedContext}
                index={i}
                displayDetails={displayDetails}
                key={i}
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default RankedContexts
