import React from 'react'
import tw, { styled } from 'twin.macro'

const Button = tw.button`
  cursor-pointer rounded border px-2 py-1 text-sm
  disabled:(cursor-not-allowed opacity-50)
`

export const ButtonNeutral = tw(Button)`
   bg-white text-black border-gray-300
   hocus:(bg-gray-100 border-gray-400)
   dark:(
     bg-gray-600 text-white border-gray-900
     hocus:(bg-gray-800 border-gray-900)
   )
`

export const ButtonPrimary = tw(Button)`
   bg-brand-dark text-white font-bold border-brand-darker
   hocus:(bg-brand-darker border-brand-darker)
`

export const ButtonDark = tw(Button)`
   bg-navy text-white border-white
   hocus:underline
`

export const IconButton = styled.button(({ disabled }) => [
  tw`
  cursor-pointer rounded-full border h-7 w-7
  bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-900

  flex items-center justify-center

  transition-colors
  hocus:(bg-gray-100 dark:bg-gray-600)
  `,
  disabled && tw`text-gray-300 cursor-not-allowed`,
])

const IconButtonDark = tw(IconButton)`
   bg-navy text-white border-white
   hocus:(bg-navy-lighter)
`

IconButton.Dark = IconButtonDark

export const IconButtonConcealed = styled(IconButton)(({ disabled }) => [
  tw`
    bg-transparent border-none
  `,
  !disabled && tw`hocus:(bg-gray-300)`,
])

Button.Neutral = ButtonNeutral
Button.Primary = ButtonPrimary
Button.Dark = ButtonDark

export default Button
