import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'

import { Menu } from './Icons'
import { IconButton } from './Button'
import HR from './HR'

export const NavbarDropdownButton = () => (
  <IconButton.Dark>
    <Menu />
  </IconButton.Dark>
)

const Wrapper = tw.div`relative z-10`
const DropdownContainer = styled.div(({ isOpen }) => [
  tw`
  w-72
  absolute right-0 top-9 rounded-lg shadow-xl
  bg-white dark:bg-gray-700
  dark:text-white
  dark:(border border-gray-900)
  py-2
  `,
  isOpen ? '' : tw`hidden`,
  {
    '> div:first-of-type': tw`rounded-t-lg`,
    '> div:last-of-type': tw`rounded-b-lg`,
  },
])

const defaultRenderToggle = () => <NavbarDropdownButton />
const NavbarDropdown = ({ renderToggle = defaultRenderToggle, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleIsOpen = () => setIsOpen(!isOpen)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const toggler = renderToggle()

  return (
    <Wrapper>
      <div onClick={toggleIsOpen}>{toggler}</div>
      <DropdownContainer isOpen={isOpen}>
        {children({ isOpen, toggleIsOpen, open, close })}
      </DropdownContainer>
    </Wrapper>
  )
}

export const NavbarDropdownItem = tw.div`
  py-1.5 px-3
`

export const NavbarDropdownHR = tw(HR)`
  mx-3
`

const NavbarDropdownLinkComponent = tw(NavbarDropdownItem)`
  font-bold
  cursor-pointer
  hocus:bg-gray-100
  dark:(hocus:bg-gray-800)
`

export const NavbarDropdownLink = ({
  toggleIsOpen = null,
  onClick,
  ...props
}) => (
  <NavbarDropdownLinkComponent
    onClick={(ev) => {
      onClick && onClick(ev)
      toggleIsOpen && toggleIsOpen()
    }}
    {...props}
  />
)

export default NavbarDropdown
