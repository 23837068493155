import React, { useMemo } from 'react'
import { useTable, useBlockLayout } from 'react-table'

import RTable from '@fxr/ui/rtable/Table'
import useAutoColumns from '@fxr/ui/rtable/useAutoColumns'
import columns from './columns'

const CasePaymentNotInDataTable = ({ ccase }) => {
  const rows = ccase.prob_payment_not_in_data_rows || []

  const defaultColumn = useMemo(
    () => ({
      minWidth: 10,
      width: 170,
      maxWidth: 250,
    }),
    []
  )

  const memoizedColumns = useAutoColumns([columns.year], rows)

  const tableInstance = useTable(
    {
      columns: memoizedColumns,
      data: rows,
      defaultColumn,
    },
    useBlockLayout
  )

  return (
    <RTable.NavbarOffset>
      <RTable
        tableInstance={tableInstance}
        renderTFoot={true}
        renderFooter={true}
      />
    </RTable.NavbarOffset>
  )
}

export default CasePaymentNotInDataTable
