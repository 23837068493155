import React from 'react'

function syncScroll(target, others, TopLeft, WidthHeight) {
  const percentage =
    target[`scroll${TopLeft}`] /
    (target[`scroll${WidthHeight}`] - target[`offset${WidthHeight}`])

  window.requestAnimationFrame(() => {
    others.forEach((el) => {
      el[`scroll${TopLeft}`] = Math.round(
        percentage * (el[`scroll${WidthHeight}`] - el[`offset${WidthHeight}`])
      )
    })
  })
}

function syncVerticalScroll(target, others) {
  syncScroll(target, others, 'Top', 'Height')
}

function syncHorizontalScroll(target, others) {
  syncScroll(target, others, 'Left', 'Width')
}

function useSyncScroll(nRefs, { vertical, horizontal }) {
  const refs = []

  for (let i = 0; i < nRefs; i++) {
    refs.push(React.useRef(null))
  }

  const refsRef = React.useRef(refs)
  const locksRef = React.useRef(0)

  React.useEffect(() => {
    const elems = refsRef.current
      .map((ref) => ref.current)
      .filter((elem) => Boolean(elem))

    if (elems.length < 2) return

    function handleScroll({ target }) {
      if (locksRef.current > 0) {
        locksRef.current -= 1 // Release lock by 1
        return
      }

      locksRef.current = elems.length - 1 // Acquire lock

      const others = elems.filter((elem) => elem !== target)

      if (vertical) syncVerticalScroll(target, others)
      if (horizontal) syncHorizontalScroll(target, others)
    }

    elems.forEach((el) => el.addEventListener('scroll', handleScroll))

    return () => {
      elems.forEach((el) => el.removeEventListener('scroll', handleScroll))
    }
  }, [refsRef, vertical, horizontal, locksRef])

  return refs
}

export default useSyncScroll
