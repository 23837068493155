import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  token: null,
}

const auth0Slice = createSlice({
  name: 'auth0',
  initialState,
  reducers: {
    tokenSet: (state, action) => {
      state.token = action.payload
    },
  },
})

export const { tokenSet } = auth0Slice.actions

export default auth0Slice.reducer
