import React from 'react'
import tw from 'twin.macro'

import { Card, H4 } from '@fxr/ui'

export const CaseReview = ({ event }) => {
  const { data } = event

  return (
    <Card>
      <Card.Body>
        <H4>
          review:{' '}
          <span
            css={
              data.decision === 'accepted'
                ? tw`text-green-600 font-bold`
                : tw`text-red-600 font-bold`
            }
          >
            {data.decision}
          </span>{' '}
          by {data.author}
        </H4>

        <p>{data.comment}</p>
      </Card.Body>
    </Card>
  )
}

export const RRGenerated = ({ event }) => {
  const { data } = event

  return (
    <Card>
      <Card.Body>
        <p tw="font-bold">Research Request was generated.</p>

        <p>RR ID: {data.rr_id}</p>
      </Card.Body>
    </Card>
  )
}

const eventType2component = {
  REVIEW_ADDED: CaseReview,
  RR_GENERATED: RRGenerated,
}

const CaseEvent = ({ event }) => {
  if (!(event.type in eventType2component)) {
    console.error('event type not recognized.')

    return null
  }
  const Component = eventType2component[event.type]

  return <Component event={event} />
}

CaseEvent.CaseReview = CaseReview
CaseEvent.RRGenerated = RRGenerated

export default CaseEvent
