import uuid from 'uuid'
import countries from 'i18n-iso-countries'
import parseObjectLiteral from 'object-literal-parse'
export { round } from 'lodash'
import { toString as _toString, mergeWith, cloneDeep } from 'lodash'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

/* export const round = (number, decimalPlaces) =>
 *   Number(
 *     Math.round(Number(number + 'e' + decimalPlaces)) + 'e' + decimalPlaces * -1
 *   ) */

export const sumArray = (arr) => arr.reduce((sum, n) => n + sum, 0)

export const mean = (arr) => sumArray(arr) / arr.length

export const objectFilter = (object, fn) =>
  Object.keys(object)
    .filter((key) => fn(key, object[key], object))
    .reduce((res, key) => ((res[key] = object[key]), res), {})

export const objectFilterByKeys = (object, keys) =>
  objectFilter(object, (key) => keys.includes(key))

export const objectReject = (object, keys) =>
  Object.keys(object)
    .filter((key) => !keys.includes(key))
    .reduce((res, key) => ((res[key] = object[key]), res), {})

export const objectMap = (object, mapFn) =>
  Object.keys(object).reduce(function (result, key) {
    result[key] = mapFn(object[key], key)
    return result
  }, {})

export const objectFromArray = (array) =>
  array.reduce((result, item) => {
    result[item[0]] = item[1]
    return result
  }, {})

export const generateUUID = () => uuid.v4()

export const alpha2country = (alpha2) =>
  countries.getName(alpha2, 'en', { select: 'official' })

export const isObject = (obj) => typeof obj === 'object' && obj !== null

export const isString = (obj) =>
  typeof obj === 'string' || obj instanceof String

export const capitalize = (sent) =>
  sent
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ')

export const parseLiteralObject = (str) =>
  Object.fromEntries(parseObjectLiteral(str))

export const currencyCode2symbol = (code) => {
  const symbol = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: code,
  })
    .formatToParts(0)
    .find((x) => x.type === 'currency')
  return symbol ? symbol.value : null
}

export const sortBy = (ary, fn, descending = true) =>
  ary.sort((a, b) => {
    const aKey = fn(a)
    const bKey = fn(b)

    if (aKey > bKey) {
      return descending ? -1 : 1
    }
    if (aKey < bKey) {
      return descending ? 1 : -1
    }

    return 0
  })

export const joinWithFinalAnd = (ary) => {
  const len = ary.length

  if (len === 1) return ary
  if (len === 2) return [ary[0], ' and ', ary[1]]

  const output = []
  ary.forEach((el, i) => {
    output.push(el)
    if (i < len - 1) {
      output.push(i === len - 2 ? ', and ' : ', ')
    }
  })
  return output
}

export const nestedMap = (nestedObj, names, fn, scope = {}) => {
  if (names.length === 1) {
    return fn({ ...scope, [names[0]]: nestedObj })
  }
  const [name, ...remainingNames] = names

  return Object.keys(nestedObj).map((key) => {
    return nestedMap(
      nestedObj[key],
      remainingNames,
      fn,
      (scope = { ...scope, [name]: key })
    )
  })
}

export const toString = (val) => {
  if (isObject) return JSON.stringify(val)
  return _toString(val)
}

const mergeNestedMergeFn = (a, b) => {
  if (Array.isArray(a)) {
    return b
  }
}

export const mergeNested = (obj1, obj2) => {
  let out = cloneDeep(obj1)

  mergeWith(out, obj2, mergeNestedMergeFn)

  return out
}
