import React from 'react'
import tw from 'twin.macro'
import { Box } from 'rebass/styled-components'

import { generateUUID, round } from '@fxr/common/utils'
import { CONFIDENCE_LEVELS } from '@fxr/common/constants'

import WithTooltip from '../WithTooltip'
import ProgressBar from '../ProgressBar'
import DistributionBar from '../DistributionBar'
import { confidenceLevel2color } from '../colors'

export const numericalRenderer = (decimals = 2) => ({ value }) =>
  isNaN(value)
    ? '-'
    : value.toLocaleString('en-US', { maximumFractionDigits: 2 })

// isFinite catches both Infinity and NaN
export const percentualRenderer = (decimals = 2, { multiplyBy = 1 }) => ({
  value,
}) =>
  !Number.isFinite(value) ? '-' : `${(value * multiplyBy).toFixed(decimals)}%`

export const progressBarRenderer = () => ({ value, ...stuff }) => (
  <Box tw="flex items-center h-full">
    <WithTooltip renderTooltip={() => `${round(value * 100, 1)}%`} place="left">
      <ProgressBar percent={value * 100} />
    </WithTooltip>
  </Box>
)

export const binnedDistributionRenderer = ({
  key2color = confidenceLevel2color,
  orderedKeys = CONFIDENCE_LEVELS,
}) => ({ value, ...stuff }) =>
  value === undefined ? null : (
    <Box tw="items-center h-full flex items-center">
      <WithTooltip
        renderTooltip={() => (
          <>
            {orderedKeys.map((key) => {
              const amount = value[key]
              return (
                <React.Fragment key={key}>
                  <span tw="font-bold" style={{ color: key2color[key] }}>
                    <strong>{key}</strong>
                  </span>
                  : {round(amount, 2)}
                  <br />
                </React.Fragment>
              )
            })}
          </>
        )}
        place="top"
      >
        <DistributionBar
          widths={orderedKeys.map((key) => value[key])}
          colors={orderedKeys.map((key) => key2color[key])}
        />
      </WithTooltip>
    </Box>
  )
