import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const filtersSlice = createSlice({
  name: 'caseFilters',
  initialState,
  reducers: {
    filterUpdated: (state, action) => {
      state[action.payload.name] = action.payload.value
    },
    filtersUpdated: (state, action) => {
      Object.keys(action.payload).forEach((col) => {
        let vals = action.payload[col]
        if (!Array.isArray(vals)) vals = [vals]
        state[col] = vals
      })
    },
    filtersSet: (state, action) => {
      // completely refresh filters
      return action.payload
    },
  },
})

export const {
  filterUpdated,
  filtersUpdated,
  filtersSet,
} = filtersSlice.actions

export default filtersSlice.reducer
