import React from 'react'
import 'twin.macro'
import { HiOutlineChevronRight, HiOutlineChevronDown } from 'react-icons/hi'

import { objectFilterByKeys } from '@fxr/common/utils'

import TutorialHint from '../TutorialHint'

const contents = {
  'sort by column': (
    <p>
      Click on a column header to sort all the rows by that column. The arrow
      next to the header name displays whether that column is being sorted in
      ascending or descending order.{' '}
    </p>
  ),
  'filter rows': (
    <p>
      You can filter rows on a per-column basis. Filters for each column are
      located just below the header. When you enter something in a filter, the
      table will display only the rows matching that criterium.
    </p>
  ),
  totals: (
    <p>
      For some columns, the bottom of the table displays the total sum of all
      the values in that column (for all rows). Note: if there is a filter
      active, these totals are calculated using only the filtered rows.
    </p>
  ),
  pagination: (
    <p>
      In order to prevent displaying all rows straight away, there are multiple
      pages of rows, which can be browsed with the controls in the bottom-right
      corner. To change the number of rows per page, click on the "show x"
      dropdown, where "x" is the number of rows currently being displayed per
      page.
    </p>
  ),
  'horizontal scroll': (
    <p>
      Try scrolling to the right (and back to the left) when hovering, with your
      mouse, over the rows; when the table becomes too wide, some columns might
      be tucked away behind others. (if you can't scroll horizontally, that
      means all columns are in sight!)
    </p>
  ),
  'expand grouped columns': (
    <p>
      If you see a <HiOutlineChevronRight style={{ display: 'inline' }} /> icon,
      that means that that row is grouped; if you click it, the row will expand
      and the grouped rows will appear below.
    </p>
  ),
}

const TableTutorialHint = ({ tutorialHintContents = null }) => (
  <TutorialHint
    title="Navigating this table"
    items={
      tutorialHintContents
        ? objectFilterByKeys(contents, tutorialHintContents)
        : contents
    }
  />
)

export default TableTutorialHint
