import React from 'react'
import tw from 'twin.macro'

const CheckboxInput = tw.input`mr-1`

const Checkbox = React.forwardRef(
  (
    { label, isSelected = null, checked = null, value, onChange, ...props },
    ref
  ) => (
    <label>
      <CheckboxInput
        type="checkbox"
        name={label}
        ref={ref}
        checked={
          isSelected === null
            ? checked === null
              ? value
              : checked
            : isSelected
        }
        onChange={(event) => onChange(event, event.target.checked)}
        {...props}
      />
      {label}
    </label>
  )
)

export default Checkbox
