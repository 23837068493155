import { Close, Check } from './Icons'
import { IconButton } from './Button'
import tw, { theme } from 'twin.macro'

export const IconButtonCheck = (props) => (
  <IconButton
    tw="text-white bg-green-500! border-green-600! hover:bg-green-700!"
    {...props}
  >
    <Check />
  </IconButton>
)

export const IconButtonClose = (props) => (
  <IconButton
    style={{
      color: 'white',
      backgroundColor: theme`colors.red.500`,
      borderColor: theme`colors.red.600`,
      ':hover': {
        backgroundColor: theme`colors.red.700`,
      },
    }}
    {...props}
  >
    <Close />
  </IconButton>
)

export default {
  Check: IconButtonCheck,
  Close: IconButtonClose,
}
