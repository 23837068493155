import { useSelector } from 'react-redux'
/* import XLSX from 'xlsx' */
import JSON5 from 'json5'

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { objectFilter } from '@fxr/common/utils'

import { VALUES, API_BASE_URL, API_VERSION } from '../../constants'

const json5ResponseHandler = async (response) => {
  const text = await response.text()
  const jsonData = JSON5.parse(text)

  return jsonData
}

const rawBaseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState()

    const token = state.auth0.token

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }

    return headers
  },
})

export const dynamicBaseQuery = async (args, api, extraOptions) => {
  const state = api.getState()

  const composer = state.composers.current

  // gracefully handle scenarios where data to generate the URL is missing
  if (!composer) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No current composer found in state.',
      },
    }
  }

  let urlEnd = typeof args === 'string' ? args : args.url

  if (urlEnd[0] === '/') {
    urlEnd = urlEnd.slice(1)
  }

  // construct a dynamically generated portion of the url
  const adjustedUrl = `${composer}/${API_VERSION}/${urlEnd}`
  const adjustedArgs =
    typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl }
  // provide the amended url and other params to the raw base query
  return rawBaseQuery(adjustedArgs, api, extraOptions)
}

export const dataApiSlice = createApi({
  reducerPath: 'data',
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    fetchConfig: builder.query({
      query: ({ currentComposer }) => ({
        url: '/config',
        method: 'GET',
        params: {
          // this is a dummy param! /config doesn't take a composer param, but
          // adding it for now because useConfig() doesn't want to update
          // when changing composers for some stupid reason...
          currentComposer,
        },
      }),
      transformResponse: (response, meta) => {
        return response.config || {}
      },
    }),
    fetchTabular: builder.query({
      query: ({ filters, groupbyColumns, values, forOrgtype }) => ({
        url: '/tabular',
        method: 'POST',
        body: {
          filters,
          groupby: { columns: groupbyColumns },
          values,
          ...(forOrgtype ? { for_orgtype: forOrgtype } : {}),
        },
        responseHandler: json5ResponseHandler,
      }),
      transformResponse: (response, meta) => {
        return response.data
      },
    }),
    fetchCollectionShares: builder.query({
      query: ({ filters }) => ({
        url: '/collection_shares',
        method: 'POST',
        body: {
          filters,
        },
      }),
      transformResponse: (response, meta) => {
        return response.data
      },
    }),
    fetchConfidenceDistributions: builder.query({
      query: ({ filters, metric }) => ({
        url: '/confidence_distributions',
        method: 'POST',
        body: {
          filters,
          metric,
        },
      }),
      transformResponse: (response, meta) => {
        return response.data
      },
    }),
    fetchConsistentContexts: builder.query({
      query: ({ filters, values, loadNContexts }) => ({
        url: '/consistent_contexts',
        method: 'POST',
        body: {
          first_n: loadNContexts,
          filters,
          values,
        },
        responseHandler: json5ResponseHandler,
      }),
      transformResponse: (response, meta) => {
        return response.data
      },
    }),
    fetchRankedContexts: builder.query({
      query: () => ({
        url: '/ranked_contexts',
        method: 'GET',
      }),
    }),
    fetchInterestingCases: builder.query({
      query: () => ({
        url: '/interesting_cases',
        method: 'GET',
      }),
    }),
    fetchBigFish: builder.query({
      query: () => ({
        url: '/big_fish',
        method: 'GET',
      }),
    }),
    fetchCompositionMetadata: builder.query({
      query: ({ filters, values }) => ({
        url: '/composition_metadata',
        method: 'POST',
        body: {
          filters,
          values,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    /* fetchContextXLSX: builder.query({
     *   query: ({ filters }) => ({
     *     url: '/original_rows.xlsx',
     *     method: 'POST',
     *     responseHandler: async (response) => {
     *       const { value } = await response.body.getReader().read()
     *       return XLSX.read(value, { type: 'array' })
     *     },
     *     body: {
     *       filters,
     *     },
     *   }),
     * }), */
    fetchOriginalRowsJson: builder.query({
      query: ({ ignoreUpperLimit, filters }) => ({
        url: '/original_rows',
        method: 'POST',
        body: {
          filters,
          ignore_upperlimit: ignoreUpperLimit,
        },
        responseHandler: json5ResponseHandler,
      }),
    }),
    fetchSULCOverviews: builder.query({
      query: ({ sortBy, ascending, collectionType, org, amountColumn }) => ({
        url: '/sulc_overviews',
        method: 'POST',
        body: {
          sort_by: sortBy,
          ascending,
          collection_type: collectionType,
          org,
          amount_column: amountColumn,
        },
      }),
      transformResponse: (response, meta) => {
        return response.data
      },
    }),
    createResearchRequest: builder.mutation({
      query: ({ context, recipient, resources, caseId, useEstimations }) => {
        return {
          url: '/research_request',
          method: 'POST',
          body: {
            case_id: caseId,
            context,
            recipient_org: recipient,
            use_estimations: useEstimations,
            resources,
          },
        }
      },
      transformResponse: (response) => response.data,
    }),
    fetchSourceTargetFlows: builder.query({
      query: ({ org, filters, flowCols, valueCol }) => ({
        url: '/source_target_flows',
        method: 'POST',
        body: {
          filters,
          org,
          flow_cols: flowCols,
          value_col: valueCol,
        },
      }),
      transformResponse: (response, meta) => {
        return response.data
      },
    }),
    clearFromMemory: builder.mutation({
      query: () => ({
        url: '/clear_from_memory',
        method: 'POST',
      }),
    }),
  }),
})

const {
  useFetchConfigQuery: _useFetchConfigQuery,
  useFetchTabularQuery: _useFetchTabularQuery,
  useFetchCollectionSharesQuery: _useFetchCollectionSharesQuery,
  useFetchConfidenceDistributionsQuery: _useFetchConfidenceDistributionsQuery,
  useFetchContextXLSXQuery: _useFetchContextXLSXQuery,
  useFetchOriginalRowsJsonQuery: _useFetchOriginalRowsJsonQuery,
  useFetchConsistentContextsQuery: _useFetchConsistentContextsQuery,
  useCreateResearchRequestMutation: _useCreateResearchRequestMutation,
  useFetchCompositionMetadataQuery: _useFetchCompositionMetadataQuery,
  useFetchSourceTargetFlowsQuery: _useFetchSourceTargetFlowsQuery,
} = dataApiSlice

export const {
  useFetchRankedContextsQuery,
  useFetchInterestingCasesQuery,
  useFetchBigFishQuery,
  useFetchSULCOverviewsQuery,
  useClearFromMemoryMutation,
} = dataApiSlice

// weird naming because of react hooks constraints
const key2getter = {
  useGet_filters: () =>
    objectFilter(
      useSelector((state) => state.temporal.present.filters),
      (k, v) => v.length > 0
    ),
  useGet_values: () => VALUES,
  useGet_groupbyColumns: () =>
    useSelector((state) => state.temporal.present.groupby.columns),
  useGet_caseFilters: () =>
    objectFilter(
      useSelector((state) => state.caseFilters),
      (k, v) => v.length > 0
    ),
  useGet_currentComposer: () => useSelector((state) => state.composers.current),
}

// have to call use* because of hook rules
export const usePassFromState = (keys, func, extraArgs = {}) => {
  const args = {}

  keys.forEach((key) => {
    args[key] = key2getter[`useGet_${key}`]()
  })

  return func({ ...args, ...extraArgs })
}

export const useFetchConfigQuery = (extraArgs) =>
  usePassFromState(['currentComposer'], _useFetchConfigQuery, extraArgs)

export const useFetchTabularQuery = (extraArgs) =>
  usePassFromState(
    ['filters', 'groupbyColumns', 'values'],
    _useFetchTabularQuery,
    extraArgs
  )

export const useFetchContextXLSXQuery = () =>
  usePassFromState(['filters'], _useFetchContextXLSXQuery)

export const useFetchOriginalRowsJsonQuery = ({ params = {} }) =>
  usePassFromState(['filters'], _useFetchOriginalRowsJsonQuery, params)

export const useFetchCollectionSharesQuery = () =>
  usePassFromState(['filters'], _useFetchCollectionSharesQuery)

export const useFetchCompositionMetadataQuery = () =>
  usePassFromState(['filters', 'values'], _useFetchCompositionMetadataQuery)

export const useFetchConfidenceDistributionsQuery = ({ metric }) =>
  usePassFromState(['filters'], _useFetchConfidenceDistributionsQuery, {
    metric,
  })

export const useFetchConsistentContextsQuery = ({ loadNContexts }) =>
  usePassFromState(['filters', 'values'], _useFetchConsistentContextsQuery, {
    loadNContexts,
  })

export const useCreateResearchRequestMutation = () => {
  const [_triggerMutation, result] = _useCreateResearchRequestMutation()

  const triggerMutation = ({ state, useEstimations }) => {
    const resources = Object.values(state.resources)
    const caseId = state.caseId
    let context = state.context

    if ('year' in context) {
      context = {
        ...context,
        year: context['year'].map((year) => year.toString()),
      }
    }

    return _triggerMutation({
      context,
      caseId,
      resources,
      useEstimations,
      recipient: state.recipient,
    })
  }

  return [triggerMutation, result]
}

export const useFetchSourceTargetFlowsQuery = ({ flowCols, valueCol, org }) =>
  usePassFromState(['filters'], _useFetchSourceTargetFlowsQuery, {
    flowCols,
    valueCol,
    org,
  })
