import { useMemo } from 'react'
import tw from 'twin.macro'
import * as dfd from 'danfojs/src/index'

import { sumArray, mean, round } from '@fxr/common/utils'
import { numericalRenderer } from './renderers'

const numericalRenderer2 = numericalRenderer(2)

const Span = tw.span`text-xs text-gray-600 dark:text-gray-400`

export const aggregationFooter = ({
  key,
  aggFunc,
  name = null,
  dropna = true,
}) => (info) => {
  let vals = info.rows.map((row) => row.values[key])
  if (dropna) {
    vals = vals.filter((val) => !isNaN(val))
  }

  const agg = useMemo(() => aggFunc(vals), [info.rows])

  return (
    <p>
      {name && <Span>{name}</Span>}
      <br />
      {numericalRenderer2({ value: agg })}
    </p>
  )
}

export const sumFooter = (key, name = 'total') =>
  aggregationFooter({
    key,
    name,
    aggFunc: sumArray,
  })

export const absSumFooter = (key, name = 'total') =>
  aggregationFooter({
    key,
    name,
    aggFunc: (vals) => sumArray(vals.map(Math.abs)),
  })

export const meanFooter = (key, name = 'mean') =>
  aggregationFooter({
    key,
    name,
    aggFunc: mean,
  })
