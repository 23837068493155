import React from 'react'
import tw, { styled } from 'twin.macro'
import { Box, Text } from 'rebass/styled-components'
import { AiOutlineExclamationCircle, AiOutlineInfoCircle } from 'react-icons/ai'
import Button from './Button'

const Card = styled.div({
  ...tw`
  bg-white dark:bg-gray-700
  text-black dark:text-white
  rounded-lg
  border
  border-gray-300 dark:border-gray-900
  `,
  '> div': tw`border-b border-gray-300 dark:border-gray-900`,
  '> div:first-of-type': tw`rounded-t-lg`,
  '> div:last-of-type': tw`border-b-0 rounded-b-lg`,
  [`> ${Button}`]: tw`w-full`,
})

export const CardHeader = tw.div`
  px-5 py-2
`

export const CardBody = tw.div`
  px-5 py-3
`

export const CardSection = tw.div`
  px-5 py-1
`

export const getCardNote = (Icon) => {
  const TWIcon = tw(Icon)`h-4 w-4 mr-3`

  return tw(({ children, ...props }) => (
    <div {...props}>
      <TWIcon />
      {children}
    </div>
  ))`
  pl-3 pr-5 py-2
  flex items-center
  text-xs
  `
}

export const CardInfo = tw(getCardNote(AiOutlineInfoCircle))`
  text-blue-800 bg-blue-100
  ring-1 ring-blue-400 border-b-0!
`

export const CardNotice = tw(getCardNote(AiOutlineInfoCircle))`
  text-yellow-800 bg-yellow-100
  ring-1 ring-yellow-400 border-b-0!
`

export const CardWarning = tw(getCardNote(AiOutlineExclamationCircle))`
  font-bold text-red-800 bg-red-100
  ring-1 ring-red-400 border-b-0!
`

export const CardDictionary = ({ dictionary }) => (
  <Box tw="flex flex-wrap gap-px bg-gray-300 dark:bg-gray-900">
    {Object.keys(dictionary).map((key) => (
      <Text
        as="p"
        tw="bg-white dark:bg-gray-700 flex-1 w-12 min-w-max pl-5 pr-8 py-1"
        key={key}
      >
        <Text as="span" tw="font-bold">
          {key}:
        </Text>{' '}
        {dictionary[key]}
      </Text>
    ))}
  </Box>
)

const Dot = tw(Box)`
  absolute p-1 w-6 h-6 rounded-full! dark:bg-gray-700
  border border-gray-300 dark:border-gray-900 flex items-center justify-center
`

export const CardDot = ({ children, top = true, left = true }) => (
  <Dot
    css={[top ? tw`-top-3` : `-bottom-3`, left ? tw`-left-3` : tw`-right-3`]}
  >
    <Text as="p" tw="inline-block text-xs">
      {children}
    </Text>
  </Dot>
)

Card.Body = CardBody
Card.Header = CardHeader
Card.Section = CardSection
Card.Notice = CardNotice
Card.Warning = CardWarning
Card.Info = CardInfo
Card.Dictionary = CardDictionary
Card.Dot = CardDot

Card.Button = {}
Card.Button.Primary = tw(
  Button.Primary
)`w-full rounded-t-none border-none rounded-b-lg`

export default Card
