import { forwardRef } from 'react'
import { Flex, Box } from 'rebass/styled-components'
import tw from 'twin.macro'

const Container = tw.div`p-3 bg-white rounded dark:bg-gray-900`

const Zone = tw.div`
cursor-pointer
border-4 border-gray-200 dark:border-gray-600 border-dashed
flex justify-center items-center
rounded-xl py-8 px-3`

const Dropzone = forwardRef((props, ref) => {
  /*   const [isDraggingOver, setIsDraggingOver] = useState(false) */
  /*
   *   const onDragEnter = (event) => {
   *     console.log('entering')
   *     setIsDraggingOver(true)
   *     props.onDragEnter && props.onDragEnter(event)
   *   }
   *
   *   const onDragLeave = (event) => {
   *     setIsDraggingOver(false)
   *     props.onDragLeave && props.onDragLeave(event)
   *   } */

  const className = `${props.className} ${
    props.isDraggingOver ? 'is-dragging-over' : ''
  }`

  return (
    <Container>
      <Zone
        {...props}
        ref={ref}
        css={[props.isDraggingOver && tw`border-brand`]}
      >
        {props.children}
      </Zone>
    </Container>
  )
})

export default Dropzone
