import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import 'twin.macro'

import { Button, Card, H2, Loading } from '@fxr/ui'

import { tokenSet } from '@fxr/common/slices/auth0Slice'

const AuthenticatedWithAuth0 = ({ children }) => {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0()
  const dispatch = useDispatch()
  const auth0Token = useSelector((state) => state.auth0.token)

  useEffect(() => {
    if (isAuthenticated) {
      if (!auth0Token) {
        getAccessTokenSilently().then((accessToken) => {
          dispatch(tokenSet(accessToken))
        })
      }
    }
  }, [isAuthenticated, auth0Token, dispatch, getAccessTokenSilently])

  if (!isAuthenticated || !auth0Token) {
    return (
      <div tw="h-screen w-screen flex justify-center items-center">
        <Card tw="w-72">
          <Card.Body>
            {isLoading ? (
              <Loading text="logging in..." />
            ) : isAuthenticated && !auth0Token ? (
              <Loading text="setting token..." />
            ) : (
              <>
                <H2>combe</H2>
                <Button.Primary onClick={loginWithRedirect} tw="w-full">
                  log in
                </Button.Primary>
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    )
  }

  return <>{children}</>
}

export default AuthenticatedWithAuth0
