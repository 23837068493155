import { memoize } from './data-utils'
import { objectReject, sumArray, sortBy, nestedMap } from './utils'

export const parseCollectionDiscrepancies = memoize((columns2val2org2tca) => {
  /*
       output:
       {
       uncollector: 'org1',
       collector2tca: { org2: 1111 },
       scope: {
       scope1: 'something',
       scope2: 'something else',
       }
       }
    */

  const discrepancies = []

  const splitKey = (key) => key.split('~')

  nestedMap(
    columns2val2org2tca,
    ['columns', 'val', 'org2tca'],
    ({ columns, val, org2tca }) => {
      const scope = {}
      const values = splitKey(val)
      splitKey(columns).forEach((col, i) => {
        scope[col] = values[i]
      })

      const uncollectors = Object.keys(org2tca).filter(
        (org) => org2tca[org] < 0.0001
      )
      const collector2tca = objectReject(org2tca, uncollectors)

      discrepancies.push({
        uncollectors,
        collector2tca,
        totalCollectedAmount: sumArray(Object.values(org2tca)),
        scope,
      })
    }
  )

  return sortBy(
    discrepancies,
    (discrepancy) => discrepancy.totalCollectedAmount
  )
})
