import React from 'react'

import { Child2canvas } from '@fxr/ui'

import useRRResourceable from './useRRResourceable'

const RRImageable = ({ children, label, width, height, caption }) => {
  const { onCapture, isCapturing } = useRRResourceable({
    resourceType: 'image',
  })

  const onCanvas = ({ dataURL }) =>
    onCapture(label, { data_url: dataURL, caption })

  return (
    <>
      {children}
      {isCapturing && (
        <Child2canvas width={width} height={height} onCanvas={onCanvas}>
          {children}
        </Child2canvas>
      )}
    </>
  )
}

export default RRImageable
