import { useEffect } from 'react'
import { isEqual } from 'lodash'

import useObjectState from './useObjectState'
import usePrevious from './usePrevious'

const useEditableRecord = (record, { name = 'record' }) => {
  const prevRecord = usePrevious(record)

  const {
    state: editedRecord,
    setState: setEditedRecord,
    updateState: updateEditedRecord,
  } = useObjectState(record)

  useEffect(() => {
    // reset editable record if actual record changed.
    if (!isEqual(record, prevRecord)) setEditedRecord(record)
  }, [record])

  // capitalize first letter
  const nameCapitalized = name.trim().replace(/^\w/, (c) => c.toUpperCase())
  return {
    [`edited${nameCapitalized}`]: editedRecord,
    [`setEdited${nameCapitalized}`]: setEditedRecord,
    [`updateEdited${nameCapitalized}`]: updateEditedRecord,
    isEdited: !isEqual(record, editedRecord),
  }
}

export default useEditableRecord
