import React from 'react'
import tw from 'twin.macro'

export const getBase = (Component) => tw(Component)`
  cursor-text
  appearance-none
  px-2 py-1
  rounded
  border-2 border-gray-200 dark:border-gray-900
  text-sm color[inherit]
  placeholder-gray-400
  bg-white dark:bg-gray-700

  transition-colors
  hover:(border-brand)
  focus:(
    outline-none
    border-brand-dark
  )
`

const Base = getBase('div')

export default Base
