import { createSlice } from '@reduxjs/toolkit'

import { loadState } from '../../app/actions'

const initialState = {
  current: null,
}

const composersSlice = createSlice({
  name: 'composers',
  initialState,
  reducers: {
    currentComposerSet: (state, action) => {
      state.current = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadState, (state, action) => {
      // completely refresh state
      return action.payload.composer
    })
  },
})

export const { currentComposerSet } = composersSlice.actions

export default composersSlice.reducer
