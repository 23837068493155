import React, { useRef } from 'react'
import tw, { styled } from 'twin.macro'
import { Flex, Box, Text } from 'rebass/styled-components'
import {
  HiOutlineChevronRight,
  HiOutlineChevronDown,
  HiOutlineArrowDown,
  HiOutlineArrowUp,
} from 'react-icons/hi'

import useSyncScroll from '@fxr/common/hooks/useSyncScroll'
import { toString } from '@fxr/common/utils'

import Loading from '../Loading'
import { withErrorBoundary } from '../ErrorBoundary'
import { IconButtonConcealed } from '../Button'
import attrs from '../attrs'
import Card from '../Card'
import Paginator from './Paginator'
import TutorialHint from './TutorialHint'

export const TableBase = styled.div({
  ...tw`w-full`,

  '.table': {
    ...tw`text-sm`,
    borderCollapse: 'initial',
  },

  '.thead, .tbody, .tfoot': tw`max-w-full`,

  '.thead': tw`top-0`,
  '.thead, .tfoot': {
    ...tw`sticky z-20 width[fit-content] overflow-x-scroll`,
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  '.thead .th-header, .tfoot .td': tw`px-2 py-1`,
  '.thead .th': tw`hocus:underline bg-gray-100 dark:bg-gray-700 border-b dark:border-gray-900`,
  '.thead .th-header': tw`bg-navy text-white font-bold whitespace-nowrap overflow-x-hidden`,
  '.thead .th-header.group-header': tw`border-l border-r border-white text-center`,
  '.thead .th:first-of-type .th-header.group-header': tw`border-l-0`,
  '.thead .th:last-of-type .th-header.group-header': tw`border-r-0`,
  '.th-filter': tw`bg-gray-100 dark:bg-gray-700 px-2 py-1`,
  '.thead .th:first-of-type .th-filter': tw`border-l dark:border-gray-900`,
  '.thead .th:last-of-type .th-filter': tw`border-r dark:border-gray-900`,
  '.th-filter input': tw`w-full`,

  '.tbody': tw`border-l border-r border-gray-300 dark:border-gray-900 pb-3
  relative z-0 width[fit-content] overflow-x-scroll`,
  '.tbody .tr.tr-clickable': tw`cursor-pointer`,
  '&, &.table-striped': {
    '.tbody .tr.tr-clickable:hover .td': tw`bg-gray-200 dark:bg-gray-900`,
  },
  '&.table-striped .tbody .tr:nth-of-type(2n) .td': tw`bg-gray-100 dark:background-color[rgb(44,54,70)]`,
  '.tbody .tr .td': tw`bg-white dark:bg-gray-700 border-b dark:border-gray-800`,
  '.tbody .tr .td.td-expansion-indent': tw`bg-gray-200 border-r border-b-0 border-gray-300 dark:border-gray-800`,
  '.tbody .td': tw`px-3 py-1 whitespace-nowrap overflow-ellipsis`,

  '.tfoot': {
    ...tw`border-l border-r border-gray-300 dark:border-gray-900 sticky z-10`,
  },
  '.tfoot .td': tw`bg-white dark:bg-gray-700`,

  '[data-sticky-td]': tw`sticky border-l border-r`,
  '.sorted-indicator svg': tw`inline-block ml-1`,

  '.footer': tw`sticky bottom-0
  border-b border-l border-r border-gray-300 dark:border-gray-900 bg-gray-100 dark:bg-gray-800
  p-2
  flex items-center max-w-full`,
})

export const Table = attrs.className('div', 'table')

export const TR = attrs.className('div', 'tr')
export const TD = attrs.className('div', 'td')

export const THead = attrs.className('div', 'thead')
export const TH = attrs.className('div', 'th')
export const THHeader = attrs.className('div', 'th-header')
export const THFilter = ({ column }) => (
  <div className="th-filter" {...column.getHeaderProps()}>
    {column.canFilter ? column.render('Filter') : null}
  </div>
)

export const TBody = attrs.className('div', 'tbody')
export const TFoot = attrs.className('div', 'tfoot')

export const Footer = attrs.className('div', 'footer')

export const RowCounter = ({ rows }) => (
  <Card>
    <Text as="p" tw="m-2">
      # of rows: {rows.length}
    </Text>
  </Card>
)

export const SortedIndicator = ({ column }) => (
  <span className="sorted-indicator">
    {column.isSorted ? (
      column.isSortedDesc ? (
        <HiOutlineArrowDown />
      ) : (
        <HiOutlineArrowUp />
      )
    ) : (
      ''
    )}
  </span>
)

export const RowExpander = ({ row }) => (
  <IconButtonConcealed {...row.getToggleRowExpandedProps()}>
    {row.isExpanded ? <HiOutlineChevronDown /> : <HiOutlineChevronRight />}
  </IconButtonConcealed>
)

const TableComposer = ({
  tableInstance,
  isLoading = false,
  onRowClick = null,
  sortable = false,
  filterable = false,
  paginated = false,
  renderTFoot = false,
  renderFooter = false,
  striped = true,
  tutorialHintContents = null,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows,

    page, // rows for current page
    state: { expanded, groupBy },
  } = tableInstance

  const visibleRows = paginated ? page : rows

  const footerRef = useRef(null)

  const [theadRef, tbodyRef, tfootRef] = useSyncScroll(3, {
    vertical: false,
    horizontal: true,
  })

  return (
    <TableBase className={striped ? 'table-striped' : ''}>
      <Table {...getTableProps()}>
        <THead ref={theadRef}>
          {headerGroups.map((headerGroup) => (
            <TR {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TH {...column.getHeaderProps()}>
                  <THHeader
                    className={
                      'headers' in column && !('placeholderOf' in column)
                        ? 'group-header'
                        : ''
                    }
                    {...(sortable ? column.getSortByToggleProps() : {})}
                  >
                    {column.render('Header')}

                    {sortable && <SortedIndicator column={column} />}
                  </THHeader>

                  {!('headers' in column) && filterable && (
                    <THFilter column={column} />
                  )}
                </TH>
              ))}
            </TR>
          ))}
        </THead>
        <TBody {...getTableBodyProps()} ref={tbodyRef}>
          {isLoading ? (
            <Loading />
          ) : (
            // Loop over the table rows
            visibleRows.map((row) => {
              // Prepare the row for display
              prepareRow(row)

              return (
                // Apply the row props
                <TR
                  {...row.getRowProps((props) => ({
                    ...props,
                    onClick: onRowClick
                      ? (event) => onRowClick(event, row)
                      : props.onClick,
                    className: `${props.className || ''} ${
                      onRowClick ? 'tr-clickable' : ''
                    } ${row.isExpanded ? 'tr-expanded' : ''}`,
                  }))}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      return (
                        <TD
                          {...cell.getCellProps((props) => ({
                            ...props,
                            className: `${props.className || ''} ${
                              cell.isPlaceholder &&
                              (!row.groupByID ||
                                cell.column.groupedIndex <
                                  groupBy.indexOf(row.groupByID))
                                ? 'td-expansion-indent'
                                : ''
                            }`,
                          }))}
                          title={toString(cell.value)}
                        >
                          {cell.isGrouped ? (
                            <Flex tw="items-center">
                              <RowExpander row={row} />
                              {cell.render('Cell')} ({row.subRows.length})
                            </Flex>
                          ) : cell.isAggregated ? (
                            <Text tw="text-gray-400">
                              {cell.render('Aggregated')}
                            </Text>
                          ) : cell.isPlaceholder ? null : (
                            cell.render('Cell')
                          )}
                        </TD>
                      )
                    })
                  }
                </TR>
              )
            })
          )}
        </TBody>

        {renderTFoot && (
          <TFoot
            ref={tfootRef}
            style={{
              top: theadRef.current ? theadRef.current.clientHeight : 0,
              bottom: footerRef.current ? footerRef.current.clientHeight : 0,
            }}
          >
            {[footerGroups[0]].map((group) => (
              <TR {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <TD {...column.getHeaderProps()}>
                    {column.render('Footer')}
                  </TD>
                ))}
              </TR>
            ))}
          </TFoot>
        )}
      </Table>

      {renderFooter && (
        <Footer
          ref={footerRef}
          style={{
            width: `${
              parseInt(footerGroups[0].getFooterGroupProps().style.width) + 2
            }px`,
            zIndex: 10,
          }}
        >
          <RowCounter rows={rows} />
          <Box tw="w-4" />
          <TutorialHint tutorialHintContents={tutorialHintContents} />
          <Box tw="flex-grow" />
          {paginated && <Paginator tableInstance={tableInstance} />}
        </Footer>
      )}
    </TableBase>
  )
}

const TableComposerWithBoundary = withErrorBoundary(TableComposer, {
  errorMessage: 'Something went wrong while trying to display this table.',
})

TableComposerWithBoundary.NavbarOffset = styled.div({
  '.thead': tw`top[60px] border-t-2 border-white dark:border-navy`,
})

export default TableComposerWithBoundary
