import React from 'react'
import 'twin.macro'

import { Card, H4, VR } from '@fxr/ui'
import { round } from '@fxr/common/utils'

const CaseAmounts = ({ ccase }) => {
  return (
    <>
      {'org2amount_type2amount' in ccase &&
        Object.keys(ccase.org2amount_type2amount).map((org) => {
          const amount_type2amount = ccase.org2amount_type2amount[org]
          return (
            <Card.Section key={org} tw="flex">
              <div tw="flex-1">
                <H4>{org}</H4>
                {Object.keys(amount_type2amount).map((amount_type) => {
                  const amount = amount_type2amount[amount_type]

                  return (
                    <p key={amount_type}>
                      {amount_type}: {round(amount, 2)}
                    </p>
                  )
                })}
              </div>

              {ccase.compensation_org2amount_type2amount &&
                org in ccase.compensation_org2amount_type2amount && (
                  <>
                    <VR tw="mx-2" />
                    <div tw="flex-1">
                      <H4>compensating amount</H4>
                      {Object.keys(
                        ccase.compensation_org2amount_type2amount[org]
                      ).map((amount_type) => {
                        const amount =
                          ccase.compensation_org2amount_type2amount[org][
                            amount_type
                          ]

                        return (
                          <p key={amount_type}>
                            {amount_type}: {round(amount, 2)}
                          </p>
                        )
                      })}
                    </div>
                  </>
                )}
            </Card.Section>
          )
        })}
    </>
  )
}

export default CaseAmounts
