import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import undoable from 'redux-undo'
import toast from 'react-hot-toast'
import {
  persistReducer,
  /*   persistStore, */
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'

import auth0Reducer from '@fxr/common/slices/auth0Slice'

import filtersReducer from '../features/filters/filtersSlice'
import groupbyReducer from '../features/groupby/groupbySlice'
import researchRequestGeneratorReducer from '../features/researchRequest/generatorSlice'
import { dataApiSlice } from '../features/data/dataApiSlice'
import { defaultApiSlice } from '../features/data/defaultApiSlice'
import { caseApiSlice } from '../features/cases/caseApiSlice'
import caseFiltersReducer from '../features/cases/filtersSlice'
import composersReducer from '../features/composers/composersSlice'

const reducer = combineReducers({
  temporal: undoable(
    combineReducers({
      filters: filtersReducer,
      groupby: groupbyReducer,
    }),
    { limit: 200 }
  ),
  auth0: auth0Reducer,
  caseFilters: caseFiltersReducer,
  composers: composersReducer,
  researchRequestGenerator: researchRequestGeneratorReducer,
  [dataApiSlice.reducerPath]: dataApiSlice.reducer,
  [caseApiSlice.reducerPath]: caseApiSlice.reducer,
  [defaultApiSlice.reducerPath]: defaultApiSlice.reducer,
})

const persistConfig = {
  key: 'researchRequestGenerator',
  whitelist: ['researchRequestGenerator'],
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action!', action)

    toast.error(
      action?.payload?.data?.error?.message ||
        'Something went wrong while trying to access the API.'
    )
  }

  return next(action)
}

let store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          'researchRequestGenerator/imageAdded',
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
        // Ignore these field paths in all actions
        /*         ignoredActionPaths: ['meta.arg', 'payload.timestamp'], */
        // Ignore these paths in the state
        ignoredPaths: ['researchRequestGenerator.images'],
      },
    }).concat([
      dataApiSlice.middleware,
      defaultApiSlice.middleware,
      caseApiSlice.middleware,
      rtkQueryErrorLogger,
    ])
  },
})

/* store = persistStore(store) */

export { store }
