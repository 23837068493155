import React, { useState } from 'react'
import { useLocalstorageState } from 'rooks'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { Loading } from '@fxr/ui'
import qs from '@fxr/common/qs'

import { loadState } from './app/actions'
import usePrepareFilters from './usePrepareFilters'

import { localstorageKey } from './SelectComposer'

const EnsureLoadStateFromURL = ({ children }) => {
  const [status, setStatus] = useState(null)
  const dispatch = useDispatch()

  const prepareFilters = usePrepareFilters()

  const [storedComposer, setStoredComposer] = useLocalstorageState(
    localstorageKey,
    null
  )

  const hashSplit = window.location.hash.split('?')
  if (hashSplit.length < 2) return children

  const params = hashSplit[1]

  if (!params.startsWith('state')) return children

  let state = qs.parse(params).state

  if (state?.composers?.current && state.composers.current !== storedComposer) {
    setStatus('setting-stored-composer')
    setStoredComposer(state.composers.current)

    // we need to reload, because otherwise it doesn't display the new composer
    window.location.reload()
    return null
  }
  if (status === 'setting-stored-composer') {
    // we don't want to let the rest of the code run because we're reloading
    return null
  }

  if (status !== 'has-dispatched') {
    if ('filters' in state) {
      state.filters = { ...state.filters, ...prepareFilters(state.filters) }
    }

    dispatch(loadState(state))
    setStatus('has-dispatched')
  }

  return (
    <>
      <Redirect to={hashSplit[0].split('#/')[1]} />
      <Loading.PaddedCard />
    </>
  )
}

export default EnsureLoadStateFromURL
