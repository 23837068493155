import { HiChevronDown } from 'react-icons/hi'
import styled from 'styled-components'
import { theme } from 'twin.macro'

const direction2degrees = {
  down: 0,
  left: 90,
  up: 180,
  right: -90,
}

const Chevron = styled(HiChevronDown).attrs((props) => ({
  ...props,
  size: props.size || '1.5em',
  color: theme`colors.gray.500`,
}))`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  transform: rotate(${(props) => direction2degrees[props.direction]}deg);
  transition: transform 230ms;
`

export default Chevron
