import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocalstorageState } from 'rooks'

import { Select, Label } from '@fxr/ui'

import { currentComposerSet } from './features/composers/composersSlice'
import { dataApiSlice } from './features/data/dataApiSlice'
import { defaultApiSlice } from './features/data/defaultApiSlice'
import { caseApiSlice } from './features/cases/caseApiSlice'
import { useFetchAvailableComposersQuery } from './features/data/defaultApiSlice'

export const localstorageKey = 'fxr:current-composer'

const SelectComposer = () => {
  const { data: composers, isFetching } = useFetchAvailableComposersQuery()
  const [storedComposer, setStoredComposer] = useLocalstorageState(
    localstorageKey,
    null
  )
  const composer = useSelector((state) => state.composers.current)
  const dispatch = useDispatch()

  const setCurrentComposer = (composer) =>
    dispatch(currentComposerSet(composer))

  // always set composer on load
  useEffect(() => {
    if (storedComposer) {
      setCurrentComposer(storedComposer)
    }
  }, [])

  const onChange = (composer) => {
    setCurrentComposer(composer)
    setStoredComposer(composer)

    // flush the api caches!
    dispatch(dataApiSlice.util.resetApiState())
    dispatch(defaultApiSlice.util.resetApiState())
    dispatch(caseApiSlice.util.resetApiState())
  }

  return (
    <>
      <Label for="select-composer">select composer</Label>
      <Select
        name="select-composer"
        value={storedComposer}
        options={composers || []}
        isLoading={isFetching}
        deselect="none"
        onChange={onChange}
      />
    </>
  )
}

export default SelectComposer
