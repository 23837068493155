import React, { useEffect } from 'react'
import { useLocalstorageState } from 'rooks'
import 'twin.macro'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { cloneDeep } from 'lodash'

import { GroupedAndStackedBar } from '@fxr/ui/charts/Bar'
import { VR, Modal, Loading, H3, Card, MultiSwitch } from '@fxr/ui'
import { objectFilterByKeys, joinWithFinalAnd, sortBy } from '@fxr/common/utils'

import useConfig from './useConfig'
import SelectColumns from './SelectColumns'
import { useFetchTabularQuery } from './features/data/dataApiSlice'
import { groupbyColumnsUpdated } from './features/groupby/groupbySlice'
import { filterUpdated } from './features/filters/filtersSlice'
import { ORGTYPE2COLOR, STRING2FORMATTED_STRING } from './constants'
import RRImageable from './RRImageable'

import { cmoName, pubName } from './constants'

const generateCaption = ({ barPlotType, columns }) => {
  const columnsDisplay = joinWithFinalAnd(
    columns.map((col) => STRING2FORMATTED_STRING[col])
  )

  if (barPlotType === 'tca') {
    return (
      'MPC and SPC total composition amounts (TCA) ' +
      "according to CMO's and publishers (PUB)" +
      `, per ${columnsDisplay}.`
    )
  }

  if (barPlotType === 'collectionAmount') {
    return (
      'MPC and SPC collection amounts ' +
      "according to CMO's and publishers (PUB)" +
      `, per ${columnsDisplay}.`
    )
  }

  // actualVsExpected
  return `Actual and expected collection amounts, per ${columnsDisplay}.`
}

const barPlotType2orgtype2columns = {
  tca: {
    cmo: ['cmo_mpc_total_comp_amount', 'cmo_spc_total_comp_amount'],
    pub: ['pub_mpc_total_comp_amount', 'pub_spc_total_comp_amount'],
  },
  collectionAmount: {
    cmo: ['cmo_mpc_collection_amount', 'cmo_spc_collection_amount'],
    pub: ['pub_mpc_collection_amount', 'pub_spc_collection_amount'],
  },
}

const replaceKeys = (obj) => {
  const out = {}
  Object.keys(obj).forEach((key) => {
    out[
      key
        .replace(/_/g, ' ')
        .replace('total comp amount', 'tca')
        .toUpperCase()
        .replace('COLLECTION AMOUNT', 'collection amount')
        .replace('CMO', cmoName)
        .replace('PUB', pubName)
    ] = obj[key]
  })
  return out
}

const getDataPerSelectedColumnPerOrg = ({ rows, column, barPlotType }) => {
  return rows.map((row) => {
    return {
      [column]: row[column],
      cmo: replaceKeys(
        objectFilterByKeys(row, barPlotType2orgtype2columns[barPlotType].cmo)
      ),
      publisher: replaceKeys(
        objectFilterByKeys(row, barPlotType2orgtype2columns[barPlotType].pub)
      ),
    }
  })
}

const getCollectionAmountDataPerSelectedColumnPerOrg = ({
  rows,
  column,
  perspectiveOrgtype,
  orgtype2orgsDisplay,
}) => {
  const getOrgs = (orgtype) => {
    if (!orgtype2orgsDisplay) {
      return orgtype
    }

    const displayOrgtype = { pub: pubName, cmo: cmoName }[orgtype]

    return `${displayOrgtype} (${joinWithFinalAnd(
      orgtype2orgsDisplay[orgtype]
    )})`
  }

  return rows.map((row) => {
    let orgtype = perspectiveOrgtype
    if (perspectiveOrgtype === 'deficient') {
      // deficient orgtype
      orgtype = row.mpc_total_comp_amount_difference < 0 ? 'cmo' : 'publisher'
    }

    return {
      [column]: row[column],
      actualCollection: {
        [`Actual MPC collection by ${getOrgs(orgtype)}`]:
          orgtype === 'cmo'
            ? row.cmo_mpc_collection_amount
            : row.pub_mpc_collection_amount,
      },
      estimatedCollection: {
        [`${getOrgs(
          orgtype
        )} estimated MPC collection`]: row.estimated_mpc_collection_amount_min,
        [`Uncertainty`]:
          row.estimated_mpc_collection_amount_max -
          row.estimated_mpc_collection_amount_min,
      },
    }
  })
}

const barPlotType2colors = {
  tca: {
    'CMO MPC TCA': ORGTYPE2COLOR.cmo,
    'CMO SPC TCA': ORGTYPE2COLOR.cmoAlt,
    'PUB MPC TCA': ORGTYPE2COLOR.pub,
    'PUB SPC TCA': ORGTYPE2COLOR.pubAlt,
  },
  collectionAmount: {
    'CMO MPC collection amount': ORGTYPE2COLOR.cmo,
    'CMO SPC collection amount': ORGTYPE2COLOR.cmoAlt,
    'PUB MPC collection amount': ORGTYPE2COLOR.pub,
    'PUB SPC collection amount': ORGTYPE2COLOR.pubAlt,
  },
  actualVsExpected: [
    // actual collection amount
    ORGTYPE2COLOR.cmo,
    // estimated collection amount
    ORGTYPE2COLOR.pub,
    ORGTYPE2COLOR.pubAlt,
  ],
}

const barPlotTypes = ['tca', 'collectionAmount', 'actualVsExpected']
const perspectiveOrgtypes = ['cmo', 'publisher'] // , 'deficient']

const BarPlotDisplay = (props) => {
  const [perspectiveOrgtype, setPerspectiveOrgtype] = useLocalstorageState(
    'fxr:bar-plot-display:perspective-orgtype',
    'cmo'
  )
  const [barPlotType, setBarPlotType] = useLocalstorageState(
    'fxr:bar-plot-display:bar-plot-type',
    'tca'
  )

  let columns = useSelector((state) => state.temporal.present.groupby.columns)
  const filters = useSelector((state) => state.temporal.present.filters)

  /* const requestFilters = cloneDeep(filters)
   * Object.keys(filters).forEach((col) => {
   *   const values = filters[col]

   *   if (col === 'year' && values.length === 1) {
   *     // need to add empty array in order to overwrite
   *     requestFilters[col] = []
   *   } else {
   *     requestFilters[col] = values
   *   }
   * }) */

  const { data, isFetching } = useFetchTabularQuery({
    forOrgtype: perspectiveOrgtype === 'deficient' ? null : perspectiveOrgtype,
    /*     filters: requestFilters, */
  })

  const dispatch = useDispatch()

  useEffect(() => {
    // on first load: set groupby columns to year
    dispatch(groupbyColumnsUpdated(['year']))
  }, [])

  useEffect(() => {}, [])

  const config = useConfig()

  if (data === undefined && !isFetching) {
    return <p>something went wrong.</p>
  }

  const getData = {
    tca: getDataPerSelectedColumnPerOrg,
    collectionAmount: getDataPerSelectedColumnPerOrg,
    actualVsExpected: getCollectionAmountDataPerSelectedColumnPerOrg,
  }[barPlotType]

  const colors = barPlotType2colors[barPlotType]

  const id = `bar-plot-${columns.join('-')}`

  const caption = generateCaption({ barPlotType, columns })

  return (
    <Card tw="mb-4">
      <Card.Header tw="flex justify-between items-center">
        <H3>bar plot</H3>

        <MultiSwitch
          options={barPlotTypes}
          value={barPlotType}
          onChange={setBarPlotType}
        />
      </Card.Header>
      <Card.Section>
        <div tw="flex items-stretch">
          <div tw="flex-1">
            <SelectColumns />
          </div>

          {barPlotType === 'actualVsExpected' && (
            <>
              <VR tw="mx-4" />

              <div tw="flex-1 flex items-center">
                <p>perspective: </p>

                <div tw="mx-2">
                  <MultiSwitch
                    options={perspectiveOrgtypes}
                    value={perspectiveOrgtype}
                    onChange={setPerspectiveOrgtype}
                  />
                </div>

                <Modal
                  renderToggle={({ toggleIsOpen }) => (
                    <>
                      {perspectiveOrgtype !== 'deficient' && (
                        <p
                          tw="cursor-pointer text-xs text-gray-500 italic underline"
                          onClick={toggleIsOpen}
                        >
                          Why can the expected amount be less than the actual
                          amount?
                        </p>
                      )}
                    </>
                  )}
                  title="Why can the expected amount be less than the actual amount?"
                >
                  {({ close }) => (
                    <>
                      <p>
                        If you take the perspective of an org, then the expected
                        amount is based on the amounts reported by the{' '}
                        <em>other</em> org(s). However, if that other org
                        collected less than what they should have, then the
                        expected amount will be accordingly (too) little. Of
                        course, there is also a chance that the org from whose
                        perspective we're looking actually collected more than
                        they should have.
                      </p>
                    </>
                  )}
                </Modal>
              </div>
            </>
          )}
        </div>
      </Card.Section>
      <Card.Body>
        {columns.length === 0 ? (
          <p>you need to select at least one column to group by</p>
        ) : isFetching ? (
          <Loading />
        ) : (
          <>
            <div tw="h-96 relative">
              <RRImageable
                label={id}
                width={1200}
                height={600}
                caption={caption}
              >
                <GroupedAndStackedBar
                  id={id}
                  colors={colors}
                  data={getData({
                    barPlotType,
                    rows: data.rows,
                    column: columns[0],
                    perspectiveOrgtype,
                    orgtype2orgsDisplay: config.orgtype2orgs_display,
                  })}
                  sortBarStackKeys={(keys) =>
                    sortBy(keys, (key) => {
                      if (key.includes('MPC')) {
                        return 2
                      }
                      if (key.includes('SPC')) {
                        return 1
                      }
                      return 3
                    })
                  }
                  yAxis={true}
                  xlabel={columns[0]}
                />
              </RRImageable>
            </div>
            <p tw="mt-2 text-center">{caption}</p>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default BarPlotDisplay
