import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'twin.macro'

import { Select } from '@fxr/ui'

import { CONTEXT_COLS } from './constants'
import { groupbyColumnsUpdated } from './features/groupby/groupbySlice'

const SelectColumns = ({ isMulti = true }) => {
  const columns = useSelector((state) => state.temporal.present.groupby.columns)
  const dispatch = useDispatch()

  const onChange = (newColumns) => dispatch(groupbyColumnsUpdated(newColumns))

  return (
    <div tw="max-w-lg relative zIndex[100]">
      <Select
        name="select-columns"
        label="columns to group by"
        isMulti={isMulti}
        creatable={true}
        value={columns}
        options={CONTEXT_COLS}
        onChange={onChange}
      />
    </div>
  )
}

export default SelectColumns
