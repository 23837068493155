import React, { useEffect } from 'react'
import { useLocalstorageState } from 'rooks'
import tw from 'twin.macro'
import { BsSun, BsMoon } from 'react-icons/bs'

import Switch from './forms/Switch'

const htmlElement = document.querySelector('html')

const Div = tw.div`fill-current text-white text-opacity-70 hover:text-opacity-90 cursor-pointer`

const DarkModeSwitch = (props) => {
  const [isDark, setIsDark] = useLocalstorageState('fxr:dark-mode', false)

  const toggleIsDark = () => setIsDark(!isDark)

  useEffect(() => {
    if (isDark) {
      htmlElement.classList.add('dark')
    } else {
      htmlElement.classList.remove('dark')
    }
  }, [isDark])

  const Icon = isDark ? BsMoon : BsSun
  return (
    <Div onClick={toggleIsDark}>
      <Icon tw="h-12 w-12" />
    </Div>
  )
}

export default DarkModeSwitch
