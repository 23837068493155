import React from 'react'
import tw, { styled } from 'twin.macro'

const Line = styled.div({
  ...tw`relative`,
  ':after': tw`absolute content top-0 bottom-0 left-3 w-px bg-gray-300`,
})

const LineItem = styled.div({
  ...tw`relative pl-8 mb-3`,
  ':before': tw`content absolute top-2 left[0.5px]
   rounded-full h-6 w-6 bg-gray-300 border-4 border-gray-200`,
})

const Timeline = ({ children }) => (
  <Line>
    {children.map((child, i) => (
      <LineItem key={i}>{child}</LineItem>
    ))}
  </Line>
)

export default Timeline
