import { useMemo } from 'react'

const useAutoColumns = (
  columns,
  rows,
  { checkAllRows = false, columnOptions = {}, memoized = true } = {}
) => {
  const keys = new Set()

  if (rows.length > 0) {
    rows.slice(0, checkAllRows ? rows.length : 1).forEach((row) => {
      Object.keys(row).forEach((key) => keys.add(key))
    })
  }

  const columnIds = []
  columns.forEach((col) => {
    columnIds.push(col.id)

    if ('columns' in col) {
      col.columns.forEach((ccol) => {
        columnIds.push(ccol.id)
      })
    }
  })

  const getColumns = () => {
    return [
      ...columns,
      ...Array.from(keys)
        .filter((key) => !columnIds.includes(key))
        .map((key) => ({
          id: key,
          Header: key,
          accessor: key,
          defaultSort: 'desc',
          disableFilters: true,
          ...columnOptions,
        })),
    ]
  }

  if (memoized) {
    return useMemo(getColumns, [...columnIds, ...keys])
  }

  return getColumns
}

export default useAutoColumns
