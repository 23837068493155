import React, { useEffect, useState, useMemo } from 'react'
import tw from 'twin.macro'
import { useLocalstorageState } from 'rooks'
import { useLocation, Link } from 'react-router-dom'

import {
  H4,
  VR,
  Card,
  Modal,
  Button,
  Loading,
  Input,
  IconButton,
  Select,
} from '@fxr/ui'

import CaseEvents from './CaseEvents'
import CaseReviewer from './CaseReviewer'
import CaseReviewsSummary from './CaseReviewsSummary'
import ContextBadges from './ContextBadges'
import useConfig from './useConfig'
import useExploreContext from './useExploreContext'
import {
  useGetCasesQuery,
  useUpdateCaseMutation,
} from './features/cases/caseApiSlice'

const EventsModal = ({ events = [] }) => {
  return (
    <Modal
      title="events"
      renderToggle={({ toggleIsOpen }) => (
        <Button.Neutral onClick={toggleIsOpen}>view events</Button.Neutral>
      )}
    >
      {() => (
        <>
          <CaseEvents events={events} />
        </>
      )}
    </Modal>
  )
}

const InterestingCaseBar = ({ closeComponent = null }) => {
  const config = useConfig()
  const { fxr_client: composer = '' } = config
  const [state, setState] = useState({
    caseId: null,
  })

  const exploreContext = useExploreContext({
    pushToHistory: false,
  })

  const { data: cases, isFetching } = useGetCasesQuery()
  const [updateCase, { data: updatedCase, isLoading }] = useUpdateCaseMutation()

  const updateState = (newState) => setState({ ...state, ...newState })

  const location = useLocation()
  useEffect(() => {
    if (location.state) {
      if ('caseId' in location.state) {
        updateState({ caseId: location.state.caseId })
      }
    }
  }, [location.state?.caseId])

  const ccase =
    Array.isArray(cases) && state.caseId
      ? cases.find((c) => c.id === state.caseId)
      : null

  useEffect(() => {
    if (ccase) {
      exploreContext(ccase.context_full)
    }
  }, [Array.isArray(cases), state.caseId])

  const selectNextCase = () => {
    const currentIndex = cases.findIndex((c) => c.id === state.caseId)

    if (currentIndex + 1 < cases.length) {
      updateState({ caseId: cases[currentIndex + 1].id })
    }
    // otherwise, just stay on the last case.
  }

  return (
    <div tw="w-full height[66px] border-t border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-700 px-4 py-2 relative">
      {closeComponent && (
        <IconButton
          tw="absolute -top-2 left-1 w-4! h-4!"
          onClick={closeComponent}
        >
          <span tw="text-gray-500 text-xs">&times;</span>
        </IconButton>
      )}
      {isFetching ? (
        <Loading />
      ) : (
        <>
          <div tw="flex items-stretch">
            <div tw="flex-basis[220px]">
              <H4>interesting cases</H4>

              <Select
                value={state.caseId}
                options={cases ? cases.map((c) => c.id) : []}
                isLoading={isFetching}
                menuPlacement="top"
                onChange={(caseId) => updateState({ caseId })}
              />
            </div>

            {ccase !== null && ccase !== undefined && (
              <>
                <VR tw="mx-6" />

                <div>
                  <H4>reviews</H4>

                  <CaseReviewsSummary events={ccase?.events} />
                </div>

                <VR tw="mx-6" />

                <EventsModal events={ccase?.events} />

                <VR tw="mx-6" />

                <Link to={`/interesting-cases/${ccase.id}`}>
                  <Button.Neutral>view case</Button.Neutral>
                </Link>

                <div tw="flex-1" />

                <VR tw="mx-6" />

                <div>
                  <H4>decide</H4>

                  <div tw="flex items-center space-x-2 w-96">
                    <CaseReviewer
                      ccase={ccase}
                      onDecisionSubmitted={() => {
                        // move to next case
                        selectNextCase()
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

const ICBComponent = ({ showICB, setShowICB }) => {
  if (showICB) {
    return <InterestingCaseBar closeComponent={() => setShowICB(false)} />
  }

  return (
    <div
      tw="absolute -bottom-4 right-20
      rounded-t bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-900 px-4 py-2 pb-6
      transform transition hover:-translate-y-1.5 cursor-pointer
      "
      onClick={() => setShowICB(true)}
    >
      <p tw="text-xs text-gray-500 dark:text-gray-400">interesting cases</p>
    </div>
  )
}

export const useInterestingCaseBarToggle = () => {
  const [showICB, setShowICB] = useLocalstorageState(
    'fxr:show-interesting-case-bar',
    false
  )

  const { state } = useLocation()
  useEffect(() => {
    if (state && 'caseId' in state) {
      setShowICB(true)
    }
  }, [Boolean(state), Boolean(state) && 'caseId' in state])

  return {
    showICB,
    renderedICBComponent: (
      <ICBComponent showICB={showICB} setShowICB={setShowICB} />
    ),
  }
}

export default InterestingCaseBar
