import React from 'react'
import { useLocalstorageState } from 'rooks'
import { useSelector } from 'react-redux'
import 'twin.macro'

import { Card } from '@fxr/ui'

import SelectComposer, { localstorageKey } from './SelectComposer'

const EnsureComposerIsSet = ({ children }) => {
  const [storedComposer, setStoredComposer] = useLocalstorageState(
    localstorageKey,
    null
  )

  const composer = useSelector((state) => state.composers.current)

  if (storedComposer === null || composer === null) {
    return (
      <div tw="h-screen w-screen flex justify-center items-center">
        <Card tw="w-72">
          <Card.Body>
            <SelectComposer />
          </Card.Body>
        </Card>
      </div>
    )
  }

  return children
}

export default EnsureComposerIsSet
