import React from 'react'
import tw from 'twin.macro'
import { BsQuestionCircle } from 'react-icons/bs'
import { Flex, Text, Box } from 'rebass/styled-components'

import { ButtonNeutral, IconButtonConcealed } from './Button'
import Modal from './Modal'

const Item = ({ name, body }) => {
  return (
    <Box>
      <Text as="p" tw="font-bold">
        {name}
      </Text>

      {body}
    </Box>
  )
}

const TutorialHint = (props) => {
  const renderToggle = ({ toggleIsOpen }) => (
    <IconButtonConcealed onClick={toggleIsOpen}>
      <Text tw="text-gray-500 text-lg">
        <BsQuestionCircle />
      </Text>
    </IconButtonConcealed>
  )

  return (
    <>
      <Modal renderToggle={renderToggle} title={props.title}>
        {({ close }) => (
          <>
            {props.items &&
              Object.keys(props.items).map((name) => (
                <Box key={name} tw="mb-3">
                  <Item name={name} body={props.items[name]} />
                </Box>
              ))}

            {props.children}

            <Flex tw="justify-end mt-8 md:mt-16 mb-4">
              <ButtonNeutral onClick={close}>OK, got it!</ButtonNeutral>
            </Flex>
          </>
        )}
      </Modal>
    </>
  )
}

export default TutorialHint
