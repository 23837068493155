import React from 'react'
import tw from 'twin.macro'
import { Box, Flex, Text } from 'rebass/styled-components'
import { AiOutlineLoading } from 'react-icons/ai'

import getEnsureRender from './getEnsureRender'
import Card from './Card'
import { default as Panel, PanelBody, PanelHeader } from './Panel'

export const Spinner = tw(AiOutlineLoading)`animate-spin dark:fill-current`

const Loading = ({ text = 'loading...' }) => (
  <Flex tw="items-center">
    <Spinner />{' '}
    <Text as="p" tw="ml-3">
      {text}
    </Text>
  </Flex>
)

export const LoadingCard = ({ text }) => (
  <Card>
    <Card.Body>
      <Loading text={text} />
    </Card.Body>
  </Card>
)
export const LoadingPanel = ({ header, text }) => (
  <Panel>
    {header && <PanelHeader children={header} />}
    <PanelBody>
      <Loading text={text} />
    </PanelBody>
  </Panel>
)

const LoadingPadded = ({ text }) => (
  <Box tw="p-2">
    <Loading text={text} />
  </Box>
)

const LoadingPaddedCard = ({ text }) => (
  <Box tw="p-4">
    <LoadingCard text={text} />
  </Box>
)

Loading.Card = LoadingCard
Loading.Panel = LoadingPanel
Loading.Padded = LoadingPadded
Loading.PaddedCard = LoadingPaddedCard

Loading.Ensure = {}
Loading.Ensure.Padded = getEnsureRender(LoadingPadded)
Loading.Ensure.Card = getEnsureRender(LoadingCard)
Loading.Ensure.PaddedCard = getEnsureRender(LoadingPaddedCard)

export default Loading
