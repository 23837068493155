import React from 'react'
import tw, { styled } from 'twin.macro'

const Panel = styled.div({
  ...tw`
  bg-gray-100
  dark:bg-gray-800
  rounded-lg
  `,
  '> div': tw`border-b border-white dark:border-gray-900`,
  '> div:first-of-type': tw`rounded-t-lg`,
  '> div:last-of-type': tw`border-b-0 rounded-b-lg`,
})

export const PanelSection = tw.div`
  px-4 py-2
`

export const PanelBody = tw.div`
  p-4
`

export const PanelHeader = tw.div`
  px-4 py-2
`

Panel.Section = PanelSection
Panel.Body = PanelBody
Panel.Header = PanelHeader

export default Panel
