import React, { useState, useEffect, useRef } from 'react'
import ChevronComponent from './Chevron'

const useChevronDropdown = (initialIsOpen = false) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen)

  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  const dropdownToggleProps = {
    onClick: toggleIsOpen,
    styles: { cursor: 'pointer' },
  }

  const Chevron = () => (
    <ChevronComponent
      direction={isOpen ? 'down' : 'right'}
      onClick={(ev) => {
        ev.stopPropagation()
        toggleIsOpen()
      }}
    />
  )

  return {
    isOpen,
    setIsOpen,
    Chevron,
    dropdownToggleProps,
  }
}

export default useChevronDropdown
