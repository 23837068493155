import React from 'react'
import tw from 'twin.macro'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  Badge,
  Loading,
  Error,
  Card,
  H2,
  H3,
  H4,
  ButtonNeutral,
  Dictionary,
} from '@fxr/ui'
import { round, objectMap } from '@fxr/common/utils'

import { useFetchCompositionMetadataQuery } from './features/data/dataApiSlice'
import { filtersUpdated } from './features/filters/filtersSlice'

const ListOrString = ({ children }) =>
  Array.isArray(children) ? (
    children.length === 0 ? (
      ''
    ) : children.length === 1 ? (
      children[0]
    ) : (
      <>
        <br />
        {children.map((child) => (
          <p key={child}>- {child}</p>
        ))}
      </>
    )
  ) : (
    children
  )

const Registration = ({ org, eid, metadata }) => {
  const { composition_cowriters, iswc: iswcs, title: titles } = metadata

  return (
    <div>
      <Badge.Neutral>
        <H4>
          {org}: {eid}
        </H4>
      </Badge.Neutral>
      <br />
      {titles && (
        <>
          <p tw="font-bold text-xs inline-block">original titles:</p>{' '}
          <ListOrString>{titles}</ListOrString>
        </>
      )}
      <div tw="block" />
      {composition_cowriters && (
        <>
          <p tw="font-bold text-xs inline-block">cowriters:</p>{' '}
          <ListOrString>{composition_cowriters}</ListOrString>
        </>
      )}
      <div tw="block" />
      {iswcs && (
        <>
          <p tw="font-bold text-xs inline-block">ISWC:</p>{' '}
          <ListOrString>{iswcs}</ListOrString>
        </>
      )}
    </div>
  )
}

const Composition = ({ title, metadata }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const onViewRelevantContexts = () => {
    dispatch(filtersUpdated({ unique_title: [title] }))
    history.push('/tabular')
  }

  return (
    <Card>
      <Card.Header css={tw`pr-2`}>
        <div tw="flex justify-between items-center">
          <H3>{title}</H3>

          <ButtonNeutral onClick={onViewRelevantContexts}>
            View relevant contexts
          </ButtonNeutral>
        </div>
      </Card.Header>
      <Card.Section>
        <H4>metadata</H4>
        <Dictionary
          object={{
            'total writer share': [
              metadata.total_writer_share
                ? `${round(metadata.total_writer_share * 100, 1)}%`
                : '-',
              metadata.is_provided ? '(provided)' : '(computed)',
            ].join(' '),
          }}
        />

        <H4 tw="mt-2">percentage of rows with reasonable %</H4>
        <Dictionary
          object={objectMap(metadata.org2fraction_reasonable, (v) => (
            <span css={v < 0.9 ? tw`text-red-500 font-bold` : ``}>
              {round(v * 100, 1)}%
            </span>
          ))}
        />
      </Card.Section>
      {Object.keys(metadata.org2org_eid2metadata).map((org) => {
        return Object.keys(metadata.org2org_eid2metadata[org]).map((eid) => {
          return (
            <Card.Section key={`${org}-${eid}`}>
              <Registration
                org={org}
                eid={eid}
                metadata={metadata.org2org_eid2metadata[org][eid]}
              />
            </Card.Section>
          )
        })
      })}
    </Card>
  )
}

const CompositionMetadata = (props) => {
  const { data, isFetching } = useFetchCompositionMetadataQuery()

  if (isFetching) return <Loading.Card />
  if (!data) return <Error />

  const { title2metadata } = data

  console.log({ title2metadata })

  return (
    <div tw="max-w-4xl mx-auto p-4">
      <Card tw="mb-4">
        <Card.Header>
          <div tw="max-w-5xl">
            <H2>composition metadata</H2>
          </div>
        </Card.Header>
      </Card>

      {Object.keys(title2metadata).map((title) => {
        const metadata = title2metadata[title]
        return (
          <div tw="mb-4" key={title}>
            <Composition title={title} metadata={metadata} />
          </div>
        )
      })}
    </div>
  )
}

export default CompositionMetadata
