import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const badgeVariants = {
  default: tw`py-0.5 px-2`,
  xs: tw`py-0.5 px-1 text-xs`,
}

const Badge = styled.div(() => [
  tw`inline-block rounded whitespace-nowrap overflow-x-hidden overflow-ellipsis`,
  ({ variant = 'default' }) => badgeVariants[variant],
])

Badge.Neutral = tw(
  Badge
)`bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-white`

Badge.Subtle = tw(
  Badge
)`bg-white dark:bg-gray-700 text-gray-600 dark:text-white border border-gray-400`

export default Badge
