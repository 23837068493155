import React from 'react'
import tw, { styled } from 'twin.macro'
import { Text, Image } from 'rebass/styled-components'
import { Link } from 'react-router-dom'

import wordmark from './images/fxr-wordmark.png'

const Navbar = tw.div`w-full p-4 bg-navy flex items-center sticky z-index[1000] top-0`

export const NavbarFXRHeader = ({ icon, name, suffix = null }) => (
  <>
    <Image src={icon} width={28} height={28} tw="mr-4" />
    <Image src={wordmark} width="auto" height={18} tw="mr-4" />
    <Text as="h4" tw="text-white font-semibold">
      {name}
      {suffix && (
        <Text as="span" tw="pl-2 text-xs">
          {suffix}
        </Text>
      )}
    </Text>
  </>
)

const LinkWithoutAttrs = ({ active, currentPath, exact, ...props }) => (
  <Link {...props} />
)

export const Navlink = styled(
  LinkWithoutAttrs
)(({ to, active = false, currentPath = null, exact = false }) => [
  tw`font-bold font-header text-white border-b-2 border-transparent transition`,
  active ||
  (exact ? to === currentPath : currentPath && currentPath.startsWith(to))
    ? tw`cursor-default border-white`
    : tw`hover:(border-white border-opacity-75)`,
])

export default Navbar
