import { round, joinWithFinalAnd } from '@fxr/common/utils'
import { parseCollectionDiscrepancies } from '@fxr/common/mutations'

const useCollectionDiscrepancies = ({ unparsedDiscrepancies }) => {
  const discrepancies = parseCollectionDiscrepancies(unparsedDiscrepancies).map(
    (discrepancy) => {
      return {
        ...discrepancy,
        renderText: () => (
          <>
            {joinWithFinalAnd(discrepancy.uncollectors)} never collected
            anything, while{' '}
            {joinWithFinalAnd(
              Object.keys(discrepancy.collector2tca).map((collector, i) => (
                <span key={collector}>
                  {collector} collected{' '}
                  {round(discrepancy.collector2tca[collector], 2)}
                </span>
              ))
            )}
          </>
        ),
      }
    }
  )

  return {
    discrepancies,
  }
}

export default useCollectionDiscrepancies
