import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, Card, Loading, Error } from '@fxr/ui'

import { useCreateCaseMutation } from './features/cases/caseApiSlice'

const InterestingCaseCreator = () => {
  const filters = useSelector((state) => state.temporal.present.filters)

  const history = useHistory()

  const [
    createCase,
    { data: createdCase, isLoading, error },
  ] = useCreateCaseMutation()

  useEffect(() => {
    if (createdCase && createdCase.id) {
      console.log(`/interesting-cases/${createdCase.id}`)

      history.push(`/interesting-cases/${createdCase.id}`)
    }
  }, [Boolean(createdCase), createdCase?.id])

  const onCreate = () => {
    // filters is not necessarily a context_full!
    createCase({ ccase: { context: filters } })
  }

  return (
    <>
      {error && <Error>{error.data.message}</Error>}
      {isLoading && (
        <Card.Info tw="mb-2">
          <Loading text="creating case..." />
        </Card.Info>
      )}
      <Button.Neutral onClick={onCreate} disabled={isLoading}>
        create case from current filters
      </Button.Neutral>
    </>
  )
}

export default InterestingCaseCreator
