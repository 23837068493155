import 'twin.macro'

import {
  TextColumnFilter,
  NumberRangeColumnFilter,
  NumberLowerBoundFilter,
  NumberUpperBoundFilter,
  /*   SliderColumnFilter, */
} from '@fxr/ui/rtable/Filters'
/* import * as aggregators from '@fxr/ui/rtable/aggregators' */
import { sumFooter, absSumFooter } from '@fxr/ui/rtable/utils'
import {
  numericalRenderer,
  percentualRenderer,
  /*   progressBarRenderer, */
  binnedDistributionRenderer,
} from '@fxr/ui/rtable/renderers'
/* import { round } from '@fxr/common/utils' */

import { ORGTYPE2COLOR } from './constants'
import { cmoName, pubName } from './constants'

const numericalRenderer2 = numericalRenderer(2)

const differenceAggregationFooter = (info) => {
  const key = 'mpc_total_comp_amount_difference'
  return (
    <div tw="flex items-center bg-gray-100 dark:bg-gray-800 rounded px-2">
      <div tw="flex-1">{sumFooter(key, 'overall')(info)}</div>
      <div tw="flex-1">{absSumFooter(key, 'abs. total')(info)}</div>
    </div>
  )
}

const getEstimatedAmountCols = (amountName) => ({
  [`estimated_${amountName}_min`]: {
    Header: 'min',
    accessor: (row) => Math.abs(row[`estimated_${amountName}_min`]),
    Filter: NumberRangeColumnFilter,
    filter: 'between',
    Footer: sumFooter(`estimated_${amountName}_min`),
    Cell: numericalRenderer2,
    width: 140,
  },
  [`estimated_${amountName}_max`]: {
    Header: 'max',
    accessor: (row) => Math.abs(row[`estimated_${amountName}_max`]),
    Filter: NumberRangeColumnFilter,
    filter: 'between',
    Footer: sumFooter(`estimated_${amountName}_max`),
    Cell: numericalRenderer2,
    width: 140,
  },
})

const columns = {
  mpc: {
    Header: 'mpc',
    accessor: (row) => (row['mpc'] ? 'True' : 'False'),
    Filter: TextColumnFilter,
    filter: 'text',
    width: 100,
  },
  usage_music_user: {
    Header: 'usage_music_user',
    accessor: 'usage_music_user',
    Filter: TextColumnFilter,
    filter: 'text',
    width: 180,
  },
  org_eid: {
    Header: 'org_eid',
    accessor: 'org_eid',
    Filter: TextColumnFilter,
    filter: 'text',
    width: 140,
  },
  org: {
    Header: 'org',
    accessor: 'org',
    Filter: TextColumnFilter,
    filter: 'text',
    width: 140,
  },
  territory: {
    Header: 'territory',
    accessor: 'territory',
    /*     Cell: ({ value }) => `${value} - ${alpha2country(value)}`, */
    Filter: TextColumnFilter,
    filter: 'textOrUnknown',
    width: 210,
  },
  territory_grouped: {
    Header: 'territory_grouped',
    accessor: 'territory_grouped',
    /*     Cell: ({ value }) => `${value} - ${alpha2country(value)}`, */
    Filter: TextColumnFilter,
    filter: 'textOrUnknown',
    width: 210,
  },
  income_stream_type: {
    Header: 'income type',
    accessor: 'income_stream_type',
    Filter: TextColumnFilter,
    filter: 'textOrUnknown',
    width: 200,
  },
  unique_title: {
    Header: 'song title',
    accessor: 'unique_title',
    Filter: TextColumnFilter,
    filter: 'textOrUnknown',
    width: 220,
  },
  year: {
    Header: 'year',
    accessor: 'year',
    Filter: NumberRangeColumnFilter,
    filter: 'between',
    width: 150,
  },
  usage_type: {
    Header: 'usage_type',
    accessor: 'usage_type',
    Filter: TextColumnFilter,
    filter: 'textOrUnknown',
    width: 150,
  },
  cowriters: {
    Header: 'cowriters',
    accessor: 'cowriters',
    Filter: TextColumnFilter,
    filter: 'textOrUnknown',
    width: 250,
  },
  confidence: {
    Header: 'confidence',
    accessor: 'confidence',
    Filter: TextColumnFilter,
    filter: 'textOrUnknown',
    width: 140,
  },
  usage_type_confidence: {
    Header: 'usage_type_confidence',
    accessor: 'usage_type_confidence',
    Filter: TextColumnFilter,
    filter: 'textOrUnknown',
    width: 140,
  },
  income_stream_type_confidence: {
    Header: 'IST confidence',
    accessor: 'income_stream_type_confidence',
    Filter: TextColumnFilter,
    filter: 'textOrUnknown',
    width: 140,
  },
  territory_confidence: {
    Header: 'territory_confidence',
    accessor: 'territory_confidence',
    Filter: TextColumnFilter,
    filter: 'textOrUnknown',
    width: 140,
  },
  reasonable_perc_confidence: {
    Header: 'reasonable_perc_confidence',
    accessor: 'reasonable_perc_confidence',
    Filter: TextColumnFilter,
    filter: 'textOrUnknown',
    width: 140,
  },
  year_confidence: {
    Header: 'year_confidence',
    accessor: 'year_confidence',
    Filter: TextColumnFilter,
    filter: 'textOrUnknown',
    width: 140,
  },
  cmo_spc_total_comp_amount: {
    Header: 'cmo_spc_total_comp_amount',
    accessor: 'cmo_spc_total_comp_amount',
    defaultSort: 'desc',
    disableFilters: true,
    Footer: sumFooter('cmo_spc_total_comp_amount'),
    Cell: numericalRenderer2,
    width: 150,
  },
  pub_spc_total_comp_amount: {
    Header: 'pub_spc_total_comp_amount',
    accessor: 'pub_spc_total_comp_amount',
    defaultSort: 'desc',
    disableFilters: true,
    Footer: sumFooter('pub_spc_total_comp_amount'),
    Cell: numericalRenderer2,
    width: 150,
  },
  cmo_mpc_total_comp_amount: {
    Header: 'cmo_mpc_total_comp_amount',
    accessor: 'cmo_mpc_total_comp_amount',
    defaultSort: 'desc',
    disableFilters: true,
    Footer: sumFooter('cmo_mpc_total_comp_amount'),
    Cell: numericalRenderer2,
    width: 120,
  },
  pub_mpc_total_comp_amount: {
    Header: 'pub_mpc_total_comp_amount',
    accessor: 'pub_mpc_total_comp_amount',
    defaultSort: 'desc',
    disableFilters: true,
    Footer: sumFooter('pub_mpc_total_comp_amount'),
    Cell: numericalRenderer2,
    width: 120,
  },
  cmo_mpc_collection_amount: {
    Header: 'cmo_mpc_collection_amount',
    accessor: 'cmo_mpc_collection_amount',
    defaultSort: 'desc',
    disableFilters: true,
    Footer: sumFooter('cmo_mpc_collection_amount'),
    Cell: numericalRenderer2,
    width: 150,
  },
  pub_mpc_collection_amount: {
    Header: 'pub_mpc_collection_amount',
    accessor: 'pub_mpc_collection_amount',
    defaultSort: 'desc',
    disableFilters: true,
    Footer: sumFooter('pub_mpc_collection_amount'),
    Cell: numericalRenderer2,
    width: 150,
  },
  cmo_spc_collection_amount: {
    Header: 'cmo_spc_collection_amount',
    accessor: 'cmo_spc_collection_amount',
    defaultSort: 'desc',
    disableFilters: true,
    Footer: sumFooter('cmo_spc_collection_amount'),
    Cell: numericalRenderer2,
    width: 150,
  },
  pub_spc_collection_amount: {
    Header: 'pub_spc_collection_amount',
    accessor: 'pub_spc_collection_amount',
    defaultSort: 'desc',
    disableFilters: true,
    Footer: sumFooter('pub_spc_collection_amount'),
    Cell: numericalRenderer2,
    width: 150,
  },
  deficient_org: {
    Header: 'deficient org',
    accessor: (row) =>
      row.mpc_total_comp_amount_difference < 0 ? cmoName : pubName,
    disableFilters: true,
    Footer: '',
    width: 110,
  },
  collection_deficient_org: {
    Header: 'collected-less org',
    accessor: (row) => {
      return row.cmo_mpc_collection_amount > row.pub_mpc_collection_amount
        ? pubName
        : cmoName
    },
    disableFilters: true,
    Footer: '',
    width: 110,
  },
  mpc_total_comp_amount_difference: {
    Header: 'mpc_total_comp_amount_difference',
    accessor: 'mpc_total_comp_amount_difference',
    defaultSort: 'desc',
    Filter: (obj) => NumberRangeColumnFilter({ ...obj, displayMinMax: false }),
    filter: 'betweenAbs',
    Footer: differenceAggregationFooter,
    Cell: ({ value }) => {
      const color =
        value > 0
          ? ORGTYPE2COLOR.cmo
          : value < 0
          ? ORGTYPE2COLOR.pub
          : 'inherit'
      return (
        <span tw="font-bold" css={{ color }}>
          {numericalRenderer2({ value: Math.abs(value) })}
        </span>
      )
    },
    width: 200,
  },
  mpc_total_comp_amount_difference_pct: {
    Header: 'MPC TCA % diff',
    accessor: 'mpc_total_comp_amount_difference_abs_frac',
    Filter: NumberRangeColumnFilter,
    filter: 'between',
    Cell: percentualRenderer(2, { multiplyBy: 100 }),
    width: 160,
  },
  mpc_collection_amount_difference_pct: {
    Header: 'MPC collection % diff',
    accessor: (row) => {
      const absDifference = Math.abs(
        row.cmo_mpc_collection_amount - row.pub_mpc_collection_amount
      )
      const maxCA = Math.max(
        row.cmo_mpc_collection_amount,
        row.pub_mpc_collection_amount
      )
      if (maxCA === 0) {
        return Infinity
      }
      return absDifference / maxCA
    },
    Filter: NumberRangeColumnFilter,
    filter: 'between',
    Cell: percentualRenderer(2, { multiplyBy: 100 }),
    width: 160,
  },
  ...getEstimatedAmountCols('mpc_client_amount_due'),
  ...getEstimatedAmountCols('mpc_collection_amount'),
  ...getEstimatedAmountCols('mpc_collection_amount_due'),
  pub_mpc_total_comp_amount_binned: {
    Header: 'pub_mpc_total_comp_amount_confidences',
    accessor: 'pub_mpc_total_comp_amount_binned',
    defaultSort: 'desc',
    disableFilters: true,
    Cell: binnedDistributionRenderer({}),
    width: 120,
  },
  cmo_mpc_total_comp_amount_binned: {
    Header: 'cmo_mpc_total_comp_amount_confidences',
    accessor: 'cmo_mpc_total_comp_amount_binned',
    defaultSort: 'desc',
    disableFilters: true,
    Cell: binnedDistributionRenderer({}),
    width: 120,
  },
}
Object.keys(columns).forEach((cid) => {
  columns[cid]['id'] = cid
})

let columnGroups = {
  spc_total_comp_amounts: {
    Header: 'SPC total comp amounts',
    Footer: '',
    columns: [
      {
        ...columns.cmo_spc_total_comp_amount,
        Header: cmoName,
      },
      {
        ...columns.pub_spc_total_comp_amount,
        Header: pubName,
      },
    ],
  },
  mpc_total_comp_amounts: {
    Header: 'MPC total comp amounts',
    Footer: '',
    columns: [
      {
        ...columns.cmo_mpc_total_comp_amount,
        Header: cmoName,
      },
      {
        ...columns.pub_mpc_total_comp_amount,
        Header: pubName,
      },
      {
        ...columns.mpc_total_comp_amount_difference,
        Header: 'difference',
      },
      {
        ...columns.mpc_total_comp_amount_difference_pct,
        Header: '% diff',
      },
    ],
  },
  mpc_total_comp_amounts_binned: {
    Header: 'MPC TCA confidences',
    Footer: '',
    columns: [
      {
        ...columns.cmo_mpc_total_comp_amount_binned,
        Header: cmoName,
      },
      {
        ...columns.pub_mpc_total_comp_amount_binned,
        Header: pubName,
      },
    ],
  },
  spc_collection_amounts: {
    Header: 'SPC collection amounts',
    Footer: '',
    columns: [
      {
        ...columns.cmo_spc_collection_amount,
        Header: cmoName,
      },
      {
        ...columns.pub_spc_collection_amount,
        Header: pubName,
      },
    ],
  },
  mpc_collection_amounts: {
    Header: 'MPC collection amounts',
    Footer: '',
    columns: [
      {
        ...columns.cmo_mpc_collection_amount,
        Header: cmoName,
      },
      {
        ...columns.pub_mpc_collection_amount,
        Header: pubName,
      },
    ],
  },
  estimated_mpc_client_amount_due_range: {
    Header: 'est. MPC net client due',
    Footer: '',
    columns: [
      {
        ...columns.estimated_mpc_client_amount_due_min,
        width: 100,
        Filter: NumberLowerBoundFilter,
        filter: 'greaterThan',
      },
      {
        ...columns.estimated_mpc_client_amount_due_max,
        width: 100,
        Filter: NumberUpperBoundFilter,
        filter: 'lessThan',
      },
    ],
  },
  estimated_mpc_collection_amount_range: {
    Header: 'est. MPC collection amount',
    Footer: '',
    columns: [
      {
        ...columns.estimated_mpc_collection_amount_min,
        width: 100,
        Filter: NumberLowerBoundFilter,
        filter: 'greaterThan',
      },
      {
        ...columns.estimated_mpc_collection_amount_max,
        width: 100,
        Filter: NumberUpperBoundFilter,
        filter: 'lessThan',
      },
    ],
  },
  estimated_mpc_collection_amount_due_range: {
    Header: 'est. MPC collection amount due',
    Footer: '',
    columns: [
      {
        ...columns.estimated_mpc_collection_amount_due_min,
        width: 120,
        Filter: NumberLowerBoundFilter,
        filter: 'greaterThan',
      },
      {
        ...columns.estimated_mpc_collection_amount_due_max,
        width: 120,
        Filter: NumberUpperBoundFilter,
        filter: 'lessThan',
      },
    ],
  },
}
Object.keys(columnGroups).forEach((cid) => {
  columnGroups[cid]['id'] = cid
})

export { columnGroups }
export default columns
