import tw, { styled } from 'twin.macro'
import RHW from 'react-highlight-words'

const Highlighter = ({ className, queries, children }) => {
  return (
    <span className={className}>
      <RHW
        highlightClassName="highlighted"
        searchWords={queries}
        textToHighlight={children}
      />
    </span>
  )
}

export default styled(Highlighter)({
  '.highlighted': tw`bg-brand bg-opacity-40 rounded-sm dark:(text-white bg-brand bg-opacity-60)`,
})
