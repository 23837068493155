import { theme } from 'twin.macro'

export const confidenceLevel2color = {
  red: theme`colors.red.500`,
  orange: theme`colors.yellow.500`,
  yellow: theme`colors.yellow.300`,
  green: theme`colors.green.500`,
}

export const getColorFromConfidenceLevel = (confidenceLevel) =>
  confidenceLevel in confidenceLevel2color
    ? confidenceLevel2color[confidenceLevel]
    : theme`colors.gray.600`
