import React, { useState, useEffect } from 'react'
import { useLocalstorageState } from 'rooks'
import 'twin.macro'

import Sankey from '@fxr/ui/charts/Sankey'
import { VR, H2, Card, Loading, MultiSwitch } from '@fxr/ui'

import { useFetchSourceTargetFlowsQuery } from './features/data/dataApiSlice'
import useConfig from './useConfig'

const valueColAbbrev2valueCol = {
  TCA: 'total_comp_amount',
  'collection amount': 'collection_amount',
}

const collectionTypes = ['mpc', 'spc', 'both']

const SankeyDisplay = () => {
  const [org, setOrg] = useState(null)
  const [valueColAbbrev, setValueColAbbrev] = useLocalstorageState(
    'fxr:sankey:value-col',
    'collection amount'
  )
  const [collectionType, setCollectionType] = useLocalstorageState(
    'fxr:sankey:collection-type',
    'mpc'
  )

  let valueCol = valueColAbbrev2valueCol[valueColAbbrev]
  // disable collectionType filter for now because we have MPC as main filter
  /* if (collectionType !== 'both') {
   *   valueCol = `${collectionType}_${valueCol}`
   * } */

  const { data, isFetching } = useFetchSourceTargetFlowsQuery({
    flowCols: 'payment_sources',
    valueCol,
    org,
  })

  const config = useConfig()

  useEffect(() => {
    config && config.orgs && setOrg(config.orgs[0])
  }, [config])

  const rows = data?.rows || []

  return (
    <>
      <Card>
        <Card.Header>
          <H2>sankey diagram</H2>
        </Card.Header>
        <Card.Section tw="flex">
          <MultiSwitch
            options={config.orgs || []}
            value={org}
            onChange={setOrg}
          />
          <div tw="flex-1" />

          {/* <MultiSwitch
              options={collectionTypes}
              value={collectionType}
              onChange={setCollectionType}
              /> */}

          {/*           <VR tw="mx-2" /> */}

          <MultiSwitch
            options={Object.keys(valueColAbbrev2valueCol)}
            value={valueColAbbrev}
            onChange={setValueColAbbrev}
          />
        </Card.Section>
        <Card.Body tw="p-4">
          {isFetching ? (
            <Loading />
          ) : rows.length === 0 ? (
            <p>No data found.</p>
          ) : (
            <Sankey data={[['From', 'To', 'Weight'], ...rows]} />
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default SankeyDisplay
