import React, { useState, useMemo } from 'react'
import tw, { theme } from 'twin.macro'
import { BarGroup, BarStack, Bar as VBar } from '@visx/shape'
import { Group } from '@visx/group'
import { Grid } from '@visx/grid'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import {
  useTooltip as useVTooltip,
  useTooltipInPortal,
  defaultStyles,
} from '@visx/tooltip'
import {
  LegendOrdinal,
  defaultStyles as legendDefaultStyles,
} from '@visx/legend'
import { localPoint } from '@visx/event'

import { round, sumArray, isObject } from '@fxr/common/utils'

import HR from '../HR'
import withAutoSize from './withAutoSize'

const defaultMargin = { top: 10, right: 0, bottom: 10, left: 0 }
const defaultColors = [
  theme('colors.brand.lightest'),
  theme('colors.brand.dark'),
  theme('colors.brand.darker'),
  theme('colors.blue.500'),
  theme('colors.yellow.700'),
]
const tooltipStyles = {
  ...defaultStyles,
  ...tw`bg-navy text-gray-100 p-2`,
}

const legendStyle = tw`flex mt-3 p-1 bg-gray-100`

const useMemoScale = (fn, args, deps) => useMemo(() => fn(args), deps)

const useColorScale = (stackedKeys, _colors) => {
  let domain = stackedKeys
  let range = _colors

  if (isObject(_colors) && !Array.isArray(_colors)) {
    domain = Object.keys(_colors)
    range = domain.map((key) => _colors[key])
  }

  return useMemoScale(
    scaleOrdinal,
    {
      domain,
      range,
    },
    [domain, range]
  )
}

const useTooltip = () => {
  const [tooltipTimeout, setTooltipTimeout] = useState(null)
  const tooltip = useVTooltip()

  const tooltipEventListeners = {
    onMouseLeave: () => {
      setTooltipTimeout(
        window.setTimeout(() => {
          tooltip.hideTooltip()
        }, 300)
      )
    },
    onMouseMove: ({ event, bar, barX }) => {
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout)
        setTooltipTimeout(null)
      }

      // TooltipInPortal expects coordinates to be relative to containerRef
      // localPoint returns coordinates relative to the nearest SVG, which
      // is what containerRef is set to in this example.
      const eventSvgCoords = localPoint(event)
      const left = barX + bar.width / 2
      tooltip.showTooltip({
        tooltipData: bar,
        tooltipTop: eventSvgCoords?.y,
        tooltipLeft: left,
      })
    },
  }

  return {
    ...tooltip,
    tooltipTimeout,
    tooltipEventListeners,
  }
}

export const GroupedAndStackedBar = withAutoSize(
  ({
    data,
    xlabel,
    width = 600,
    height = 360,
    events = false,
    margin: _margin = defaultMargin,
    formatXLabel = (x) => x,
    formatYLabel = (label) => label,
    xLabelProps = {},
    yLabelProps = {},
    colors = defaultColors,
    sortBarStackKeys = null,
    yAxis = false,
  }) => {
    // duplicate because we'll be changing it
    const margin = { ..._margin }

    if (yAxis) margin.left = margin.left + 80

    let maxStackedValue = 0
    let groupedKeys = new Set()
    let stackedKeys = new Set()
    data.forEach((item) => {
      const dataKeys = Object.keys(item).filter(
        (d) => ![xlabel, 'meta'].includes(d)
      )
      dataKeys.forEach((key) => groupedKeys.add(key))

      dataKeys.forEach((key) => {
        const val = item[key]
        Object.keys(val).forEach((key) => stackedKeys.add(key))
        const stackedValue = sumArray(Object.values(val))
        if (stackedValue > maxStackedValue) maxStackedValue = stackedValue
      })
    })
    stackedKeys = Array.from(stackedKeys)
    groupedKeys = Array.from(groupedKeys)

    const getXLabelProps = (label) =>
      isObject(xLabelProps) ? xLabelProps : xLabelProps(label)

    const getXLabel = (d) => d[xlabel]
    const xLabels = data.map(getXLabel)

    const xLabelScale = useMemoScale(
      scaleBand,
      {
        domain: xLabels,
        padding: 0.2,
      },
      [xLabels]
    )

    const groupedScale = useMemoScale(
      scaleBand,
      {
        domain: groupedKeys,
        padding: 0.2,
      },
      [groupedKeys]
    )

    const stackedScale = useMemoScale(
      scaleBand,
      {
        domain: stackedKeys,
        padding: 0.7,
      },
      [stackedKeys]
    )

    const valueScale = useMemoScale(
      scaleLinear,
      {
        domain: [0, maxStackedValue * 1.2],
      },
      [maxStackedValue]
    )

    const colorScale = useColorScale(stackedKeys, colors)

    const {
      tooltipOpen,
      tooltipLeft,
      tooltipTop,
      tooltipData,
      hideTooltip,
      showTooltip,
      tooltipEventListeners,
    } = useTooltip()

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
      // TooltipInPortal is rendered in a separate child of <body /> and positioned
      // with page coordinates which should be updated on scroll. consider using
      // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
      scroll: true,
    })

    // bounds
    const xMax = width - margin.left
    const yMax = height - margin.top - 30

    xLabelScale.rangeRound([0, xMax])
    groupedScale.rangeRound([0, xLabelScale.bandwidth()])
    stackedScale.rangeRound([0, groupedScale.bandwidth() * 0.7])
    valueScale.range([yMax, 0])

    return (
      <div style={{ position: 'relative', color: 'black' }}>
        <svg ref={containerRef} width={width} height={height}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill={theme('colors.gray.100')}
            rx={theme('borderRadius.lg')}
          />
          <Grid
            top={margin.top}
            left={margin.left}
            xScale={xLabelScale}
            yScale={valueScale}
            width={xMax}
            height={yMax}
            stroke="black"
            strokeOpacity={0.1}
            xOffset={xLabelScale.bandwidth() / 2}
          />
          <BarGroup
            data={data}
            keys={groupedKeys}
            width={xMax}
            height={yMax}
            x0={getXLabel}
            x0Scale={xLabelScale}
            x1Scale={groupedScale}
            yScale={valueScale}
            color={() => null}
          >
            {(barGroups) =>
              barGroups.map((barGroup, barGroupIndex) => (
                <Group
                  key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0 + margin.left}
                  top={margin.top}
                >
                  {barGroup.bars.map((bar) => (
                    <BarStack
                      data={[{ index: bar.index, ...bar.value }]}
                      keys={
                        sortBarStackKeys
                          ? sortBarStackKeys(Object.keys(bar.value))
                          : Object.keys(bar.value)
                      }
                      x={(b) => b.index}
                      xScale={stackedScale}
                      yScale={valueScale}
                      color={colorScale}
                    >
                      {(barStacks) => {
                        return barStacks.map((barStack) =>
                          barStack.bars.map((subBar) => (
                            <rect
                              key={`bar-stack-${barGroup.index}-${barStack.index}-${subBar.index}`}
                              x={bar.x + subBar.x}
                              y={subBar.y}
                              height={subBar.height}
                              width={bar.width + subBar.width}
                              fill={subBar.color}
                              {...tooltipEventListeners}
                              onMouseMove={(event) =>
                                tooltipEventListeners.onMouseMove({
                                  event,
                                  bar: {
                                    ...subBar,
                                    xLabel: xLabels[barGroupIndex],
                                  },
                                  barX: barGroup.x0 + bar.x + subBar.x,
                                })
                              }
                            />
                          ))
                        )
                      }}
                    </BarStack>
                  ))}
                </Group>
              ))
            }
          </BarGroup>
          <AxisBottom
            top={yMax + margin.top}
            left={margin.left}
            scale={xLabelScale}
            tickFormat={formatXLabel}
            stroke={theme('colors.navy.DEFAULT')}
            tickStroke={theme('colors.navy.DEFAULT')}
            tickLabelProps={(label) => ({
              fill: theme('colors.navy.DEFAULT'),
              fontSize: 11,
              textAnchor: 'middle',
              ...getXLabelProps(label),
            })}
          />
          {yAxis && (
            <AxisLeft
              top={margin.top}
              left={margin.left}
              scale={valueScale}
              tickFormat={formatYLabel}
              stroke={theme('colors.navy.DEFAULT')}
              tickStroke={theme('colors.navy.DEFAULT')}
              tickLabelProps={(label) => ({
                fill: theme('colors.navy.DEFAULT'),
                fontSize: 11,
                textAnchor: 'right',
                dx: -50,
                ...(isObject(yLabelProps) ? yLabelProps : yLabelProps(label)),
              })}
            />
          )}
        </svg>
        <div
          style={{
            position: 'absolute',
            top: margin.top / 2 - 10,
            left: margin.left,
            width: xMax,
            display: 'flex',
            justifyContent: 'center',
            fontSize: '14px',
          }}
        >
          <LegendOrdinal
            scale={colorScale}
            style={legendStyle}
            direction="row"
            labelMargin="0 15px 0 0"
          />
        </div>

        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            <div style={{ color: colorScale(tooltipData.key) }}>
              <strong>{tooltipData.key}</strong>
            </div>
            <div>
              <strong>{tooltipData.xLabel}</strong>
            </div>

            <HR />

            <div>{round(tooltipData.bar.data[tooltipData.key], 2)}</div>
            <div>
              <small></small>
            </div>
          </TooltipInPortal>
        )}
      </div>
    )
  }
)

const Bar = withAutoSize(
  ({
    data,
    xlabel,
    yAxis = true,
    width = 600,
    height = 360,
    events = false,
    margin: _margin = { ...defaultMargin, bottom: 20, left: 0 },
    formatXLabel = (label) => label,
    formatYLabel = (label) => label,
    xLabelProps = {},
    yLabelProps = {},
    yMaxValue = null,
    background = theme`colors.gray.100`,
    getFill = () => theme`colors.brand.DEFAULT`,
  }) => {
    const margin = { ..._margin }

    if (yAxis) margin.left = margin.left + 80

    // bounds
    const xMax = width
    const yMax = height - margin.top - margin.bottom

    const dataValues = new Set(data.map((item) => item.value))
    const xLabels = new Set(data.map((item) => item[xlabel]))

    const getXLabelProps = (label) =>
      isObject(xLabelProps) ? xLabelProps : xLabelProps(label)

    const xScale = useMemoScale(
      scaleBand,
      {
        range: [margin.left, xMax - margin.right],
        round: true,
        domain: xLabels,
        padding: 0.4,
      },
      [xMax, xLabels]
    )

    const yScale = useMemoScale(
      scaleLinear,
      {
        range: [yMax * 0.9, 0],
        round: true,
        domain: [0, yMaxValue || Math.max(...dataValues)],
        padding: 0.1,
      },
      [yMax, dataValues]
    )

    const {
      tooltipOpen,
      tooltipLeft,
      tooltipTop,
      tooltipData,
      hideTooltip,
      showTooltip,
      tooltipEventListeners,
    } = useTooltip()

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
      // TooltipInPortal is rendered in a separate child of <body /> and positioned
      // with page coordinates which should be updated on scroll. consider using
      // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
      scroll: true,
    })

    return (
      <div style={{ position: 'relative' }}>
        <svg ref={containerRef} width={width} height={height}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill={background}
            rx={theme('borderRadius.lg')}
          />
          <Grid
            top={margin.top}
            left={margin.left}
            right={margin.right}
            bottom={margin.bottom}
            xScale={xScale}
            yScale={yScale}
            width={xMax}
            height={yMax}
            stroke="black"
            strokeOpacity={0.1}
            xOffset={xScale.bandwidth() / 2}
          />

          {data.map((item) => {
            const x = item[xlabel]
            const barWidth = xScale.bandwidth()
            const barHeight = yMax * 0.9 - (yScale(item.value) ?? 0)
            const barX = xScale(x)
            const barY = yMax - barHeight

            return (
              <VBar
                key={`bar-${x}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={getFill({ item, x })}
                onClick={() => {
                  if (events)
                    alert(`clicked: ${JSON.stringify(Object.values(d))}`)
                }}
                {...tooltipEventListeners}
                onMouseMove={(event) =>
                  tooltipEventListeners.onMouseMove({
                    event,
                    bar: { key: x, value: item.value, width: barWidth },
                    barX,
                  })
                }
              />
            )
          })}

          <AxisBottom
            top={yMax}
            scale={xScale}
            tickFormat={formatXLabel}
            stroke={theme('colors.navy.DEFAULT')}
            tickStroke={theme('colors.navy.DEFAULT')}
            tickLabelProps={(label) => ({
              fill: theme('colors.navy.DEFAULT'),
              fontSize: 11,
              textAnchor: 'middle',
              ...getXLabelProps(label),
            })}
          />
          {yAxis && (
            <AxisLeft
              top={margin.top}
              left={margin.left}
              scale={yScale}
              tickFormat={formatYLabel}
              stroke={theme('colors.navy.DEFAULT')}
              tickStroke={theme('colors.navy.DEFAULT')}
              tickLabelProps={(label) => ({
                fill: theme('colors.navy.DEFAULT'),
                fontSize: 11,
                textAnchor: 'right',
                dx: -50,
                ...(isObject(yLabelProps) ? yLabelProps : yLabelProps(label)),
              })}
            />
          )}
        </svg>

        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            <div>
              <strong>{tooltipData.key}</strong>
            </div>
            <div>{tooltipData.value}</div>
            <div>
              <small></small>
            </div>
          </TooltipInPortal>
        )}
      </div>
    )
  }
)

export default Bar
