import React from 'react'
import tw from 'twin.macro'

const UnstyledLabel = ({ for: for_, htmlFor, ...props }) => (
  <label htmlFor={htmlFor || for_} {...props} />
)

const Label = tw(UnstyledLabel)`font-bold dark:text-white`

export default Label
