import React from 'react'
import tw, { styled } from 'twin.macro'
import { Box } from 'rebass/styled-components'

const Option = styled.div(({ isActive }) => [
  tw`px-2 py-1 rounded`,
  isActive
    ? tw`bg-brand-dark text-white font-bold`
    : tw`hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer`,
])

const Container = tw.div`
flex
rounded bg-white dark:bg-gray-800 border border-gray-300 space-x-1 p-1
`

const MultiSwitch = ({ value, options, onChange }) => (
  <Container>
    {options.map((option) => (
      <Option
        key={option}
        isActive={value === option}
        onClick={(event) => value !== option && onChange(option, event)}
      >
        {option}
      </Option>
    ))}
  </Container>
)

export default MultiSwitch
