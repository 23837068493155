import _memoize from 'lodash/memoize'
import * as dfd from 'danfojs/src/index'

import { round, isObject } from '@fxr/common/utils'

const objectToString = (obj) => {
  if (obj instanceof dfd.DataFrame || obj instanceof dfd.Series) {
    // fingerprint dataframe as best we can...
    // maybe consider including index?
    const properties = [...obj.columns, ...obj.dtypes, ...obj.shape]
    return properties.join('-')
  }

  if (isObject(obj)) {
    const sortedKeys = Object.keys(obj).sort((a, b) => a.localeCompare(b))
    return sortedKeys
      .map((key) => `${key}:${objectToString(obj[key])}`)
      .join(';')
  }

  if (Array.isArray(obj)) {
    return obj.map((val) => objectToString(val)).join(',')
  }

  if (obj === undefined) return 'undefined'
  if (obj === null) return 'null'

  return obj.toString()
}

export const memoize = (func) => {
  const resolver = (...args) => args.map(objectToString).join('_')

  const memoized = _memoize((...args) => {
    if (false && process.env.NODE_ENV === 'development') {
      console.log(`[memoize] calling ${func.name} with key:`, resolver(...args))
    }

    return func(...args)
  }, resolver)

  return memoized
}
