import { ActionCreators } from 'redux-undo'
import { useDispatch, useSelector } from 'react-redux'

const useUndoRedo = () => {
  const dispatch = useDispatch()
  const past = useSelector((state) => state.temporal?.past)
  const future = useSelector((state) => state.temporal?.future)

  return {
    onUndo: () => dispatch(ActionCreators.undo()),
    onRedo: () => dispatch(ActionCreators.redo()),
    canUndo: past?.length > 0,
    canRedo: future?.length > 0,
  }
}

export default useUndoRedo
