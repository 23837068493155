import React, { useMemo, useEffect } from 'react'
import { useLocalstorageState } from 'rooks'
import 'twin.macro'
import { useTable, usePagination, useBlockLayout } from 'react-table'
import toast from 'react-hot-toast'

import { H2, Loading, Card, MultiSwitch } from '@fxr/ui'
import RTable from '@fxr/ui/rtable/Table'
import useAutoColumns from '@fxr/ui/rtable/useAutoColumns'
import { numericalRenderer } from '@fxr/ui/rtable/renderers'

import columns from './columns'
import useConfig from './useConfig'
import { useFetchSULCOverviewsQuery } from './features/data/dataApiSlice'

const defaultColumns = [
  columns.unique_title,
  columns.territory,
  {
    ...columns.income_stream_type,
    width: 140,
  },
  {
    ...columns.year,
    width: 100,
  },
]

const defaultColumn = {
  minWidth: 10,
  width: 260,
  maxWidth: 300,
}

const columnOptions = {
  Cell: numericalRenderer(2),
}

const collectionTypes = ['mpc', 'spc']
const sortOrders = ['ascending', 'descending']
const sortBys = ['pos', 'neg', 'net']
const amountColumns = ['total_comp_amount', 'collection_amount']

const SULCOverviewsTable = ({ rows }) => {
  const memoizedColumns = useAutoColumns(defaultColumns, rows || [], {
    columnOptions,
  })

  const tableInstance = useTable(
    {
      columns: memoizedColumns,
      data: rows || [],
      defaultColumn,
      pageSizes: [20, 50, 100, 150, 300],
      initialState: {
        pageSize: 50,
        pageIndex: 0,
      },
    },
    usePagination,
    useBlockLayout
  )

  if (rows === null) {
    return <p>something went wrong.</p>
  }

  if (rows === []) {
    return <p>no rows were returned.</p>
  }

  const onRowClick = (event, row) => {
    const columnsToCopy = memoizedColumns.map((c) => c.id)

    const sulc = columnsToCopy
      .map((col) => {
        let val = row.values[col]
        if (col === 'territory') {
          val = val.split('.')
          val = val[val.length - 1]
        }

        return val
      })
      .join('\t')

    toast.promise(navigator.clipboard.writeText(sulc), {
      loading: 'copying...',
      success: 'copied SULC to clipboard.',
      error: 'could not copy.',
    })
  }

  return (
    <div tw="dark:text-white">
      <RTable.NavbarOffset>
        <RTable
          onRowClick={onRowClick}
          tableInstance={tableInstance}
          paginated={true}
          renderTFoot={true}
          renderFooter={true}
        />
      </RTable.NavbarOffset>
    </div>
  )
}

const SULCOverviews = (props) => {
  const config = useConfig()
  /* const [collectionType, setCollectionType] = useLocalstorageState(
   *   'fxr:sulc-overviews:collection-type',
   *   collectionTypes[0]
   * )
   * const [sortOrder, setSortOrder] = useLocalstorageState(
   *   'fxr:sulc-overviews:sort-order',
   *   sortOrders[0]
   * )
   * const [org, setOrg] = useLocalstorageState('fxr:sulc-overviews:org', null)
   * const [sortBy, setSortBy] = useLocalstorageState(
   *   'fxr:sulc-overviews:sort-by',
   *   'net'
   * ) */
  const [state, setState] = React.useState({
    collectionType: collectionTypes[0],
    sortOrder: sortOrders[0],
    org: null,
    sortBy: 'net',
    amountColumn: amountColumns[0],
  })

  const getUpdateState = (key) => (value) =>
    setState({
      ...state,
      [key]: value,
    })

  useEffect(() => {
    config.orgs && getUpdateState('org')(config.orgs[0])
  }, [config.orgs])

  const setSortByAndSortOrder = (sortBy) => {
    setState({
      ...state,
      sortBy,
      sortOrder: {
        pos: 'descending',
        neg: 'ascending',
        net: 'descending',
      }[sortBy],
    })
  }

  const { collectionType, sortOrder, org, sortBy, amountColumn } = state

  const { data, isFetching } = useFetchSULCOverviewsQuery({
    sortBy: sortBy === 'net' ? '' : sortBy,
    ascending: sortOrder === 'ascending',
    collectionType,
    org,
    amountColumn,
  })

  const rows = useMemo(() => (data ? data.rows : []), [data])

  return (
    <div tw="max-w-4xl mx-auto p-4">
      <Card tw="mb-6">
        <Card.Header tw="flex items-center">
          <H2>SULC overviews</H2>
        </Card.Header>

        <Card.Section>
          <div tw="inline-block">
            <MultiSwitch
              options={collectionTypes}
              value={collectionType}
              onChange={getUpdateState('collectionType')}
            />
          </div>
        </Card.Section>

        <Card.Section>
          <div tw="inline-block">
            <MultiSwitch
              options={config.orgs || []}
              value={org}
              onChange={getUpdateState('org')}
            />
          </div>
        </Card.Section>

        <Card.Section>
          <div tw="inline-block">
            <MultiSwitch
              options={sortOrders}
              value={sortOrder}
              onChange={getUpdateState('sortOrder')}
            />
          </div>
        </Card.Section>

        <Card.Section>
          <div tw="inline-block">
            <MultiSwitch
              options={sortBys}
              value={sortBy}
              onChange={setSortByAndSortOrder}
            />
          </div>
        </Card.Section>

        <Card.Section>
          <div tw="inline-block">
            <MultiSwitch
              options={amountColumns}
              value={amountColumn}
              onChange={getUpdateState('amountColumn')}
            />
          </div>
        </Card.Section>
      </Card>

      {isFetching ? <Loading.Card /> : <SULCOverviewsTable rows={rows} />}
    </div>
  )
}

export default SULCOverviews
