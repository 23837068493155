import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import { useLocalstorageState } from 'rooks'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import CheckboxTree from 'react-checkbox-tree'
import { Box, Text } from 'rebass/styled-components'
import {
  RiCheckboxLine,
  RiCheckboxBlankLine,
  RiCheckboxIndeterminateLine,
} from 'react-icons/ri'
import { IoChevronDownOutline, IoChevronForwardOutline } from 'react-icons/io5'
import { FiPlusSquare, FiMinusSquare } from 'react-icons/fi'

import Tree from '@fxr/common/tree'

import { PSmall } from '../Typography'
import Switch from './Switch'
import Select from './Select'
import Label from './Label'
import Loading from '../Loading'

const icons = {
  check: <RiCheckboxLine />,
  uncheck: <RiCheckboxBlankLine />,
  halfCheck: <RiCheckboxIndeterminateLine />,
  expandClose: <IoChevronForwardOutline />,
  expandOpen: <IoChevronDownOutline />,
  expandAll: <FiPlusSquare />,
  collapseAll: <FiMinusSquare />,
  parentClose: null,
  parentOpen: null,
  leaf: null,
}

const CheckboxTreeWrapper = styled(Box)(({ disabled }) => [
  tw`border border-gray-300 rounded p-2 dark:text-white`,
  disabled && tw`opacity-50`,
  {
    '.rct-title': tw`text-xs`,
    '.rct-node-icon': tw`hidden`,
    '.react-checkbox-tree': tw`relative block`,
    '.rct-options': tw`absolute right-0`,
    '.rct-option + .rct-option': tw`ml-0`,
    '.react-checkbox-tree ol ol': tw`pl-2 border-l`,
    '.react-checkbox-tree label': tw`height[20px] flex items-center`,
    '.react-checkbox-tree label input': tw`cursor-pointer`,
    '.react-checkbox-tree label:hover': tw`bg-brand bg-opacity-20 dark:bg-opacity-50`,
    '.react-checkbox-tree .rct-node-clickable': tw`height[20px]`,
    '.react-checkbox-tree .rct-node-clickable:hover, .rct-node-clickable:focus': tw`bg-brand bg-opacity-20 dark:bg-opacity-50`,
  },
])

const TreeSelect = ({
  label,
  value,
  onChange,
  options: treeData,
  isLoading = false,
  ...props
}) => {
  const [expanded, setExpanded] = useState([])
  const [showSelect, setShowSelect] = useLocalstorageState(
    `fxr:tree-select:${label}:show-select`,
    false
  )
  const checked = value || []

  const tree = new Tree(treeData)
  const nodes = tree.toLabelValueFormat()

  const onChangeSingle = (val) => {
    if (checked.includes(val)) {
      onChange(checked.filter((v) => v !== val))
    } else {
      onChange([...checked, val])
    }
  }

  return (
    <>
      <Box tw="flex items-center">
        {label && <Label htmlFor={props.name}>{label}</Label>}
        <Box tw="flex-1" />
        <Switch
          name="select"
          value={showSelect}
          onChange={setShowSelect}
          variant="small"
        />
        <Box tw="ml-1">
          <PSmall>select</PSmall>
        </Box>
      </Box>
      {showSelect && (
        <Select
          options={tree.nodes()}
          onChange={onChange}
          isLoading={isLoading}
          value={value}
          {...props}
        />
      )}
      <CheckboxTreeWrapper disabled={isLoading && nodes.length > 0}>
        {nodes.length === 0 ? (
          <Text as="p" tw="italic text-gray-400">
            {isLoading ? 'loading...' : 'no options.'}
          </Text>
        ) : (
          <CheckboxTree
            showExpandAll={true}
            icons={icons}
            checkModel="all"
            onClick={({ value }) => onChangeSingle(value)}
            {...props}
            nodes={nodes}
            checked={checked}
            expanded={expanded}
            onCheck={onChange}
            nativeCheckboxes={true}
            optimisticToggle={false}
            onExpand={setExpanded}
          />
        )}
      </CheckboxTreeWrapper>
    </>
  )
}

export default TreeSelect
