import React, { useState, useEffect } from 'react'
import 'twin.macro'
import {
  CgChevronRight,
  CgPushChevronRight,
  CgChevronLeft,
  CgPushChevronLeft,
} from 'react-icons/cg'
import { Text, Box, Flex } from 'rebass/styled-components'

import Select from '../forms/Select'
import Input from '../forms/Input'
import { IconButtonConcealed } from '../Button'

const Paginator = ({ tableInstance, configurePageSizes = true }) => {
  const {
    // pagination stuff
    canPreviousPage,
    canNextPage,
    /*     pageOptions, */
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageSizes,
    state: { pageIndex, pageSize },
  } = tableInstance

  const [pageIndexValue, setPageIndexValue] = useState(pageIndex + 1)

  useEffect(() => {
    setPageIndexValue(pageIndex + 1)
  }, [pageIndex])

  const onInputBlur = (event) => {
    const page = Number(event.target.value) - 1

    if (page > 0) {
      gotoPage(Math.min(page, pageCount))
    }
  }

  return (
    <Flex tw="items-center">
      {configurePageSizes && (
        <>
          <Text as="p" tw="mr-1">
            show
          </Text>
          <Box tw="w-20">
            <Select
              value={pageSize}
              onChange={(value) => {
                setPageSize(Number(value))
              }}
              options={pageSizes}
              menuPlacement="top"
            />
          </Box>
          <Text as="p" tw="ml-1">
            per page
          </Text>

          <Box tw="w-4" />
        </>
      )}

      <IconButtonConcealed
        tw="mr-2"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        <CgPushChevronLeft />
      </IconButtonConcealed>
      <IconButtonConcealed
        tw="mr-2"
        onClick={previousPage}
        disabled={!canPreviousPage}
      >
        <CgChevronLeft />
      </IconButtonConcealed>

      <Input
        value={pageIndexValue}
        onChange={(e) => setPageIndexValue(e.target.value)}
        onBlur={onInputBlur}
        style={{ width: '3rem', display: 'inline', textAlign: 'center' }}
      />
      <Text as="p" tw="w-10 text-center">
        {' '}
        / {pageCount}
      </Text>

      <IconButtonConcealed tw="ml-2" onClick={nextPage} disabled={!canNextPage}>
        <CgChevronRight />
      </IconButtonConcealed>
      <IconButtonConcealed
        tw="ml-2"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        <CgPushChevronRight />
      </IconButtonConcealed>
    </Flex>
  )
}

export const NonTablePaginator = ({ index, count, setIndex }) => (
  <Paginator
    tableInstance={{
      canPreviousPage: index !== 0,
      canNextPage: index !== count - 1,
      pageCount: count,
      gotoPage: (index) => setIndex(Math.max(Math.min(index, count - 1), 0)),
      nextPage: () => setIndex(index + 1),
      previousPage: () => setIndex(index - 1),
      state: { pageIndex: index },
    }}
    configurePageSizes={false}
  />
)

export default Paginator
