import React, { useState } from 'react'
import 'twin.macro'
import { Link } from 'react-router-dom'

import {
  Badge,
  H3,
  H4,
  VR,
  Panel,
  Loading,
  Card,
  H2,
  ButtonNeutral,
  Switch,
} from '@fxr/ui'
import { round } from '@fxr/common/utils'
import usePrevious from '@fxr/common/usePrevious'
import { NonTablePaginator } from '@fxr/ui/rtable/Paginator'

import InterestingCaseFilters from './InterestingCaseFilters'
import ContextBadges from './ContextBadges'
import useExploreContext from './useExploreContext'
import CasePaymentNotInDataTable from './CasePaymentNotInDataTable'
import CaseAmounts from './CaseAmounts'
import CaseBadges from './CaseBadges'
import { useGetCasesQuery } from './features/cases/caseApiSlice'

const InterestingCase = ({ ccase, displayDetails = false, index = null }) => {
  const { context_full: contextFull, compensation_score } = ccase

  const exploreContext = useExploreContext({ caseId: ccase.id })

  return (
    <Card>
      <Card.Section tw="relative">
        {index !== null && <Card.Dot>{index + 1}</Card.Dot>}

        <div tw="flex items-center space-x-2">
          <H2>{ccase.id}</H2>

          {ccase && <CaseBadges ccase={ccase} />}

          <div tw="flex-1" />

          <Link to={`/interesting-cases/${ccase.id}`}>
            <ButtonNeutral>view case</ButtonNeutral>
          </Link>
          <ButtonNeutral onClick={() => exploreContext(contextFull)}>
            explore this context
          </ButtonNeutral>
        </div>
      </Card.Section>

      <Card.Section>
        <ContextBadges context={contextFull} />
      </Card.Section>

      <Card.Section>
        <p>
          <strong>compensation score:</strong> {round(compensation_score, 3)}
        </p>
      </Card.Section>

      <CaseAmounts ccase={ccase} />

      <Card.Section>
        <CasePaymentNotInDataTable ccase={ccase} />
      </Card.Section>

      {displayDetails && (
        <Card.Section>
          <Panel>
            <Panel.Header>
              <H3>interesting case object</H3>
            </Panel.Header>
            <Panel.Body>
              <code>
                <pre>{JSON.stringify(ccase, null, 2)}</pre>
              </code>
            </Panel.Body>
          </Panel>
        </Card.Section>
      )}
    </Card>
  )
}

const InterestingCases = (props) => {
  const { data, isFetching } = useGetCasesQuery()
  const [displayDetails, setDisplayDetails] = useState(false)

  const [page, setPage] = useState(0)
  const pageSize = 30

  const ccases = data || []

  const prevNCases = usePrevious(ccases.length)

  React.useEffect(() => {
    if (prevNCases !== ccases.length) {
      setPage(0)
    }
  }, [ccases])

  const pageCount = parseInt((ccases?.length || 0) / pageSize) + 1

  const paginatedCases = ccases.slice(page * pageSize, (page + 1) * pageSize)

  return (
    <div tw="max-w-4xl mx-auto p-4">
      <Card tw="mb-6">
        <Card.Header tw="flex items-center">
          <H2>
            {!isFetching && <Badge.Neutral>{ccases.length}</Badge.Neutral>}{' '}
            interesting cases
          </H2>

          <VR tw="mx-3 h-6" />

          <p tw="mr-2">display details</p>

          <Switch
            name="display-details"
            value={displayDetails}
            onChange={setDisplayDetails}
          />

          <div tw="flex-1" />

          <NonTablePaginator
            index={page}
            count={pageCount}
            setIndex={setPage}
          />
        </Card.Header>

        <Card.Section>
          <InterestingCaseFilters />
        </Card.Section>
      </Card>

      <div tw="space-y-4">
        {isFetching ? (
          <Loading.Card />
        ) : (
          <>
            {paginatedCases.map((ccase, i) => (
              <InterestingCase
                ccase={ccase}
                index={page * pageSize + i}
                displayDetails={displayDetails}
                key={page * pageSize + i}
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default InterestingCases
