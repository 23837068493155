import React, { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import 'twin.macro'

import { VR, H2, H3, H4, Loading, Card, Button } from '@fxr/ui'
import { round, objectFilter, objectMap } from '@fxr/common/utils'
import useEditableRecord from '@fxr/common/useEditableRecord'

import { useFetchTabularQuery } from './features/data/dataApiSlice'
import CasePaymentNotInDataTable from './CasePaymentNotInDataTable'
import useExploreContext from './useExploreContext'
import {
  useGetCasesQuery,
  useUpdateCaseMutation,
} from './features/cases/caseApiSlice'
import CaseAmounts from './CaseAmounts'
import CaseBadges from './CaseBadges'
import CaseEvents from './CaseEvents'
import CaseReviewer from './CaseReviewer'
import { FilterInputs } from './Filters'

const InterestingCaseView = (props) => {
  const { data: cases = [], isFetching } = useGetCasesQuery()

  const params = useParams()

  const ccase = cases ? cases.find((ccase) => ccase.id === params.id) : null

  const {
    editedCase,
    setEditedCase,
    updateEditedCase,
    isEdited,
  } = useEditableRecord(ccase ? { context_full: ccase.context_full } : null, {
    name: 'case',
  })

  const resetEditedCase = () => {
    setEditedCase({ context_full: ccase.context_full })
  }

  useEffect(() => {
    if (ccase) resetEditedCase()
  }, [Boolean(ccase)])

  const [
    updateCaseMutation,
    { isLoading: isUpdating },
  ] = useUpdateCaseMutation()

  const updateCase = () => {
    const newCase = {
      ...editedCase,
      context_full: objectMap(
        objectFilter(editedCase.context_full || {}, (k, v) => v.length > 0),
        (vals, k) => {
          if (k === 'year') {
            return vals.map((val) => val.toString())
          }
          return vals
        }
      ),
    }

    updateCaseMutation({
      id: ccase.id,
      ccase: newCase,
    })
  }

  // tabularData is used for concol2avail_values
  const { data: tabularData } = useFetchTabularQuery({
    filters: editedCase ? editedCase.context_full : {},
  })

  const getEditContext = (concol) => (value) =>
    updateEditedCase({ context_full: { [concol]: value } })

  const exploreContext = useExploreContext({ caseId: ccase?.id })

  const hasCase = Boolean(ccase) && Boolean(editedCase)

  return (
    <div tw="max-h-full">
      <div tw="bg-white dark:bg-gray-700 border-b border-gray-300 dark:border-gray-900 py-1 px-4 flex items-center">
        <Link to="/interesting-cases">
          <Button.Neutral>all interesting cases</Button.Neutral>
        </Link>

        {hasCase && (
          <>
            <div tw="flex-1 flex flex-col items-center">
              <div tw="flex items-center space-x-2">
                <H2 tw="text-center">{ccase?.id}</H2>
                {ccase && <CaseBadges ccase={ccase} />}
              </div>
              <p
                tw="italic underline text-gray-500 dark:text-gray-400 text-center cursor-pointer inline"
                onClick={() => exploreContext(ccase.context_simplified)}
              >
                open in explorer
              </p>
            </div>
            <Button.Neutral
              tw="mr-2"
              disabled={!isEdited || isUpdating}
              onClick={() => resetEditedCase()}
            >
              undo changes
            </Button.Neutral>
            <Button.Primary
              disabled={!isEdited || isUpdating}
              onClick={updateCase}
            >
              update case
            </Button.Primary>
          </>
        )}
      </div>

      {isFetching && (
        <div tw="mt-4 mx-4">
          <Loading.Card />
        </div>
      )}

      {hasCase && (
        <div tw="flex p-4 space-x-4">
          <div tw="flex-basis[25%]">
            <Card>
              <Card.Header>
                <H3>context</H3>
              </Card.Header>
              <Card.Body>
                <FilterInputs
                  filters={editedCase.context_full}
                  getOnChange={getEditContext}
                  isLoading={isFetching}
                  concol2availValues={tabularData?.concol2avail_values}
                />
              </Card.Body>
            </Card>
          </div>

          <div tw="flex-1">
            {isUpdating && (
              <Card.Info tw="mb-2">
                <Loading text="updating case..." />
              </Card.Info>
            )}
            {isEdited && (
              <Card.Notice tw="mb-2">
                <p>
                  these numbers do not correspond with the context on the left.
                </p>
              </Card.Notice>
            )}

            <Card>
              <CaseAmounts ccase={ccase} />
            </Card>

            <Card tw="mt-4">
              <Card.Header>
                <H3>probability orgs paid out (but we missed it)</H3>
              </Card.Header>
              <Card.Section>
                <CasePaymentNotInDataTable ccase={ccase} />
              </Card.Section>
            </Card>
          </div>

          <div tw="flex-basis[30%]">
            <Card>
              <Card.Header>
                <H3>events</H3>
              </Card.Header>
              <Card.Section>
                <CaseEvents events={ccase?.events || []} />
              </Card.Section>

              <Card.Section>
                <H4>add a review</H4>
                <div tw="flex space-x-2">
                  <CaseReviewer ccase={ccase} />
                </div>
              </Card.Section>
            </Card>
          </div>
        </div>
      )}
    </div>
  )
}

export default InterestingCaseView
