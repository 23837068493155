import React, { useState } from 'react'
import tw from 'twin.macro'
import { useSelector } from 'react-redux'

import { HR, H2, H3, Card, Loading, MultiSwitch } from '@fxr/ui'
import Bar from '@fxr/ui/charts/Bar'
import { getColorFromConfidenceLevel } from '@fxr/ui/colors'
import { objectFilter } from '@fxr/common/utils'

import { CONFIDENCE_COL2CONTEXT_COL } from './constants'
import { useFetchConfidenceDistributionsQuery } from './features/data/dataApiSlice'

const metrics = ['tca', 'nrows']

const ConfidenceDistributions = (props) => {
  const filters = useSelector((state) => state.temporal.present.filters)
  const activeFilterCols = Object.keys(
    objectFilter(filters, (col) => filters[col].length > 0)
  )
  const [metric, setMetric] = useState(metrics[0])

  const { data, isFetching } = useFetchConfidenceDistributionsQuery({ metric })

  const prepareData = (data) =>
    Object.keys(data).map((key) => ({
      level: key,
      value: parseInt(data[key]),
    }))

  const orgtype2confidenceColumn2level2amount = data
    ? data.orgtype2confidence_column2level2amount
    : null

  const orgtype2yMaxValue = {}
  if (orgtype2confidenceColumn2level2amount) {
    Object.keys(orgtype2confidenceColumn2level2amount).forEach((orgtype) => {
      const confidenceColumn2level2amount =
        orgtype2confidenceColumn2level2amount[orgtype]
      Object.values(confidenceColumn2level2amount).forEach((level2amount) => {
        Object.values(level2amount).forEach((amount) => {
          orgtype2yMaxValue[orgtype] = Math.max(
            amount,
            orgtype2yMaxValue[orgtype] || 0
          )
        })
      })
    })
  }

  const Distribution = ({
    header,
    confidenceColumn,
    orgtype,
    level2amount,
    largeAspectRatio = false,
  }) => (
    <Card>
      <Card.Section>
        <p tw="overflow-ellipsis overflow-hidden text-xs">{header}</p>
      </Card.Section>
      {Object.keys(level2amount).length === 0 ? (
        <Card.Section>
          <p>No data to display.</p>
        </Card.Section>
      ) : (
        <div
          css={
            largeAspectRatio
              ? tw`aspect-w-12 aspect-h-3`
              : tw`aspect-w-4 aspect-h-3`
          }
        >
          <Bar
            id={`stats-${confidenceColumn}`}
            data={prepareData(level2amount)}
            xlabel="level"
            background="white"
            yMaxValue={orgtype2yMaxValue[orgtype]}
            getFill={({ x }) => getColorFromConfidenceLevel(x)}
          />
        </div>
      )}
    </Card>
  )

  const confidenceColumnsUsedForRecomputation = []
  Object.keys(CONFIDENCE_COL2CONTEXT_COL).forEach((confidenceCol) => {
    const contextCol = CONFIDENCE_COL2CONTEXT_COL[confidenceCol]
    if (
      contextCol === null ||
      activeFilterCols.includes(contextCol) ||
      activeFilterCols.includes(confidenceCol)
    ) {
      confidenceColumnsUsedForRecomputation.push(confidenceCol)
    }
  })

  const ConfidenceDistributionsForOrgtype = ({ orgtype }) => (
    <div tw="flex-1">
      <Card tw="mb-2">
        <Card.Header>
          <H3>{orgtype}</H3>
        </Card.Header>
      </Card>

      {orgtype in orgtype2confidenceColumn2level2amount ? (
        <>
          {'confidence' in orgtype2confidenceColumn2level2amount[orgtype] && (
            <div tw="mb-2">
              <Distribution
                largeAspectRatio={true}
                confidenceColumn="confidence"
                header={
                  <p>
                    <strong>confidence</strong>{' '}
                    <em>
                      (based on:{' '}
                      {confidenceColumnsUsedForRecomputation.join(', ')})
                    </em>
                  </p>
                }
                orgtype={orgtype}
                level2amount={
                  orgtype2confidenceColumn2level2amount[orgtype].confidence
                }
              />
            </div>
          )}

          <HR tw="mb-2" />

          <div tw="grid grid-cols-2 xl:grid-cols-3 gap-4">
            {Object.keys(orgtype2confidenceColumn2level2amount[orgtype]).map(
              (confidenceColumn) => {
                const level2amount =
                  orgtype2confidenceColumn2level2amount[orgtype][
                    confidenceColumn
                  ]

                if (confidenceColumn === 'confidence') return null

                return (
                  <Distribution
                    key={confidenceColumn}
                    confidenceColumn={confidenceColumn}
                    header={
                      <p>
                        <strong>{confidenceColumn}</strong>
                      </p>
                    }
                    level2amount={level2amount}
                    orgtype={orgtype}
                  />
                )
              }
            )}
          </div>
        </>
      ) : (
        <Card>
          <Card.Notice>
            <p>no data for the {orgtype} in this context.</p>
          </Card.Notice>
        </Card>
      )}
    </div>
  )

  return (
    <>
      <Card tw="mb-4">
        <Card.Header tw="flex justify-between items-center">
          <H2>Confidence distributions</H2>

          <MultiSwitch options={metrics} value={metric} onChange={setMetric} />
        </Card.Header>
      </Card>

      {isFetching ? (
        <Loading.Card />
      ) : Boolean(orgtype2confidenceColumn2level2amount) ? (
        <div tw="flex space-x-6">
          <ConfidenceDistributionsForOrgtype orgtype="cmo" />
          <ConfidenceDistributionsForOrgtype orgtype="publisher" />
        </div>
      ) : (
        <Card>
          <Card.Body>
            <p>There is no data to display.</p>
          </Card.Body>
        </Card>
      )}
    </>
  )
}

export default ConfidenceDistributions
