import {
  objectFilterByKeys,
  objectMap,
  isString,
  isObject,
} from '@fxr/common/utils'
import Tree from '@fxr/common/tree'

import { CONTEXT_COLS, TYPE_TREE_COLS } from './constants'
import { useFetchTabularQuery } from './features/data/dataApiSlice'

const usePrepareFilters = (props) => {
  /* const filters = useSelector((state) => state.temporal.present.filters)
   * const activeFilters = Object.keys(filters).filter(
   *   (col) => filters[col].length > 0
   * ) */

  const { data } = useFetchTabularQuery()

  const prepareCol = (vals_, col) => {
    let vals = vals_
    if (!Array.isArray(vals)) {
      if (isObject(vals)) {
        vals = Object.values(vals)
      }
      if (isString(vals)) {
        vals = [vals]
      }
    }

    if (TYPE_TREE_COLS.includes(col)) {
      if (!data) return vals
      // TODO: WE WANT THE FULL TREE HERE, NOT A FILTERED ONE.
      // FOR NOW, AVAIL_VALUES RETURNS FULL, UNFILTERED TREES.
      return new Tree(data.concol2avail_values[col]).resolveLabel(
        isString(vals) ? vals : vals.join('|')
      )
    }

    if (col === 'year') {
      return vals.map((val) => val.toString())
    }

    return vals
  }

  const prepareFilters = (col2values) => {
    return objectMap(objectFilterByKeys(col2values, CONTEXT_COLS), prepareCol)
  }
  return prepareFilters
}

export default usePrepareFilters
