import React, { useRef, useState, useEffect } from 'react'
import tw, { styled } from 'twin.macro'

const Div = styled.div(() => ({
  '> *': tw`w-full! h-auto!`,
}))

const DOMNode = ({ node }) => {
  const ref = useRef()
  const [appended, setAppended] = useState(false)

  useEffect(() => {
    if (!appended && ref.current) {
      console.log({ node, dom: ref.current })

      ref.current.appendChild(node)
      setAppended(true)
    }
  }, [ref.current])

  return <Div ref={ref} />
}

export default DOMNode
