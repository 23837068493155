import React, { useState } from 'react'
import 'twin.macro'
import { useLocation } from 'react-router-dom'
import { GrUndo, GrRedo } from 'react-icons/gr'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocalstorageState } from 'rooks'

import {
  Loading,
  Button,
  Navbar as BaseNavbar,
  Navlink,
  NavbarFXRHeader,
  DarkModeSwitch,
  NavbarDropdown,
  NavbarDropdownLink,
  NavbarDropdownItem,
  NavbarDropdownHR,
} from '@fxr/ui'
import useUndoRedo from '@fxr/common/useUndoRedo'

import {
  useFetchConfigQuery,
  useClearFromMemoryMutation,
} from './features/data/dataApiSlice'
import useShareToURL from './useShareToURL'
import SavedViews from './SavedViews'
import { ToggleRRGenerator } from './RRGenerator'
import SelectComposer from './SelectComposer'

/* import Icon from './images/icon.png' */

const Navbar = () => {
  const [activeSideMenu, setActiveSideMenu] = useState(null)
  const { user, logout } = useAuth0()

  const { data: config = {}, isFetching } = useFetchConfigQuery()

  const setStoredComposer = useLocalstorageState(
    'fxr:current-composer',
    null
  )[1]
  const onLogout = () => {
    setStoredComposer(null)
    logout()
  }
  const onShareToURL = useShareToURL()
  let { pathname } = useLocation()

  const isOnExplorer = ![
    '/big-fish',
    '/interesting-cases',
    '/sulc-overviews',
  ].find((pathStart) => pathname.startsWith(pathStart))

  const { onUndo, onRedo, canUndo, canRedo } = useUndoRedo()

  const [clearFromMemory] = useClearFromMemoryMutation()
  const onClearFromMemory = () => {
    clearFromMemory().then(() => {
      window.location.reload(true)
    })
  }

  return (
    <>
      <BaseNavbar>
        <NavbarFXRHeader
          icon={null}
          name="combe"
          suffix={
            <span
              tw="text-white text-opacity-50 inline-block px-1 py-0.5
              border border-white border-opacity-30 rounded"
            >
              {isFetching ? <Loading /> : config?.fxr_client_display}
            </span>
          }
        />

        <div tw="w-6" />

        {isOnExplorer && (
          <>
            <Button.Dark tw="mr-4" onClick={onUndo} disabled={!canUndo}>
              <GrUndo />
            </Button.Dark>
            <Button.Dark onClick={onRedo} disabled={!canRedo}>
              <GrRedo />
            </Button.Dark>
          </>
        )}

        <div tw="flex-1" />

        <div tw="space-x-8">
          <Navlink active={isOnExplorer} to="/">
            explorer
          </Navlink>

          <Navlink currentPath={pathname} to="/interesting-cases">
            interesting cases
          </Navlink>

          <Navlink currentPath={pathname} to="/big-fish">
            big fish
          </Navlink>

          <Navlink currentPath={pathname} to="/sulc-overviews">
            SULC overviews
          </Navlink>
        </div>

        <div tw="mx-8">
          <DarkModeSwitch />
        </div>

        <div tw="space-x-4 flex items-center">
          <Button.Dark
            onClick={() =>
              setActiveSideMenu(
                activeSideMenu === 'saved-views' ? null : 'saved-views'
              )
            }
          >
            saved views
          </Button.Dark>

          <ToggleRRGenerator />

          <NavbarDropdown>
            {({ toggleIsOpen }) => (
              <>
                {user && user.nickname && (
                  <>
                    <NavbarDropdownItem>
                      <p>logged in as {user.nickname}</p>
                    </NavbarDropdownItem>
                    <NavbarDropdownLink
                      onClick={onLogout}
                      toggleIsOpen={toggleIsOpen}
                    >
                      log out
                    </NavbarDropdownLink>

                    <NavbarDropdownHR />
                  </>
                )}

                <NavbarDropdownLink
                  onClick={onShareToURL}
                  toggleIsOpen={toggleIsOpen}
                >
                  share to URL
                </NavbarDropdownLink>

                <NavbarDropdownHR />

                <NavbarDropdownItem>
                  <SelectComposer />
                </NavbarDropdownItem>

                <NavbarDropdownLink
                  onClick={onClearFromMemory}
                  toggleIsOpen={toggleIsOpen}
                >
                  refresh data
                </NavbarDropdownLink>
              </>
            )}
          </NavbarDropdown>
        </div>
      </BaseNavbar>
      {activeSideMenu === 'saved-views' && <SavedViews />}
    </>
  )
}

export default Navbar
