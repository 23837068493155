import React, { useState } from 'react'
import 'twin.macro'
import { useLocalstorageState } from 'rooks'

import { sortBy } from '@fxr/common/utils'
import { H2, Card, IconButton, ButtonNeutral, Input } from '@fxr/ui'

import { useSharableURL } from './useShareToURL'

const SavedViews = (props) => {
  const [savedViews, setSavedViews] = useLocalstorageState(
    'fxr:saved-views',
    []
  )

  const [newName, setNewName] = useState('')
  const url = useSharableURL()

  const addSavedView = (savedView) => {
    setSavedViews([...savedViews, savedView])
  }

  const removeSavedView = (name) => {
    setSavedViews(savedViews.filter((view) => view.name !== name))
  }

  const createSavedView = () => {
    addSavedView({
      name: newName,
      url,
      createdAt: Math.floor(Date.now() / 1000),
    })
    setNewName('')
  }

  const loadSavedView = (savedView) => {
    window.location.href = savedView.url
    window.location.reload(true)
  }

  const sortedSavedViews = sortBy(savedViews, (view) => view.createdAt)

  return (
    <div tw="fixed height[calc(100vh - 62px)] overflow-y-scroll right-0 bottom-0 shadow-xl w-72 bg-white dark:bg-gray-800 py-4 px-4 border-l dark:border-gray-900 zIndex[10000]">
      <Card>
        <Card.Header>save current view</Card.Header>
        <Card.Body tw="flex flex-col items-stretch">
          <Input
            type="text"
            placeholder="name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <ButtonNeutral tw="mt-2" onClick={createSavedView}>
            add saved view
          </ButtonNeutral>
        </Card.Body>
      </Card>

      <H2 tw="my-4">saved views</H2>

      {sortedSavedViews.map((savedView) => {
        const name = savedView.name
        const date = new Date(savedView.createdAt * 1000)

        return (
          <div key={name} tw="mb-3">
            <Card tw="relative">
              <IconButton
                tw="absolute -right-2 -top-2"
                onClick={() => removeSavedView(name)}
              >
                &times;
              </IconButton>

              <Card.Header>
                <p>
                  <strong>{name}</strong>
                </p>
                <p tw="text-xs text-gray-500">
                  {date.toLocaleDateString('en-US')}{' '}
                  {date.toLocaleTimeString('en-US')}
                </p>

                <div tw="h-2" />

                <div tw="flex space-x-2">
                  <ButtonNeutral
                    tw="w-full flex-1"
                    onClick={() => loadSavedView(savedView)}
                  >
                    load
                  </ButtonNeutral>
                </div>
              </Card.Header>
            </Card>
          </div>
        )
      })}
    </div>
  )
}

export default SavedViews
