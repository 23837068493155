import { theme } from 'twin.macro'

export const CONFIDENCE_COL2CONTEXT_COL = {
  usage_type_confidence: 'usage_type',
  income_stream_type_confidence: 'income_stream_type',
  territory_confidence: 'territory',
  year_confidence: 'year',
  expected_payment_confidence: null,
  collection_type_confidence: null,
  collection_share_confidence: null,
}

export const CONTEXT_COLS = [
  'territory',
  'year',
  'unique_title',
  'income_stream_type',
  'usage_type',
  'org',
  'mpc',
  'usage_music_user',
  'cowriters',
  'territory_grouped',
  'confidence',
  ...Object.keys(CONFIDENCE_COL2CONTEXT_COL),
]

export const FILTERABLE_CONTEXT_COLS = CONTEXT_COLS.filter(
  (col) => !['territory_grouped'].includes(col)
)

export const TYPE_TREE_COLS = ['territory']

export const ORGTYPE2COLOR = {
  cmo: theme('colors.brand.darker'),
  cmoAlt: theme('colors.brand.lighter'),
  pub: theme('colors.blue.600'),
  pubAlt: theme('colors.blue.300'),
}

export const VALUES = [
  'cmo_mpc_total_comp_amount',
  'pub_mpc_total_comp_amount',
  'mpc_total_comp_amount_difference',
  'cmo_spc_total_comp_amount',
  'pub_spc_total_comp_amount',
  'cmo_mpc_collection_amount',
  'pub_mpc_collection_amount',
  'cmo_spc_collection_amount',
  'pub_spc_collection_amount',
]

export const STRING2FORMATTED_STRING = {
  income_stream_type: 'income type',
  unique_title: 'composition',
  usage_type: 'usage type',
  territory: 'usage territory',
  year: 'usage year',
  collection_amount: 'Gross collection amount',
  client_amount: 'Client amount',
  performance_rights: 'performance rights',
  mechanical_rights: 'mechanical rights',
  sync_fee_composition: 'sync fee composition',
}

export const ORGTYPE2NAME = {
  cmo: 'cmo',
  publisher: 'publisher',
}

export const cmoName = ORGTYPE2NAME.cmo
export const pubName = ORGTYPE2NAME.publisher
export const API_BASE_URL = 'https://combe.fxrinsights.com'
/* export const API_BASE_URL = 'http://localhost:5000' */
export const API_VERSION = 'v1'
