import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { theme } from 'twin.macro'
import { Flex, Text, Box } from 'rebass/styled-components'
import { IoClose } from 'react-icons/io5'

import { IconButtonConcealed } from './Button'
import { H2 } from './Typography'
import HR from './HR'

const Modal = ({
  title,
  renderToggle = null,
  isOpen: initialIsOpen = false,
  onClose = null,
  maxWidth = theme`maxWidth.3xl`,
  children,
}) => {
  const [isOpen, setIsOpen_] = useState(initialIsOpen)

  const setIsOpen = (newIsOpen) => {
    if (!newIsOpen && onClose) onClose()
    setIsOpen_(newIsOpen)
  }

  useEffect(() => {
    setIsOpen(initialIsOpen)
  }, [initialIsOpen])

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  const toggleIsOpen = () => setIsOpen(!isOpen)

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={close}
        contentLabel={title}
        ariaHideApp={false}
        style={{
          overlay: {
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 10000,
          },
          content: {
            maxWidth,
            margin: '0 auto',
            padding: 0,
            border: 0,
            background: 'transparent',
          },
        }}
      >
        <Box tw="py-4 px-6 border bg-white dark:(bg-gray-700 text-white border-gray-900) rounded-xl h-full flex flex-col">
          <Flex tw="items-center justify-between">
            <H2>{title}</H2>

            <IconButtonConcealed onClick={close}>
              <Text tw="text-gray-500 text-xl">
                <IoClose />
              </Text>
            </IconButtonConcealed>
          </Flex>
          <Box tw="mt-2 mb-3">
            <HR />
          </Box>

          {children({ isOpen, open, close, toggleIsOpen })}
        </Box>
      </ReactModal>

      {renderToggle && renderToggle({ toggleIsOpen })}
    </>
  )
}

export default Modal
