import React from 'react'
import 'twin.macro'

import useRRResourceable from './useRRResourceable'

const RRResourceable = ({ children, resourceType, label, data }) => {
  const { onCapture, Capturable } = useRRResourceable({
    resourceType,
  })

  const onClick = () => onCapture(label, data)

  return (
    <div tw="relative">
      {children}
      <Capturable onClick={onClick} />
    </div>
  )
}

export default RRResourceable
